import {ApiResponse} from './utils'
import {Product} from "@/api/models/product";
import {Provider} from "@/api/models/provider";
import {snackCaseDeep} from "@/share/utils/toApiObj";
import GroupProduct from "@/api/models/group_product";
import {Locale} from "@/api/models/locale";
import {Storage} from "@/api/models/storage";
import {StorageRow} from "@/api/models/storage_row";
import {StorageCell} from "@/api/models/storage_cell";
import {StorageStack} from "@/api/models/storage_stack";
import {StorageBoard} from "@/api/models/storage_board";
import {Shop} from "@/api/models/shop";
import {Order} from "@/api/models/order";
import {InnerOrder} from "@/api/models/inner_order";
import {OrderItem} from "@/api/models/order_item";
import {Sachkonto} from "@/api/models/sachkonto";
import {Mvz} from "@/api/models/mvz";
import {Unit} from "@/api/models/unit";
import {Delivery} from "@/api/models/delivery";
import {DeliveryItem} from "@/api/models/delivery_item";
import {User} from "@/api/models/user";
import {Log} from "@/api/models/log";

export const basePath = process.env.VUE_APP_BACKEND

export const headers: any = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
}

export const api = {
    async auth(email: string, password: string) {
        try {
            return await fetch(`${basePath}/api/v1/auth`, {method: "POST", headers, body: JSON.stringify({email, password} )})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<any>(undefined, error);
        }
    },
    async logout() {
        try {
            return await fetch(`${basePath}/api/v1/logout`, {method: "POST", headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<any>(undefined, error);
        }
    },
    async getStorage({search, productId}: { search?: string, productId?: number }): Promise<ApiResponse<Storage[]>> {
        const params: { [x: string]: any } = {};
        if (search) {
            params['search'] = search ?? '';
        }

        if (productId) {
            params['product_id'] = productId ?? '';
        }

        const path = basePath + "/api/v1/storages" + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Storage[]>(undefined, error);
        }
    },

    async createStorage({payload,}: { payload: Storage, }): Promise<ApiResponse<Storage>> {
        const params: { [x: string]: any } = {};

        params["payload"] = payload

        const path = basePath + "/api/v1/storages";

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Storage>(undefined, error);
        }
    },

    async createRow({payload,}: { payload: StorageRow, }): Promise<ApiResponse<StorageRow>> {
        const params: { [x: string]: any } = {};

        params["payload"] = payload

        const path = basePath + "/api/v1/row";

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Storage>(undefined, error);
        }
    },

    async updateRow({id, payload,}: { id: number, payload: StorageRow, }): Promise<ApiResponse<StorageRow[]>> {
        const params: { [x: string]: any } = {};

        params["id"] = id
        params["payload"] = payload

        const path = basePath + "/api/v1/row/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "PUT", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<StorageRow>(undefined, error);
        }
    },

    async deleteStorage({id,}: { id: number, }): Promise<ApiResponse<Storage[]>> {
        const params: { [x: string]: any } = {};

        params["id"] = id

        const path = basePath + "/api/v1/storages/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "DELETE", body: JSON.stringify(params), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Storage[]>(undefined, error);
        }
    },

    async updateStorage({id, payload,}: { id: number, payload: Storage, }): Promise<ApiResponse<Storage[]>> {
        const params: { [x: string]: any } = {};

        params["id"] = id
        params["payload"] = payload

        const path = basePath + "/api/v1/storages/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "PUT", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Storage>(undefined, error);
        }
    },

    async getStorageById({id,}: { id: number, }): Promise<ApiResponse<Storage>> {
        const params: { [x: string]: any } = {};

        const path = basePath + "/api/v1/storages/{id}".replace('{id}', `${id}`) + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Storage>(undefined, error);
        }
    },

    async getProducts({search, page}: { search?: string, page: number }): Promise<ApiResponse<Product[]>> {
        const params: { [x: string]: any } = {};
        if (page) {
            params['page'] = page;
        }
        if (search) {
            params['search'] = search;
        }

        const path = basePath + "/api/v1/products" + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Product[]>(undefined, error);
        }
    },

    async getProductById({id,}: {id: number,}): Promise<ApiResponse<Product>> {
        const params: { [x: string]: any } = {};

        const path = basePath + "/api/v1/products/{id}".replace('{id}', `${id}`) + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET" , headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Product>(undefined, error);
        }
    },

    async createProduct({payload,}: { payload: Product, }): Promise<ApiResponse<Product[]>> {
        const params: { [x: string]: any } = {};

        const path = basePath + "/api/v1/products";

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Product>(undefined, error);
        }
    },

    async updateProduct({id, payload,}: { id: number, payload: Product, }): Promise<ApiResponse<Product[]>> {
        const params: { [x: string]: any } = {};

        params["id"] = id
        params["payload"] = payload

        const path = basePath + "/api/v1/products/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "PUT", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Product>(undefined, error);
        }
    },

    async deleteProduct({id,}: { id: number, }): Promise<ApiResponse<Product[]>> {
        const params: { [x: string]: any } = {};

        params["id"] = id

        const path = basePath + "/api/v1/products/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "DELETE", body: JSON.stringify(params), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Product[]>(undefined, error);
        }
    },

    async getGroupProducts({page, search}: { page: number, search?: string }): Promise<ApiResponse<GroupProduct[]>> {
        const params: { [x: string]: any } = {};
        params['page'] = page;
        params['search'] = search ?? '';

        const path = basePath + "/api/v1/group-products" + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<GroupProduct[]>(undefined, error);
        }
    },

    async createGroupProduct({payload,}: { payload: GroupProduct, }): Promise<ApiResponse<GroupProduct[]>> {
        const params: { [x: string]: any } = {};

        const path = basePath + "/api/v1/group-products" + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(payload), headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<GroupProduct>(undefined, error);
        }
    },

    async getGroupProductById({id,}: { id: number, }): Promise<ApiResponse<GroupProduct[]>> {
        const params: { [x: string]: any } = {};

        const path = basePath + "/api/v1/group-products/{id}".replace('{id}', `${id}`) + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<GroupProduct>(undefined, error);
        }
    },

    async updateGroupProducts({
                                  id,
                                  payload,
                              }: { id: number, payload: GroupProduct, }): Promise<ApiResponse<GroupProduct[]>> {
        const params: { [x: string]: any } = {};

        params["id"] = id
        params["payload"] = payload

        const path = basePath + "/api/v1/group-products/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "PUT", body: JSON.stringify(payload), headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<GroupProduct>(undefined, error);
        }
    },

    async deleteGroupProduct({id,}: { id: number, }): Promise<ApiResponse<GroupProduct[]>> {
        const params: { [x: string]: any } = {};

        const path = basePath + "/api/v1/group-products/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "DELETE", headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<GroupProduct>(undefined, error);
        }
    },

    async getProviders({page, search}: { page: number, search?: string }): Promise<ApiResponse<Provider[]>> {
        const params: { [x: string]: any } = {};
        params['page'] = page ?? '';
        params['search'] = search ?? '';

        const path = basePath + "/api/v1/providers" + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Provider[]>(undefined, error);
        }
    },

    async createProvider({payload,}: { payload: Provider, }): Promise<ApiResponse<Provider[]>> {
        const params: { [x: string]: any } = {};

        params["payload"] = payload

        const path = basePath + "/api/v1/provider";

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(payload), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Provider>(undefined, error);
        }
    },

    async updateProvider({id, payload,}: { id: number, payload: Provider, }): Promise<ApiResponse<Provider[]>> {
        const params: { [x: string]: any } = {};

        params["id"] = id
        params["payload"] = payload

        const path = basePath + "/api/v1/provider/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "PUT", body: JSON.stringify(payload), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Provider>(undefined, error);
        }
    },

    async deleteProvider({id,}: { id: number, }): Promise<ApiResponse<Provider[]>> {
        const params: { [x: string]: any } = {};

        params["id"] = id

        const path = basePath + "/api/v1/provider/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "DELETE", body: JSON.stringify(params), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Provider>(undefined, error);
        }
    },

    async getLocales(): Promise<ApiResponse<Locale[]>> {
        const params: { [x: string]: any } = {};


        const path = basePath + "/api/v1/locale" + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Locale[]>(undefined, error);
        }
    },

    async createLocale({payload,}: { payload: Locale, }): Promise<ApiResponse<Locale[]>> {
        const params: { [x: string]: any } = {};

        params["payload"] = payload

        const path = basePath + "/api/v1/locale";

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Locale>(undefined, error);
        }
    },

    async updateLocale({id, payload,}: { id: string, payload: Locale, }): Promise<ApiResponse<Locale[]>> {
        const params: { [x: string]: any } = {};

        params["id"] = id
        params["payload"] = payload

        const path = basePath + "/api/v1/locale/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "PUT", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Locale>(undefined, error);
        }
    },

    async deleteLocale({id,}: { id: number, }): Promise<ApiResponse<Locale[]>> {
        const params: { [x: string]: any } = {};

        params["id"] = id

        const path = basePath + "/api/v1/locale/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "DELETE", headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Locale>(undefined, error);
        }
    },

    async getStorageRowByStorage({id, productId}: { id: number, productId?: number}): Promise<ApiResponse<StorageRow[]>> {
        const params: { [x: string]: any } = {};
        if (productId) {
            params['product_id'] = productId ?? '';
        }

        const path = basePath + "/api/v1/storages/{id}/row".replace('{id}', `${id}`) + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<StorageRow[]>(undefined, error);
        }
    },

    async getStorageStackByRow({id, productId}: { id: number, productId?: number}): Promise<ApiResponse<StorageStack[]>> {
        const params: { [x: string]: any } = {};
        if (productId) {
            params['product_id'] = productId ?? '';
        }

        const path = basePath + "/api/v1/storages/{id}/stack".replace('{id}', `${id}`) + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<StorageStack[]>(undefined, error);
        }
    },

    async getBoardByStack({id,productId}: { id: number, productId?: number}): Promise<ApiResponse<StorageBoard[]>> {
        const params: { [x: string]: any } = {};
        if (productId) {
            params['product_id'] = productId ?? '';
        }

        const path = basePath + "/api/v1/storages/{id}/board".replace('{id}', `${id}`) + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<StorageBoard[]>(undefined, error);
        }
    },

    async getCellByBoard({id, productId}: { id: number, productId?: number}): Promise<ApiResponse<StorageCell[]>> {
        const params: { [x: string]: any } = {};
        if (productId) {
            params['product_id'] = productId ?? '';
        }

        const path = basePath + "/api/v1/storages/{id}/cell".replace('{id}', `${id}`) + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<StorageCell[]>(undefined, error);
        }
    },

    async getSubCellByCell({id, productId}: { id: number, productId?: number}): Promise<ApiResponse<StorageCell[]>> {
        const params: { [x: string]: any } = {};
        if (productId) {
            params['product_id'] = productId ?? '';
        }

        const path = basePath + "/api/v1/storages/{id}/sub-cell".replace('{id}', `${id}`) + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<StorageCell[]>(undefined, error);
        }
    },

    async getStorageRow({id, id_row,}: { id: number, id_row: number, }): Promise<ApiResponse<StorageRow>> {
        const params: { [x: string]: any } = {};

        const path = basePath + "/api/v1/storages/{id}/row/{id_row}".replace('{id}', `${id}`).replace('{id_row}', `${id_row}`) + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<StorageRow>(undefined, error);
        }
    },

    async createCell({payload,}: { payload: StorageCell, }): Promise<ApiResponse<StorageCell[]>> {
        const params: { [x: string]: any } = {};

        params["payload"] = payload

        const path = basePath + "/api/v1/cell";

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<StorageCell>(undefined, error);
        }
    },

    async updateCell({payload,}: { payload: StorageCell, }): Promise<ApiResponse<StorageCell[]>> {
        const params: { [x: string]: any } = {};

        params["payload"] = payload

        const path = basePath + "/api/v1/cell/{id}".replace('{id}', `${payload.id}`);

        try {
            return await fetch(path, {method: "PUT", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<StorageCell>(undefined, error);
        }
    },

    async deleteCell({id,}: { id: number, }): Promise<ApiResponse<StorageCell>> {
        const params: { [x: string]: any } = {};

        params["id"] = id

        const path = basePath + "/api/v1/cell/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "DELETE", body: JSON.stringify(params), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<StorageCell>(undefined, error);
        }
    },

    async createStack({payload,}: { payload: StorageStack, }): Promise<ApiResponse<StorageStack>> {
        const params: { [x: string]: any } = {};

        params["payload"] = payload

        const path = basePath + "/api/v1/stack";

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<StorageStack>(undefined, error);
        }
    },

    async updateStack({payload, id,}: { payload: StorageStack, id: number, }): Promise<ApiResponse<StorageStack>> {
        const params: { [x: string]: any } = {};

        params["payload"] = payload
        params["id"] = id

        const path = basePath + "/api/v1/stack/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "PUT", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<StorageStack>(undefined, error);
        }
    },

    async deleteStack({id,}: { id: number, }): Promise<ApiResponse<StorageStack>> {
        const params: { [x: string]: any } = {};

        params["id"] = id

        const path = basePath + "/api/v1/stack/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "DELETE", body: JSON.stringify(params), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<StorageStack>(undefined, error);
        }
    },

    async getShops(): Promise<ApiResponse<Shop[]>> {
        const params: { [x: string]: any } = {};


        const path = basePath + "/api/v1/shop" + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Shop[]>(undefined, error);
        }
    },

    async getShopById({id,}: { id: number, }): Promise<ApiResponse<Shop>> {
        const params: { [x: string]: any } = {};


        const path = basePath + "/api/v1/shop/{id}".replace('{id}', `${id}`) + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Shop>(undefined, error);
        }
    },

    async createShop({payload,}: { payload: Shop, }): Promise<ApiResponse<Shop>> {
        const params: { [x: string]: any } = {};

        params["payload"] = payload

        const path = basePath + "/api/v1/shop";

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Shop>(undefined, error);
        }
    },

    async updateShop({id, payload,}: { id: number, payload: Shop, }): Promise<ApiResponse<Shop>> {
        const params: { [x: string]: any } = {};

        params["id"] = id
        params["payload"] = payload

        const path = basePath + "/api/v1/shop/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "PUT", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Shop>(undefined, error);
        }
    },

    async deleteShop({id,}: { id: number, }): Promise<ApiResponse<Shop>> {
        const params: { [x: string]: any } = {};

        params["id"] = id

        const path = basePath + "/api/v1/shop/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "DELETE", body: JSON.stringify(params), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Shop>(undefined, error);
        }
    },

    async deleteRow({id,}: { id: number, }): Promise<ApiResponse<Shop>> {
        const params: { [x: string]: any } = {};

        params["id"] = id

        const path = basePath + "/api/v1/row/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "DELETE", body: JSON.stringify(params), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Shop>(undefined, error);
        }
    },

    async getOrders({status, page}: { status: String, page: number }): Promise<ApiResponse<Order[]>> {
        const params: { [x: string]: any } = {};
        params['page'] = page;
        params['status'] = status;

        const path = basePath + "/api/v1/order" + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Order[]>(undefined, error);
        }
    },

    async getOrderById({id,}: { id: number, }): Promise<ApiResponse<Order>> {
        const params: { [x: string]: any } = {};

        const path = basePath + "/api/v1/order/{id}".replace('{id}', `${id}`) + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Order>(undefined, error);
        }
    },

    async createOrder({payload,}: { payload: Order, }): Promise<ApiResponse<Order>> {
        const params: { [x: string]: any } = {};

        const path = basePath + "/api/v1/order";

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Order>(undefined, error);
        }
    },

    async updateOrder({payload, id,}: { payload: Order, id: number, }): Promise<ApiResponse<Order>> {
        const path = basePath + "/api/v1/order/{id}".replace('{id}', `${id}`);
        try {
            return await fetch(path, {method: "PUT", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Order>(undefined, error);
        }
    },

    async updateOrderStatus({status, id,}: { status: string, id: number, }): Promise<ApiResponse<Order>> {
        const path = basePath + "/api/v1/order/{id}/status".replace('{id}', `${id}`);

        try {
            return await fetch(path, {
                method: "POST",
                body: JSON.stringify(snackCaseDeep({status: status})),
                headers: headers
            })
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Order>(undefined, error);
        }
    },

    async deleteOrder({id,}: { id: number, }): Promise<ApiResponse<Order>> {
        const path = basePath + "/api/v1/order/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "DELETE", headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Order>(undefined, error);
        }
    },

    async getOrderItem({order_id, page}: { order_id: number, page: number }): Promise<ApiResponse<OrderItem[]>> {
        const params: { [x: string]: any } = {};
        params['page'] = page;
        params['order_id'] = order_id;

        const path = basePath + "/api/v1/order-item" + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<OrderItem[]>(undefined, error);
        }
    },

    async createOrderItem({payload,}: { payload: OrderItem, }): Promise<ApiResponse<OrderItem>> {
        const params: { [x: string]: any } = {};

        params["payload"] = payload

        const path = basePath + "/api/v1/order-item";

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<OrderItem>(undefined, error);
        }
    },

    async updateOrderItem({id, payload,}: { id: number, payload: OrderItem, }): Promise<ApiResponse<OrderItem>> {
        const params: { [x: string]: any } = {};

        params["id"] = id
        params["payload"] = payload

        const path = basePath + "/api/v1/order-item/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "PUT", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<OrderItem>(undefined, error);
        }
    },

    async deleteOrderItem({id,}: { id: number, }): Promise<ApiResponse<OrderItem>> {
        const params: { [x: string]: any } = {};

        params["id"] = id

        const path = basePath + "/api/v1/order-item/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "DELETE", body: JSON.stringify(params), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<OrderItem>(undefined, error);
        }
    },

    async getSachkonto({search, page, searchMode}: { search: string, page: number, searchMode: boolean }): Promise<ApiResponse<Sachkonto[]>> {
        const params: { [x: string]: any } = {};
        if (page) {
            params['page'] = page;
        }
        if (search) {
            params['search'] = search;
        }
        if (searchMode) {
            params['search-mode'] = searchMode;
        }

        const path = basePath + "/api/v1/sachkonto" + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Sachkonto[]>(undefined, error);
        }
    },

    async createSachkonto({payload,}: { payload: Sachkonto, }): Promise<ApiResponse<Sachkonto>> {
        const params: { [x: string]: any } = {};

        params["payload"] = payload

        const path = basePath + "/api/v1/sachkonto";

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Sachkonto>(undefined, error);
        }
    },

    async updateSachkonto({id, payload,}: { id: number, payload: Sachkonto, }): Promise<ApiResponse<Sachkonto>> {
        const params: { [x: string]: any } = {};

        params["id"] = id
        params["payload"] = payload

        const path = basePath + "/api/v1/sachkonto/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "PUT", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Sachkonto>(undefined, error);
        }
    },

    async deleteSachkonto({id,}: { id: number, }): Promise<ApiResponse<Sachkonto>> {
        const params: { [x: string]: any } = {};

        params["id"] = id

        const path = basePath + "/api/v1/sachkonto/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "DELETE", body: JSON.stringify(params), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Sachkonto>(undefined, error);
        }
    },

    async getMvz({search, page, searchMode}: { search: string, page: number, searchMode?: boolean }): Promise<ApiResponse<Mvz[]>> {
        const params: { [x: string]: any } = {};
        params['page'] = page;
        params['search'] = search ?? '';
        params['search-mode'] = searchMode;

        const path = basePath + "/api/v1/mvz" + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Mvz[]>(undefined, error);
        }
    },

    async createMvz({payload,}: { payload: Mvz, }): Promise<ApiResponse<Mvz>> {
        const params: { [x: string]: any } = {};

        params["payload"] = payload

        const path = basePath + "/api/v1/mvz";

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Mvz>(undefined, error);
        }
    },

    async updateMvz({id, payload,}: { id: number, payload: Mvz, }): Promise<ApiResponse<Mvz>> {
        const params: { [x: string]: any } = {};

        params["id"] = id
        params["payload"] = payload

        const path = basePath + "/api/v1/mvz/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "PUT", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Mvz>(undefined, error);
        }
    },

    async deleteMvz({id,}: { id: number, }): Promise<ApiResponse<Mvz>> {
        const params: { [x: string]: any } = {};

        params["id"] = id

        const path = basePath + "/api/v1/mvz/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "DELETE", body: JSON.stringify(params), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Mvz>(undefined, error);
        }
    },

    async getInnerOrder({search, page, searchMode}: { search: string, page: number, searchMode?: boolean }): Promise<ApiResponse<InnerOrder[]>> {
        const params: { [x: string]: any } = {};
        params['page'] = page;
        params['search'] = search ?? '';
        params['search-mode'] = searchMode;

        const path = basePath + "/api/v1/inner-order" + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<InnerOrder[]>(undefined, error);
        }
    },

    async createInnerOrder({payload,}: { payload: InnerOrder, }): Promise<ApiResponse<InnerOrder>> {
        const params: { [x: string]: any } = {};

        params["payload"] = payload

        const path = basePath + "/api/v1/inner-order";

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<InnerOrder>(undefined, error);
        }
    },

    async updateInnerOrder({id, payload,}: { id: number, payload: InnerOrder, }): Promise<ApiResponse<InnerOrder>> {
        const params: { [x: string]: any } = {};

        params["id"] = id
        params["payload"] = payload

        const path = basePath + "/api/v1/inner-order/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "PUT", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<InnerOrder>(undefined, error);
        }
    },

    async deleteInnerOrder({id,}: { id: number, }): Promise<ApiResponse<InnerOrder>> {
        const params: { [x: string]: any } = {};

        params["id"] = id

        const path = basePath + "/api/v1/inner-order/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "DELETE", headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<InnerOrder>(undefined, error);
        }
    },

    async getUnit({page}: { page: number }): Promise<ApiResponse<Unit[]>> {
        const params: { [x: string]: any } = {};
        params['page'] = page;

        const path = basePath + "/api/v1/unit" + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Unit[]>(undefined, error);
        }
    },

    async createUnit({payload,}: { payload: Unit, }): Promise<ApiResponse<Unit>> {
        const params: { [x: string]: any } = {};

        params["payload"] = payload

        const path = basePath + "/api/v1/unit";

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Unit>(undefined, error);
        }
    },

    async updateUnit({id, payload,}: { id: number, payload: Unit, }): Promise<ApiResponse<Unit>> {
        const params: { [x: string]: any } = {};

        params["id"] = id
        params["payload"] = payload

        const path = basePath + "/api/v1/unit/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "PUT", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Unit>(undefined, error);
        }
    },

    async deleteUnit({id,}: { id: number, }): Promise<ApiResponse<Unit>> {
        const params: { [x: string]: any } = {};

        params["id"] = id

        const path = basePath + "/api/v1/unit/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "DELETE", headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Unit>(undefined, error);
        }
    },

    async getDelivery({page}: {page: number}): Promise<ApiResponse<Delivery[]>> {
        const params: { [x: string]: any } = {};
        params['page'] = page;

        const path = basePath + "/api/v1/delivery" + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET" , headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Delivery[]>(undefined, error);
        }
    },

    async getDeliveryById({id}: {id: number}): Promise<ApiResponse<Delivery[]>> {
        const path = basePath + "/api/v1/delivery/" + id;

        try {
            return await fetch(path, {method: "GET" , headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Delivery[]>(undefined, error);
        }
    },

    async createDelivery({payload,}: { payload: Delivery, }): Promise<ApiResponse<Delivery>> {
        const params: { [x: string]: any } = {};

        params["payload"] = payload

        const path = basePath + "/api/v1/delivery";

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Delivery>(undefined, error);
        }
    },

    async updateDelivery({id, payload,}: { id: number, payload: Delivery, }): Promise<ApiResponse<Delivery>> {
        const params: { [x: string]: any } = {};

        params["id"] = id
        params["payload"] = payload

        const path = basePath + "/api/v1/delivery/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "PUT", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Delivery>(undefined, error);
        }
    },

    async deleteDelivery({id,}: { id: number, }): Promise<ApiResponse<Delivery>> {
        const params: { [x: string]: any } = {};

        params["id"] = id

        const path = basePath + "/api/v1/delivery/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "DELETE", headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<Delivery>(undefined, error);
        }
    },


    async getDeliveryItem({page, delivery_id}: { page: number, delivery_id: number }): Promise<ApiResponse<DeliveryItem[]>> {
        const params: { [x: string]: any } = {};
        params['page'] = page;
        params['delivery_id'] = delivery_id;

        const path = basePath + "/api/v1/delivery-item" + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<DeliveryItem[]>(undefined, error);
        }
    },

    async createDeliveryItem({payload,}: { payload: DeliveryItem, }): Promise<ApiResponse<DeliveryItem>> {
        const params: { [x: string]: any } = {};

        params["payload"] = payload

        const path = basePath + "/api/v1/delivery-item";

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<DeliveryItem>(undefined, error);
        }
    },

    async updateDeliveryItem({
                                 id,
                                 payload,
                             }: { id: number, payload: DeliveryItem, }): Promise<ApiResponse<DeliveryItem>> {
        const params: { [x: string]: any } = {};

        params["id"] = id
        params["payload"] = payload

        const path = basePath + "/api/v1/delivery-item/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "PUT", body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<DeliveryItem>(undefined, error);
        }
    },

    async deleteDeliveryItem({id,}: { id: number, }): Promise<ApiResponse<DeliveryItem>> {
        const params: { [x: string]: any } = {};

        params["id"] = id

        const path = basePath + "/api/v1/delivery-item/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "DELETE", headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<DeliveryItem>(undefined, error);
        }
    },

    async getProductPlaces({product_id}: { product_id: number }): Promise<ApiResponse<DeliveryItem[]>> {
        const path = basePath + `/api/v1/products/${product_id}/places`;

        try {
            return await fetch(path, {method: "GET", headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<DeliveryItem[]>(undefined, error);
        }
    },

    async getUser(): Promise<ApiResponse<User[]>> {
        const params: { [x: string]: any } = {};

        const path = basePath + "/api/v1/user" + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET" , headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<User[]>(undefined, error);
        }
    },

    async createUser({payload,}: {payload: User,}): Promise<ApiResponse<User>> {
        const params: { [x: string]: any } = {};

        params["payload"] = payload

        const path = basePath + "/api/v1/user";

        try {
            return await fetch(path, {method: "POST" , body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<User>(undefined, error);
        }
    },

    async updateUser({id,payload,}: {id: number,payload: User,}): Promise<ApiResponse<User>> {
        const params: { [x: string]: any } = {};

        params["id"] = id
        params["payload"] = payload

        const path = basePath + "/api/v1/user/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "PUT" , body: JSON.stringify(snackCaseDeep(payload)), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<User>(undefined, error);
        }
    },

    async deleteUser({id,}: {id: number,}): Promise<ApiResponse<User>> {
        const params: { [x: string]: any } = {};

        params["id"] = id

        const path = basePath + "/api/v1/user/{id}".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "DELETE", headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<User>(undefined, error);
        }
    },
    async updatePassword({id,password,}: {id: number,password: string,}): Promise<ApiResponse<User>> {
        const params: { [x: string]: any } = {};

        params["id"] = id
        params["password"] = password

        const path = basePath + "/api/v1/user/{id}/password".replace('{id}', `${id}`);

        try {
            return await fetch(path, {method: "POST" , body: JSON.stringify(snackCaseDeep({password})), headers: headers})
                .then(async r => ([r, await r.json()]))
                .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        } catch (error) {
            return new ApiResponse<User>(undefined, error);
        }
    },

    async getLogs({page}: { page: number }): Promise<ApiResponse<Log[]>> {
        const params: { [x: string]: any } = { page: page ?? 1 };

        const path = basePath + "/api/v1/logs" + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET" , headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Log[]>(undefined, error);
        }
    },

}
