
import {Options, Vue} from "vue-class-component";
import AppIcons from "../share/AppIcons.vue";
import LayoutTable from "../share/Layout/LayoutTable.vue";
import AppForm from "@/share/AppForm.vue";
import {doYouClose} from "@/share/utils/doYouClose";
import {ElMessage, ElMessageBox} from "element-plus";
import {FormAction} from "@/share/forms/forms_definitions";
import store from "@/store";
import {ApiResponse} from "@/api/utils";
import {locale, localeError} from "@/share/locale";
import {getEmptyMvz, Mvz} from "@/api/models/mvz";
import {mvzTable} from "@/table/mvz_columns";
import {mvzFields} from "@/form/mvz_form";
import {ACTIONS_MVZ} from "@/store/MvzModule";

type T = Mvz;

@Options({
  components: {
    AppForm,
    AppIcons,
    LayoutTable,
  },
})
export default class MvzList extends Vue {

  async created() {
  }

  columnsTable = mvzTable;

  form = mvzFields;

  selectItem?: T

  openModal = false;

  doYouClose = doYouClose;
  locale = locale;
  nameEntity = 'mvz'

  openItem(item) {
    this.selectItem = item;
    this.openModal = true;
  }

  get loading(): boolean {
    return store.getters.sachkontoModuleIsPending;
  }

  async handleAction(item: FormAction<T>) {
    let res: ApiResponse<any>
    const payload: T = {...item.values}
    if (item.values.id) {
      res = await store.dispatch(ACTIONS_MVZ.UPDATE, payload);
    } else {
      res = await store.dispatch(ACTIONS_MVZ.CREATE, payload);
    }
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
      this.openModal = false;
    }
  }

  handleOpenCreate() {
    this.selectItem = getEmptyMvz();
    this.openModal = true;
  }

  async handleDelete(item: T) {
    await ElMessageBox.confirm(
        locale(`${this.nameEntity}.form.delete`, 'Delete', item.title),
        locale(`modal.title.warning`, 'Warning'),
        {
          confirmButtonText: locale(`action.delete`, 'Delete'),
          cancelButtonText: locale(`action.cancel`, 'Cancel'),
          type: 'warning',
        }
    )
    const res: ApiResponse<any> = await store.dispatch(ACTIONS_MVZ.DELETE, item.id);
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
      ElMessage({type: 'success', message: locale(`request.success`, 'Success'),})
    }
  }
}
