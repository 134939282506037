import {getEmptyProduct, Product} from "@/api/models/product";
import {getEmptySachkonto, Sachkonto} from "@/api/models/sachkonto";
import {getEmptyMvz, Mvz} from "@/api/models/mvz";
import {getEmptyInnerOrder, InnerOrder} from "@/api/models/inner_order";

export interface OrderItem {
    id: number
    uid?: number
    type: string
    count: number | null
    netto: number
    priceByUnitEuro: string | null
    priceByUnitCent: string | null
    sachkonto?: Sachkonto
    sachkontoId: number
    mvz?: Mvz
    mvzId: number | null,
    innerOrder?: InnerOrder
    innerOrderId: number
    product?: Product
    productId: number
    orderId: number
    priceByUnit?: string
    unit?: number
}

export function getEmptyOrderItem(orderId = 0): OrderItem {
    return {
        id: 0,
        type: "",
        count: null,
        netto: 0,
        priceByUnitEuro: null,
        priceByUnitCent: null,
        sachkontoId: 0,
        mvzId: null,
        innerOrderId: 0,
        productId: 0,
        orderId: orderId,
    };
}
