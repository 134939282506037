
import {Options, Vue} from "vue-class-component";
import AppIcons from "../../share/AppIcons.vue";
import LayoutTable from "../../share/Layout/LayoutTable.vue";
import AppForm from "@/share/AppForm.vue";
import {doYouClose} from "@/share/utils/doYouClose";
import {ElMessage, ElMessageBox} from "element-plus";
import {FormAction} from "@/share/forms/forms_definitions";
import store from "@/store";
import {ApiResponse} from "@/api/utils";
import {locale, localeError} from "@/share/locale";
import LayoutHeader from "@/share/Layout/LayoutHeader.vue";
import AppTable from "@/share/AppTable.vue";
import {api} from "@/api/api";
import {Delivery, getEmptyDelivery} from "@/api/models/delivery";
import {deliveryFields, deliveryViewFields} from "@/form/delivery_form";
import {deliveryItemFields} from "@/form/delivery_item_form";
import {deliveryItemTable} from "@/table/delivery_item_columns";
import {DeliveryItem, getEmptyDeliveryItem} from "@/api/models/delivery_item";
import {ACTIONS_DELIVERY} from "@/store/DeliveryModule";
import {Product} from "@/api/models/product";
import moment from "moment";
import {ACTIONS_ORDER} from "@/store/OrderModule";
import {createLogger} from "vuex";
import {Order} from "@/api/models/order";
import {referencesStorage} from "@/share/referenceStorage";

type T = Delivery;

@Options({
  components: {
    AppTable,
    LayoutHeader,
    AppForm,
    AppIcons,
    LayoutTable,
  },
})
export default class DeliveryView extends Vue {

  items: DeliveryItem[] = []

  isLoaded = false;

  async created() {
    await this.load();
    await this.loadItems()

    const _this = this;
    setTimeout(() => _this.isLoaded = true, 100);
  }

  form = deliveryViewFields;
  formRow = deliveryItemFields;

  tableRows = deliveryItemTable;

  openModal = false;
  selectItem = getEmptyDeliveryItem();

  newDeliveryItems: DeliveryItem[] = []
  removedDeliveryItems: number[] = []
  updatedDeliveryItems: DeliveryItem[] = []

  values: Delivery = getEmptyDelivery();

  doYouClose = doYouClose;
  locale = locale;
  nameEntity = 'delivery'

  loading = false

  references = referencesStorage

  async handleAction() {
    this.values = (this.$refs.AppFormValues as any)!.valuesLocal;

    const item = this.values;

    let r = item.dateSend
    if (typeof (item as any).dateSend == "object") {
      const d = (item as any).dateSend;

      r = moment(d).utcOffset(0, true).format()
    }

    const val = {...item, dateSend: r};

    let res: ApiResponse<any>
    let flag = false;

    if (this.values.id) {
      res = await store.dispatch(ACTIONS_DELIVERY.UPDATE, val);
    } else {
      res = await store.dispatch(ACTIONS_DELIVERY.CREATE, val);
    }

    console.log({res})

    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
      flag = true;
    }

    await this.appleDeliveryItemAction();

    this.removedDeliveryItems = [];
    this.newDeliveryItems = [];
    this.updatedDeliveryItems = [];

    await this.loadItems()

    if (flag) {
      ElMessage({type: 'success', message: locale(`request.success`, 'Success'),})
    }

  }

  async appleDeliveryItemAction() {
    for (let item of this.removedDeliveryItems) {
      const res = await api.deleteDeliveryItem({id: item});
      if (res.error) {
        ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
      }
    }
    for (let item of this.newDeliveryItems) {
      await this.appleDeliveryItemAction1(item);
    }
    for (let item of this.updatedDeliveryItems) {
      await this.appleDeliveryItemAction1(item);
    }
  }

  async appleDeliveryItemAction1(item: DeliveryItem) {
    let res: ApiResponse<Delivery>

    if (item.id) {
      res = await api.updateDeliveryItem({payload: item, id: item.id})
    } else {
      res = await api.createDeliveryItem({payload: item})
    }

    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
    }

    this.openModal = false;
  }

  applyActionDeliveryItem(item: FormAction<DeliveryItem>) {
    if (item.values.id) {
      this.updatedDeliveryItems.push(item.values);
      const index = this.items.findIndex(e => e.id == item.values.id);
      if (index != -1) {
        this.items.splice(index, 1, item.values);
      }
    } else {
      const res: DeliveryItem = {...item.values};
      if (res.uid) {
        const index = this.newDeliveryItems.findIndex(e => e.uid == res.uid);
        if (index > -1) {
          this.newDeliveryItems.splice(index, 1);
        }
        const index2 = this.items.findIndex(e => e.uid == res.uid);
        if (index2 > -1) {
          this.items.splice(index2, 1);
        }
      } else {
        res.uid = Math.random()
      }

        this.newDeliveryItems.push(res);
        this.items.unshift(res);
    }

    this.openModal = false;
  }

  get id() {
    return Number(this.$route.params.id);
  }

  async handleDeleteRow(item: DeliveryItem) {
    await ElMessageBox.confirm(
        locale(`deliveryItem.form.delete`, 'Delete', `${item.id}`),
        locale(`modal.title.warning`, 'Warning'),
        {
          confirmButtonText: locale(`action.delete`, 'Delete'),
          cancelButtonText: locale(`action.cancel`, 'Cancel'),
          type: 'warning',
        }
    )

    if (item.deliveryId) {
      this.removedDeliveryItems.push(item.id)
      const index = this.items.findIndex(e => e.id == item.id)
      if (index != -1) {
        this.items.splice(index, 1)
      }
    }

    if (item.uid) {
      const index = this.items.findIndex(e => e.uid == item.uid)
      if (index != -1) {
        this.items.splice(index, 1)
      }
    }

    // const res = await api.deleteRow({id: item.id})
    //
    // if (res.error) {
    //   ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    // } else {
    // }
    // await this.loadItems()
  }

  async loadItems() {
    this.items = (await api.getDeliveryItem({delivery_id: Number(this.$route.params.id), page: 1})).payload;
  }

  async load() {
    const res = await api.getDeliveryById({id: this.id});
    this.values = res.payload;
  }

  handleEditDeliveryItem(item: DeliveryItem) {
    this.selectItem = item;
    this.openModal = true;
  }

  handleOpenAdd() {
    this.selectItem = {...getEmptyDeliveryItem(Number(this.$route.params.id))};
    this.openModal = true;
  }

  handleDownload() {

  }

}
