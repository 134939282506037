import {StorageCell} from "@/api/models/storage_cell";

export interface StorageBoard {
  	 id: number
	 title: string
	 cells: StorageCell[]
	 storageStackId: number

}

export function getEmptyStorageBoard () {
   return {
      id: 0,
      title: "",
      cells: [],
      storageStackId: 0,
   };
}
