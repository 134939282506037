import {createStore} from 'vuex'
import {StorageModule} from "@/store/StorageModule";
import {StorageSingleModule} from "@/store/StorageSingleModule";
import {ProductModule} from "@/store/ProductModule";
import {GroupProductModule} from "@/store/GroupProductModule";
import {ProviderModule} from "@/store/ProviderModule";
import {LocaleModule} from "@/store/LocaleModule";
import {StorageRowSingleModule} from "@/store/StorageRowSingleModule";
import {ShopModule} from "@/store/ShopModule";
import {StorageRowModule} from "@/store/StorageRowModule";
import {OrderModule} from "@/store/OrderModule";
import {InnerOrderModule} from "@/store/InnerOrderModule";
import {MvzModule} from "@/store/MvzModule";
import {OrderItemModule} from "@/store/OrderItemModule";
import {SachkontoModule} from "@/store/SachkontoModule";
import {UnitModule} from "@/store/UnitModule";
import {DeliveryModule} from "@/store/DeliveryModule";
import {DeliveryItemModule} from "@/store/DeliveryItemModule";
import {UserModule} from "@/store/UserModule";
import {LogModule} from "@/store/LogModule";

export interface RootState {

}

export default createStore<RootState>({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        StorageModule,
        StorageSingleModule,
        StorageRowSingleModule,
        ProductModule,
        GroupProductModule,
        ProviderModule,
        LocaleModule,
        ShopModule,
        StorageRowModule,
        OrderModule,
        InnerOrderModule,
        MvzModule,
        OrderItemModule,
        SachkontoModule,
        UnitModule,
        DeliveryModule,
        DeliveryItemModule,
        UserModule,
        LogModule,
    }
})
