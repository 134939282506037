export interface Shop {
    id: number
    title: string
    responsible: string
    address: string
}

export function getEmptyShop(): Shop {
    return {
        id: 0,
        title: "",
        responsible: "",
        address: "",
    };
}
