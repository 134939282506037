import {AppFormField, AppFormFieldType, AppFormFieldTypeView} from "@/share/forms/forms_definitions";
import {Unit} from "@/api/models/unit";
import {FieldBtnSave} from "@/share/forms/form";
import {fieldRequired} from "@/share/fieldRequired";

export const unitFields: AppFormField<Unit>[] = [
    {
        label: 'ID',
        key: 'id',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        disabled: true,
        conditionVisible: (r) => r.id != 0,
    },
    {
        label: 'Title',
        key: 'title',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
    },
    {...FieldBtnSave, width: '350px', key: 'action'},
];
