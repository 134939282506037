import {AppTable} from "@/share/AppTable.vue";
import {Storage} from "@/api/models/storage";

export const storageTable: AppTable<Storage> = {
    title: 'Список складов',
    columns: [
        {
            title: 'ID',
            type: 'plain',
            code: 'id',
            width: '100px',
        },
        {
            title: 'Заголовок',
            type: 'plain',
            code: 'title',
        },
        {
            title: 'Ответственный',
            type: 'plain',
            code: 'responsible',
        },
        {
            title: 'Цех',
            type: 'plain',
            code: 'shop',
            render: (text, row) => row.shop?.title,
        }
    ]
};
