
import {Options, Vue} from "vue-class-component";
import AppIcons from "../share/AppIcons.vue";
import LayoutTable from "../share/Layout/LayoutTable.vue";
import AppForm from "@/share/AppForm.vue";
import {doYouClose} from "@/share/utils/doYouClose";
import {providerFields} from "@/form/provider_form";
import {ElMessage, ElMessageBox} from "element-plus";
import {FormAction} from "@/share/forms/forms_definitions";
import store from "@/store";
import {ApiResponse} from "@/api/utils";
import {providerTable} from "@/table/provider_columns";
import {getEmptyProvider, Provider} from "@/api/models/provider";
import {ACTIONS_PROVIDER} from "@/store/ProviderModule";
import {locale, localeError} from "@/share/locale";

@Options({
  components: {
    AppForm,
    AppIcons,
    LayoutTable,
  },
})
export default class ProviderList extends Vue {

  async created() {
  }

  columnsTable = providerTable

  form = providerFields;

  selectItem?: Provider

  openModal = false;

  doYouClose = doYouClose;
  locale = locale;
  nameEntity = 'provider'

  openProvider(item) {
    this.selectItem = item;
    this.openModal = true;
  }

  get loading(): boolean {
    return store.getters.providerModuleIsPending;
  }

  async handleAction(item: FormAction<Provider>) {
    let res: ApiResponse<any>
    if (item.values.id) {
      res = await store.dispatch(ACTIONS_PROVIDER.UPDATE, item.values);
    } else {
      res = await store.dispatch(ACTIONS_PROVIDER.CREATE, item.values);
    }
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
      this.openModal = false;
    }
  }

  handleOpenCreate() {
    this.selectItem = getEmptyProvider();
    this.openModal = true;
  }

  async handleDelete(item: Provider) {
    await ElMessageBox.confirm(
        locale(`${this.nameEntity}.form.delete`, 'Delete', item.title),
        locale(`modal.title.warning`, 'Warning'),
        {
          confirmButtonText:  locale(`action.delete`, 'Delete'),
          cancelButtonText: locale(`action.cancel`, 'Cancel'),
          type: 'warning',
        }
    )
    const res: ApiResponse<any> = await store.dispatch(ACTIONS_PROVIDER.DELETE, item.id);
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
      ElMessage({type: 'success', message: locale(`request.success`, 'Success'),})
    }
  }
}
