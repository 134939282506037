export interface Unit {
    id: number
    title: string

}

export function getEmptyUnit(): Unit {
    return {
        id: 0,
        title: "",
    };
}
