import GroupProduct, {getEmptyGroupProduct} from "@/api/models/group_product";
import {getEmptyStorageRow, StorageRow} from "@/api/models/storage_row";
import {getEmptyStorageStack, StorageStack} from "@/api/models/storage_stack";
import {getEmptyStorageBoard, StorageBoard} from "@/api/models/storage_board";
import {getEmptyStorageCell, StorageCell} from "@/api/models/storage_cell";
import {getEmptyProvider, Provider} from "@/api/models/provider";
import {getEmptyStorage, Storage} from "@/api/models/storage";
import {getEmptyUnit, Unit} from "@/api/models/unit";
import {getEmptySachkonto, Sachkonto} from "@/api/models/sachkonto";
import {getEmptyMvz, Mvz} from "@/api/models/mvz";
import {getEmptyInnerOrder, InnerOrder} from "@/api/models/inner_order";

export interface Product {
    id: number
    number: number | null,
    title: string
    provider?: Provider
    providerId: number | null
    baseCount: number | null
    count: number | null
    limitWarning: number | null
    storage?: Storage
    storageId: number | null,
    storageRowId: number | null,
    storageStackId: number | null,
    storageBoardId: number | null,
    storageCellId: number | null,
    group?: GroupProduct
    groupId: number | null
    storageRow?: StorageRow
    storageStack?: StorageStack
    storageBoard?: StorageBoard
    storageCell?: StorageCell

    price: string
    priceEuro: number | null
    priceCent: number | null
    desc: string

    unitId: number | null,
    unit?: Unit,

    sachkonto?: Sachkonto
    sachkontoId: number
    mvz?: Mvz
    mvzId: number | null,
    innerOrder?: InnerOrder
    innerOrderId: number

}


export function getEmptyProduct(): Product {
    return {
        id: 0,
        number: null,
        title: "",
        provider: getEmptyProvider(),
        baseCount: null,
        limitWarning: 1000,
        storage: getEmptyStorage(),
        storageId: null,
        storageRowId: null,
        storageStackId: null,
        storageBoardId: null,
        storageCellId: null,
        priceEuro: null,
        priceCent: null,
        group: getEmptyGroupProduct(),
        storageRow: getEmptyStorageRow(),
        storageStack: getEmptyStorageStack(),
        storageBoard: getEmptyStorageBoard(),
        storageCell: getEmptyStorageCell(),
        groupId: 0,
        providerId: 0,
        desc: '',
        unitId: null,
        unit: getEmptyUnit(),
        sachkontoId: 0,
        mvzId: null,
        innerOrderId: 0,
        sachkonto: getEmptySachkonto(),
        mvz: getEmptyMvz(),
        innerOrder: getEmptyInnerOrder(),
        count: 0,
        price: '',
    };
}
