import {AppFormField, AppFormFieldType, AppFormFieldTypeView} from "@/share/forms/forms_definitions";
import {StorageStack} from "@/api/models/storage_stack";
import {FieldBtnSave} from "@/share/forms/form";
import {fieldRequired} from "@/share/fieldRequired";

export const storageStackFields: AppFormField<StorageStack>[] = [
    {
        label: 'ID',
        key: 'id',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        disabled: true,
        conditionVisible: (r) => r.id != 0,
    },
    {
        label: 'Title',
        key: 'title',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
    },
    {
        label: 'Boards',
        key: 'storageBoards',
        type: AppFormFieldType.array,
        typeView: AppFormFieldTypeView.form,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        required: true,
        form: [{
            key: 'title',
            type: AppFormFieldType.text,
            typeView: AppFormFieldTypeView.text,
            rules: [{required: true, message: fieldRequired}],
            required: true,
            placeholder: 'Add board',
            width: '100%',
            label: 'NONE'
        }, {
            key: 'remove',
            type: AppFormFieldType.button,
            typeView: AppFormFieldTypeView.button,
            label: '-',
            class: 'default'
        }],
    },
    {...FieldBtnSave, width: '350px', key: 'action'},
];
