import {AppTable} from "@/share/AppTable.vue";
import {DeliveryItem} from "@/api/models/delivery_item";
import store from "@/store";
import {Storage} from "@/api/models/storage";

export const deliveryItemTable: AppTable<DeliveryItem> = {
    title: 'Элемент в поставке на склад',
    columns: [
        {
            title: 'ID',
            type: 'plain',
            code: 'id',
        },
        {
            title: 'Товар',
            type: 'plain',
            code: 'product',
            render: (v, r) =>r.product?.title ?? r.product,
        },
        {
            title: 'Количество',
            type: 'plain',
            code: 'count',
        },
        {
            title: 'Склад',
            type: 'plain',
            code: 'storage',
            render: (v, r) => {
                let val  = r.storage?.title ?? r.storage;

                const items = (store.getters.storageModuleItems as Storage[]);
                return items.find(e => (e.id == r.storageId) || (e.id == r.storage.id))?.title ?? val;
            },
        },
        {
            title: 'Ряд',
            type: 'plain',
            code: 'row',
            render: (v, r) => {
                const  val = r.row?.title ?? r.row;

                const row = (window as any).rows?.find(e => e.id == r.rowId);

                return row?.title ?? val;
            },
        },
        {
            title: 'Стеллаж',
            type: 'plain',
            code: 'stack',
            render: (v, r) => {
                const  val = r.stack?.title ?? r.stack;

                return (window as any).stacks?.find(e => e.id == r.stackId)?.title ?? val;
            },
        },
        {
            title: 'Полка',
            type: 'plain',
            code: 'board',
            render: (v, r) => {
                const  val = r.board?.title ?? r.board;

                return (window as any).boards?.find(e => e.id == r.boardId)?.title ?? val;
            },
        },
        {
            title: 'Ячейка',
            type: 'plain',
            code: 'cell',
            render: (v, r) => {
                const  val = r.cell?.title ?? r.cell;

                return (window as any).cells?.find(e => e.id == r.cellId)?.title ?? val;
            },
        },
        {
            title: 'Подячейка',
            type: 'plain',
            code: 'subCell',
            render: (v, r) => {
                const  val = r.subCell?.title ?? r.subCell;

                return (window as any).subCells?.find(e => e.id == r.subCellId)?.title ?? val;
            },
        },
    ]
};
