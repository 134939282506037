import {Module} from "vuex/types";
import {RootState} from "@/store/index";
import {MetaPage} from "@/Models/MetaPage";
import {Locale} from '@/api/models/locale.ts'
import {api} from "@/api/api";

type T = Locale;

interface State {
    isPending: boolean,
    items: T[],
    meta?: MetaPage,
    params: { [x: string]: any }
}

export enum MUTATIONS_LOCALE {
    INIT = 'MUTATIONS_LOCALE/INIT',
    SET = 'MUTATIONS_LOCALE/SET',
    UPDATE_PENDING = 'MUTATIONS_LOCALE/UPDATE_PENDING',
}

export enum ACTIONS_LOCALE {
    INIT = 'ACTIONS_LOCALE/INIT',
    LOAD = 'ACTIONS_LOCALE/LOAD',
    RELOAD = 'ACTIONS_LOCALE/RELOAD',
    CREATE = 'ACTIONS_LOCALE/CREATE',
    UPDATE = 'ACTIONS_LOCALE/UPDATE',
    DELETE = 'ACTIONS_LOCALE/DELETE',
}

export const LocaleModule: Module<State, RootState> = {
    // namespaced: true,
    state: {
        isPending: false,
        items: [],
        meta: undefined,
        params: {}
    },
    mutations: {
        [MUTATIONS_LOCALE.INIT](state) {
            state.items = [];
            state.meta = undefined;
            state.isPending = false;
            state.params = {};
        },
        [MUTATIONS_LOCALE.SET](state, {
            payload,
            meta,
            isPending,
            params,
        }: { payload: T[], meta: MetaPage, isPending: boolean, params?: {[x: string]: any} }) {
            state.items = payload;
            state.meta = meta;
            state.isPending = isPending;
            state.params = params ?? {};
        },
        [MUTATIONS_LOCALE.UPDATE_PENDING](state, flag: boolean) {
            state.isPending = flag;
        },
    },
    actions: {
        async [ACTIONS_LOCALE.INIT]({commit}) {
            commit(MUTATIONS_LOCALE.INIT)
        },
        async [ACTIONS_LOCALE.LOAD]({commit, state}, params?: { page: number }) {
            console.log('ACTIONS_LOCALE.LOAD', params)
            commit(MUTATIONS_LOCALE.UPDATE_PENDING, true)
            const res = await api.getLocales();
            commit(MUTATIONS_LOCALE.SET, {...res, isPending: false, params: params})
        },
        async [ACTIONS_LOCALE.RELOAD]({commit, state}) {
            console.log('ACTIONS_LOCALE.RELOAD')
            commit(MUTATIONS_LOCALE.UPDATE_PENDING, true)
            const res = await api.getLocales();
            commit(MUTATIONS_LOCALE.SET, {...res, isPending: false, params: state.params})
        },
        async [ACTIONS_LOCALE.CREATE]({commit, state, dispatch}, params: T) {
            console.log('ACTIONS_LOCALE.CREATE', params)
            commit(MUTATIONS_LOCALE.UPDATE_PENDING, true)
            const res = await api.createLocale({payload: params as any});
            await dispatch(ACTIONS_LOCALE.RELOAD);
            return res;
        },
        async [ACTIONS_LOCALE.UPDATE]({commit, state, dispatch}, params: T) {
            console.log('ACTIONS_LOCALE.UPDATE', params)
            commit(MUTATIONS_LOCALE.UPDATE_PENDING, true)
            const res = await api.updateLocale({payload: params, id: params.key});
            console.log({res})
            await dispatch(ACTIONS_LOCALE.RELOAD);
            return res;
        },
        async [ACTIONS_LOCALE.DELETE]({commit, state, dispatch}, id: number ) {
            console.log('ACTIONS_LOCALE.DELETE', id)
            commit(MUTATIONS_LOCALE.UPDATE_PENDING, true)
            const res = await api.deleteLocale({id: id});
            await dispatch(ACTIONS_LOCALE.RELOAD);
            return res;
        },
    },
    getters: {
        localeModuleItems: (state): T[] => state.items ?? [],
        localeModuleMeta: (state): MetaPage | undefined => state.meta,
        localeModuleIsPending: (state): boolean | undefined => state.isPending,
    }
}
