import {AppFormField, AppFormFieldType, AppFormFieldTypeView} from "@/share/forms/forms_definitions";
import {Order} from "@/api/models/order";
import {FieldBtnSave} from "@/share/forms/form";
import {OrderItem} from "@/api/models/order_item";
import {api} from "@/api/api";
import {Storage} from "@/api/models/storage";
import {Sachkonto} from "@/api/models/sachkonto";
import store from "@/store";
import {Shop} from "@/api/models/shop";
import {Mvz} from "@/api/models/mvz";
import {StorageRow} from "@/api/models/storage_row";
import {StorageStack} from "@/api/models/storage_stack";
import {StorageBoard} from "@/api/models/storage_board";
import {StorageCell} from "@/api/models/storage_cell";
import {MUTATIONS_PRODUCT} from "@/store/ProductModule";
import {fieldRequired} from "@/share/fieldRequired";
import {getOptionMapTitle, titleField} from "@/form/utils";

export const orderItemFields: AppFormField<OrderItem>[] = [
    {
        label: 'ID',
        key: 'id',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        conditionVisible: (r) => r.id != 0,
        disabled: true,
    },
    {
        label: 'Type',
        key: 'type',
        id: 'order-elem-type-input',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.list,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        options: [
            {value: '!', label: '!'},
            {value: 'K', label: 'K'},
            {value: 'F', label: 'F'},
        ],
    },
    {
        label: 'Product',
        key: 'product',
        id: 'order-elem-product-input',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.autocomplete,
        placeholder: 'Select',
        rules: [{required: true, message: fieldRequired}],
        actionGet: async (text: string, cb: (arg: any) => void) => {
            const products = await api.getProducts({page: 1, search: text.split('(')[0].trim()});
            const res = products.payload.map((e: Sachkonto) => getOptionMapTitle(e))
            cb(res);

            if (products.payload?.length) {
                products.payload.forEach(item => store.commit(MUTATIONS_PRODUCT.ADD, item))
            }
        },
        renderValue: (text: any) => titleField(text),
    },
    {
        label: 'Count',
        key: 'count',
        id: 'order-elem-count-input',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
    },
    {
        label: 'Sachkonto',
        key: 'sachkonto',
        id: 'order-elem-account-input',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.autocomplete,
        placeholder: 'Select',
        rules: [{required: true, message: fieldRequired}],
        actionGet: async (text: string, cb: (arg: any) => void) => {
            const res = (await api.getSachkonto({page: 1, search: text.split('(')[0].trim(), searchMode: true}))
                .payload
                .map((e: Sachkonto) => ({
                    value: e.title,
                    item: e,
                }))
            cb(res);
        },
        renderValue: (text: any) => titleField(text),
    },
    {
        label: 'Mvz',
        key: 'mvz',
        id: 'order-elem-mvz-input',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.autocomplete,
        placeholder: 'Select',
        rules: [{required: true, message: fieldRequired}],
        actionGet: async (text: string, cb: (arg: any) => void) => {
            const res = (await api.getMvz({page: 1, search: text.split('(')[0].trim(), searchMode: true}))
                .payload
                .map((e: Mvz) => ({
                    value: e.title,
                    item: e,
                }))
            cb(res);
        },
        renderValue: (text: any) => titleField(text),
    },
    {
        label: 'Inner order',
        key: 'innerOrder',
        id: 'order-elem-inner-input',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.autocomplete,
        placeholder: 'Select',
        rules: [{required: true, message: fieldRequired}],
        actionGet: async (text: string, cb: (arg: any) => void) => {
            const res = (await api.getInnerOrder({page: 1, search: text.split('(')[0].trim(), searchMode: true}))
                .payload
                .map((e: Sachkonto) => ({
                    value: e.title,
                    item: e,
                }))
            cb(res);
        },
        renderValue: (text: any) => titleField(text),
    },
    {
        label: 'Price',
        key: 'priceByUnit',
        id: 'order-elem-price-input',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
    },
    {
        label: 'Storage',
        key: 'storageId',
        id: 'order-elem-storage-input',
        placeholder: 'Select',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.list,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        options: async (values: OrderItem) => {
            if (!values.productId) return []

            return (await api.getStorage({productId: values.productId}))?.payload?.map(e => ({
                label: e.title,
                value: e.id
            })) ?? [];
        },
        validate: (val, oldVal) => {
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {
        label: 'Row',
        key: 'rowId',
        id: 'order-elem-row-input',
        placeholder: 'Select',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.list,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        options: async (values) => {
            if (!values.storageId || !values.productId) return []

            return (await api.getStorageRowByStorage({
                id: values.storageId,
                productId: values.productId
            })).payload.map((e: StorageRow) => ({
                value: e.id,
                label: e.title
            }));
        },
        validate: (val, oldVal) => {
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {
        label: 'Stack',
        key: 'stackId',
        id: 'order-elem-stack-input',
        placeholder: 'Select',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.list,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        options: async (values) => {
            if (!values.rowId || !values.productId) return []

            return (await api.getStorageStackByRow({
                id: values.rowId,
                productId: values.productId
            })).payload.map((e: StorageStack) => ({
                value: e.id,
                label: e.title
            }));
        },
        validate: (val, oldVal) => {
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {
        label: 'Board',
        key: 'boardId',
        id: 'order-elem-board-input',
        placeholder: 'Select',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.list,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        options: async (values) => {
            if (!values.stackId || !values.productId) return []

            return (await api.getBoardByStack({
                id: values.stackId,
                productId: values.productId
            })).payload.map((e: StorageBoard) => ({
                value: e.id,
                label: e.title
            }));
        },
        validate: (val, oldVal) => {
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {
        label: 'Cell',
        key: 'cellId',
        id: 'order-elem-cell-input',
        placeholder: 'Select',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.list,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        options: async (values) => {
            if (!values.boardId || !values.productId) return []

            return (await api.getCellByBoard({
                id: values.boardId,
                productId: values.productId
            })).payload.map((e: StorageCell) => ({
                value: e.id,
                label: e.title
            }));
        },
        validate: (val, oldVal) => {
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {
        label: 'Sub cell',
        key: 'subCellId',
        id: 'order-elem-cell-input',
        placeholder: 'Select',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.list,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        options: async (values) => {
            if (!values.boardId || !values.productId) return []

            return (await api.getSubCellByCell({
                id: values.cellId,
                productId: values.productId
            })).payload.map((e: StorageCell) => ({
                value: e.id,
                label: e.title
            }));
        },
        validate: (val, oldVal) => {
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {...FieldBtnSave, width: '350px', key: 'action', id: 'order-elem-save-btn'},
];
