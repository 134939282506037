import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_layout_table = _resolveComponent("layout-table")!
  const _component_app_form = _resolveComponent("app-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_layout_table, {
      columns: _ctx.columnsTable.columns,
      title: _ctx.locale(`${_ctx.nameEntity}.table`, _ctx.columnsTable.title),
      store: "provider",
      onClickRow: _ctx.openProvider,
      deleted: true,
      onDelete: _ctx.handleDelete
    }, {
      button: _withCtx(() => [
        _createVNode(_component_el_button, { onClick: _ctx.handleOpenCreate }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.locale(`action.create`, 'Create')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["columns", "title", "onClickRow", "onDelete"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.openModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openModal) = $event)),
      title: _ctx.selectItem?.id ? _ctx.locale(`${_ctx.nameEntity}.form.update`, 'Update') : _ctx.locale(`${_ctx.nameEntity}.form.create`, 'Create'),
      "custom-class": "modal-provider",
      top: "10px",
      "before-close": _ctx.doYouClose
    }, {
      default: _withCtx(() => [
        (_ctx.openModal)
          ? (_openBlock(), _createBlock(_component_app_form, {
              key: 0,
              "name-entity": _ctx.nameEntity,
              "is-sending": _ctx.loading,
              fields: _ctx.form,
              values: _ctx.selectItem,
              onAction: _ctx.handleAction
            }, null, 8, ["name-entity", "is-sending", "fields", "values", "onAction"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "title", "before-close"])
  ]))
}