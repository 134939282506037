import {AppFormField, AppFormFieldType, AppFormFieldTypeView} from "@/share/forms/forms_definitions";
import {Product} from "@/api/models/product";
import {FieldBtnSave} from "@/share/forms/form";
import store from "@/store";
import {Provider} from "@/api/models/provider";
import {api} from "@/api/api";
import {Storage} from "@/api/models/storage";
import {Unit} from "@/api/models/unit";
import {fieldRequired} from "@/share/fieldRequired";
import {Sachkonto} from "@/api/models/sachkonto";
import {InnerOrder} from "@/api/models/inner_order";
import {Mvz} from "@/api/models/mvz";
import {getTitleWithNumber} from "@/form/utils";

export const productFields: AppFormField<Product>[] = [
    {
        label: 'Номер',
        key: 'number',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        validate: (val, oldVal) => {
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
        disabled: true,
        conditionVisible: values => values.id,
    },
    {
        label: 'Title',
        key: 'title',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        validate: (val, oldVal) => {
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {
        label: 'Desc',
        key: 'desc',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.textarea,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        validate: (val, oldVal) => {
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {
        label: 'Unit',
        key: 'unitId',
        placeholder: 'Select',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.list,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        // options: [{label: '111', value: '222'}],
        options: () => (store.getters.unitModuleItems as Unit[])?.map(e => ({label: e.title, value: e.id})) ?? [],
        validate: (val, oldVal) => {
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {
        label: 'Group',
        key: 'group',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.autocomplete,
        placeholder: 'Select',
        rules: [{required: true, message: fieldRequired}],
        actionGet: async (text: string, cb: (arg: any) => void) => {
            const res = (await api.getGroupProducts({page: 1, search: text})).payload.map((e: Storage) => ({
                value: e.title,
                item: e,
            }))
            cb(res);
        },
        renderValue: (text: any) => {
            console.log(`renderValue `, text)
            return text ? (text.title ?? text) : text;
        },
        validate: (val, oldVal) => {
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {
        label: 'Provider',
        key: 'provider',
        placeholder: 'Select',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.autocomplete,
        rules: [{required: true, message: fieldRequired}],
        options: (store.getters.providerModuleItems as Provider[]).map(e => ({label: e.title, value: e.id})),
        actionGet: async (text: string, cb: (arg: any) => void) => {
            const res = (await api.getProviders({page: 1, search: text})).payload.map((e: Provider) => ({
                value: e.title,
                item: e,
            }))
            cb(res);
        },
        renderValue: (text: any) => {
            console.log(`renderValue `, text)
            return text ? (text.title ?? text) : text;
        },
        validate: (val, oldVal) => {
            console.log(val)
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {
        label: 'Sachkonto',
        key: 'sachkonto',
        placeholder: 'Select',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.autocomplete,
        rules: [{required: true, message: fieldRequired}],
        actionGet: async (text: string, cb: (arg: any) => void) => {
            const res = (await api.getSachkonto({page: 1, search: text.split('(')[0].trim(), searchMode: true}))
                .payload
                .map((e: Sachkonto) => ({
                    value: e.title,
                    item: e,
                }))
            cb(res);
        },
        renderValue: (text: any, values) => {
            console.log(`renderValue `, text)
            return getTitleWithNumber(text);
        },
        validate: (val, oldVal) => {
            console.log(val)
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {
        label: 'Mvz',
        key: 'mvz',
        placeholder: 'Select',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.autocomplete,
        rules: [{required: true, message: fieldRequired}],
        actionGet: async (text: string, cb: (arg: any) => void) => {
            const res = (await api.getMvz({page: 1, search: text.split('(')[0].trim(), searchMode: true}))
                .payload
                .map((e: Mvz) => ({
                    value: e.title,
                    item: e,
                }))
            cb(res);
        },
        renderValue: (text: any) => {
            console.log(`renderValue `, text)
            return getTitleWithNumber(text);
        },
        validate: (val, oldVal) => {
            console.log(val)
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {
        label: 'Inner order',
        key: 'innerOrder',
        placeholder: 'Select',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.autocomplete,
        rules: [{required: true, message: fieldRequired}],
        actionGet: async (text: string, cb: (arg: any) => void) => {
            const res = (await api.getInnerOrder({
                page: 1,
                search: text.split('(')[0].trim(),
                searchMode: true,
            })).payload.map((e: InnerOrder) => ({
                value: e.title,
                item: e,
            }))
            cb(res);
        },
        renderValue: (text: any) => {
            console.log(`renderValue `, text)
            return getTitleWithNumber(text);
        },
        validate: (val, oldVal) => {
            console.log(val)
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {
        label: 'Limit warning',
        key: 'limitWarning',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.number,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        validate: (val, oldVal) => {
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {
        label: 'Price',
        key: 'price',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        validate: (val, oldVal) => {
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {
        label: 'Count',
        key: 'count',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.number,
    },

    {...FieldBtnSave, width: '350px', key: 'action'},
];
