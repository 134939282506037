export interface Mvz {
    id: number
    number: string
    title: string

}

export function getEmptyMvz(): Mvz {
    return {
        id: 0,
        number: "",
        title: "",
    };
}
