import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import ProductList from "@/views/ProductList.vue";
import Page404 from "@/views/Page404.vue";
import GroupProductList from "@/views/GroupProductList.vue";
import ProviderList from "@/views/ProviderList.vue";
import LocaleList from "@/views/LocaleList.vue";
import StoragesList from "@/views/StoragesList.vue";
import StorageView from "@/views/StorageView/StorageView.vue";
import StorageRowView from "@/views/StorageView/StorageRowView.vue";
import StorageStackView from "@/views/StorageView/StorageStackView.vue";
import ShopList from "@/views/ShopList.vue";
import UnitList from "@/views/UnitList.vue";
import SachkontoList from "@/views/SachkontoList.vue";
import MvzList from "@/views/MvzList.vue";
import InnerOrderList from "@/views/InnerOrderList.vue";
import DeliveryList from "@/views/DeliveryList/DeliveryList.vue";
import DeliveryView from "@/views/DeliveryList/DeliveryView.vue";
import OrderView from "@/views/OrderList/OrderView.vue";
import OrderList from "@/views/OrderList/OrderList.vue";
import PageAuth from "@/views/PageAuth.vue";
import UserList from "@/views/UserList.vue";
import LogList from "@/views/LogList.vue";

export const routesKeys = {
    Order: {
        name: 'order',
        path: '/',
        title: 'Orders',
        component: OrderList,
        roles: ['admin', 'user'],
    },
    OrderView: {
        name: 'orderView',
        path: '/order/:id',
        component: OrderView,
        roles: ['admin', 'user'],
    },
    Storages: {
        name: 'storage',
        path: '/storage',
        title: 'Склады',
        component: StoragesList,
        roles: ['admin'],
    },
    Shop: {
        name: 'shop',
        path: '/shop',
        title: 'Shop',
        component: ShopList,
        roles: ['admin'],
    },
    StorageView: {
        name: 'storage-view',
        path: '/storage/:id',
        component: StorageView,
        roles: ['admin'],
    },
    StorageRowView: {
        name: 'storage-row-view',
        path: '/storage/:id/row/:id_row',
        component: StorageRowView,
        roles: ['admin'],
    },
    StorageStackView: {
        name: 'storage-stack-view',
        path: '/storage/:id/row/:id_row/stack',
        component: StorageStackView,
        roles: ['admin'],
    },
    Product: {
        name: 'product',
        path: '/products',
        title: 'Товары',
        component: ProductList,
        roles: ['admin'],
    },
    GroupProduct: {
        name: 'group_product',
        path: '/group-products',
        title: 'Группа товаров',
        component: GroupProductList,
        roles: ['admin'],
    },
    Provider: {
        name: 'provider',
        path: '/provider',
        title: 'Поставщик',
        component: ProviderList,
        roles: ['admin'],
    },
    Locale: {
        name: 'Locale',
        path: '/locales',
        component: LocaleList,
        roles: ['admin'],
    },
    Unit: {
        name: 'unit',
        path: '/unit',
        component: UnitList,
        title: 'Unit',
        roles: ['admin'],
    },
    Sachkonto: {
        name: 'sachkonto',
        path: '/sachkonto',
        component: SachkontoList,
        title: 'Sachkonto',
        roles: ['admin'],
    },
    Mvz: {
        name: 'mvz',
        path: '/mvz',
        component: MvzList,
        title: 'Mvz',
        roles: ['admin'],
    },
    InnerOrder: {
        name: 'innerOrder',
        path: '/inner_order',
        component: InnerOrderList,
        title: 'Inner order',
        roles: ['admin'],
    },
    Delivery: {
        name: 'delivery',
        path: '/delivery',
        component: DeliveryList,
        title: 'Поставки',
        roles: ['admin'],
    },
    DeliveryView: {
        name: 'deliveryView',
        path: '/delivery/:id',
        component: DeliveryView,
        roles: ['admin'],
    },
    User: {
        name: 'user',
        path: '/user',
        component: UserList,
        title: 'users',
        roles: ['admin'],
    },
    Logs: {
        name: 'logs',
        path: '/logs',
        component: LogList,
        // title: 'Logs',
        roles: ['admin'],
    },
}


const routes: Array<RouteRecordRaw> = [
    {
        name: 'auth',
        component: PageAuth,
        path: '/auth',
    },
    routesKeys.Storages,
    routesKeys.Shop,
    routesKeys.StorageView,
    routesKeys.StorageStackView,
    routesKeys.StorageRowView,
    routesKeys.Product,
    routesKeys.GroupProduct,
    routesKeys.Provider,
    routesKeys.Order,
    routesKeys.OrderView,
    routesKeys.Locale,
    routesKeys.Unit,
    routesKeys.Sachkonto,
    routesKeys.Mvz,
    routesKeys.InnerOrder,
    routesKeys.Delivery,
    routesKeys.DeliveryView,
    routesKeys.User,
    routesKeys.Logs,
    {
        path: '/:catchAll(.*)',
        name: 'Page404',
        component: Page404,
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    const r = Object.values(routesKeys).find(e => e.name == to.name)

    const roles = JSON.parse(localStorage.getItem('roles') ?? '[]') ?? []

    if (location.hash == '#/auth') {
        next();
    }

    if (r?.roles?.find(e => roles.includes(e))) {
        next();
    } else {
        location.href = '/#/auth'
    }
})

export default router
