import {StorageRow} from "@/api/models/storage_row";
import {getEmptyShop, Shop} from "@/api/models/shop";

export interface Storage {
    id: number
    title: string
    responsible: string
    shopId?: number | null
    shop: Shop
    storageId: number
    storageRow: StorageRow[]
}

export function getEmptyStorage(): Storage {
    return {
        id: 0,
        responsible: "",
        title: "",
        shop: getEmptyShop(),
        storageId: 0,
        storageRow: [],
        shopId: null,
    };
}
