import {getEmptyShop, Shop} from "@/api/models/shop";
import {getEmptyProvider, Provider} from "@/api/models/provider";
import {OrderStatus} from '@/api/models/order_status'

export interface Order {
    id: number
    number: string
    dateSend: string
    shopId: number | null
    shop: Shop
    status: OrderStatus
    sum: number
    providerId: number | null
    provider: Provider
    updatedAt: string,
    createdAt: string,
}

export function getEmptyOrder(): Order {
    return {
        id: 0,
        number: '',
        dateSend: new Date().toUTCString(),
        updatedAt: new Date().toUTCString(),
        createdAt: new Date().toUTCString(),
        shopId: null,
        shop: getEmptyShop(),
        status: OrderStatus.Draft,
        sum: 0,
        providerId: null,
        provider: getEmptyProvider(),
    };
}
