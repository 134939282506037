import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: "StorageStackView" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_layout_header = _resolveComponent("layout-header")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_app_form = _resolveComponent("app-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_layout_header, {
      key: "storageHeader",
      title: `${_ctx.item?.title} / Lagerregal`,
      loading: _ctx.loading
    }, {
      button: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_el_button, { onClick: _ctx.handleOpenCreate }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.locale(`storage.meta.add_stack`, 'Add stack')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["title", "loading"]),
    _createVNode(_component_app_table, {
      key: "tableRow",
      columns: _ctx.tableRows.columns,
      items: _ctx.item?.storageStack,
      deleted: true,
      "name-entity": "storage",
      onDelete: _ctx.handleDeleteCell,
      onClickRow: _ctx.handleOpenCell,
      loading: _ctx.loading
    }, null, 8, ["columns", "items", "onDelete", "onClickRow", "loading"]),
    (_ctx.item)
      ? (_openBlock(), _createBlock(_component_el_dialog, {
          key: 0,
          modelValue: _ctx.openModal,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openModal) = $event)),
          title: _ctx.selectItem?.id ? _ctx.locale(`storage.meta.update_stack`, 'Update stack') : _ctx.locale(`storage.meta.add_stack`, 'Add stack'),
          "custom-class": "modal-locale",
          top: "10px",
          "before-close": _ctx.doYouClose
        }, {
          default: _withCtx(() => [
            (_ctx.openModal)
              ? (_openBlock(), _createBlock(_component_app_form, {
                  key: 0,
                  class: "stack-form",
                  "is-sending": _ctx.loading,
                  fields: _ctx.form,
                  values: _ctx.selectItem,
                  onAction: _ctx.handleAction,
                  references: _ctx.references,
                  "name-entity": _ctx.nameEntity,
                  layout: "flex"
                }, null, 8, ["is-sending", "fields", "values", "onAction", "references", "name-entity"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue", "title", "before-close"]))
      : _createCommentVNode("", true)
  ]))
}