
import {Options, Vue} from "vue-class-component";
import AppIcons from "../../share/AppIcons.vue";
import LayoutTable from "../../share/Layout/LayoutTable.vue";
import AppForm from "@/share/AppForm.vue";
import {doYouClose} from "@/share/utils/doYouClose";
import {ElMessage, ElMessageBox} from "element-plus";
import {FormAction} from "@/share/forms/forms_definitions";
import store from "@/store";
import {ApiResponse} from "@/api/utils";
import {locale, localeError} from "@/share/locale";
import LayoutHeader from "@/share/Layout/LayoutHeader.vue";
import AppTable from "@/share/AppTable.vue";
import {api, basePath} from "@/api/api";
import {getEmptyOrder, Order} from "@/api/models/order";
import {getEmptyOrderItem, OrderItem} from "@/api/models/order_item";
import {orderFieldsView} from "@/form/order_form_view";
import {orderItemTable} from "@/table/order_item_columns";
import {orderItemFields} from "@/form/order_item_form";
import {ACTIONS_ORDER} from "@/store/OrderModule";
import {OrderStatus} from "@/api/models/order_status";
import moment from "moment";
import {referencesStorage} from "@/share/referenceStorage";
import {DeliveryItem} from "@/api/models/delivery_item";
import {getEmptyProduct} from "@/api/models/product";
import {getEmptySachkonto} from "@/api/models/sachkonto";
import {getEmptyMvz} from "@/api/models/mvz";
import {getEmptyInnerOrder} from "@/api/models/inner_order";
import {priceByUnitToString} from "@/share/priceByUnitToString";

type T = OrderItem;

@Options({
  components: {
    AppTable,
    LayoutHeader,
    AppForm,
    AppIcons,
    LayoutTable,
  },
})
export default class OrderView extends Vue {

  items: T[] = []

  isLoaded = false;

  async created() {
    await this.load();
    await this.loadItems()

    const _this = this;
    setTimeout(() => _this.isLoaded = true, 100);
  }

  form = orderFieldsView;
  formRow = orderItemFields;

  tableRows = orderItemTable;

  openModal = false;
  selectItem = getEmptyOrderItem();
  selectProduct = getEmptyProduct();

  newOrderItems: OrderItem[] = []
  removedOrderItems: number[] = []
  updatedOrderItems: OrderItem[] = []

  values: Order = getEmptyOrder();

  Status = OrderStatus;

  doYouClose = doYouClose;
  locale = locale;
  nameEntity = 'order'

  loading = false

  basePath = basePath

  references = {
    ...referencesStorage,
    'product': (values: OrderItem) => {
      console.log(222)
      return {
        'storageId': '',
        'rowId': '',
        'stackId': '',
        'boardId': '',
        'cellId': '',
      }
    },
  }

  async handleAction() {
    this.values = (this.$refs.AppFormValues as any)!.valuesLocal;

    const item = this.values;

    let r = item.dateSend
    if (typeof (item as any).dateSend == "object") {
      const d = (item as any).dateSend;

      r = moment(d).utcOffset(0, true).format('YYYY-MM-DD')
    }

    const val = {...item, dateSend: r};

    let res: ApiResponse<any>
    let flag = false;
    if (this.values.id) {
      res = await store.dispatch(ACTIONS_ORDER.UPDATE, val);
    } else {
      res = await store.dispatch(ACTIONS_ORDER.CREATE, val);
    }
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
      return
    } else {
      flag = true;
    }

    await this.handleActionOrderItems();

    this.removedOrderItems = [];
    this.newOrderItems = [];
    this.updatedOrderItems = [];

    await this.loadItems()

    if (flag) {
      ElMessage({type: 'success', message: locale(`request.success`, 'Success'),})
    }
  }

  async handleActionOrderItems() {
    for (let item of this.removedOrderItems) {
      const res = await api.deleteOrderItem({id: item});
      if (res.error) {
        ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
      }
    }
    for (let item of this.newOrderItems) {
      await this.handleActionOrderItem(item);
    }
    for (let item of this.updatedOrderItems) {
      await this.handleActionOrderItem(item);
    }
  }

  async handleActionOrderItem(item: T) {
    let res: ApiResponse<Order>

    let fixItem: T = {...item}

    fixItem.priceByUnitCent = fixItem.priceByUnitCent || '0';

    if (item.id) {
      res = await api.updateOrderItem({payload: fixItem, id: item.id})
    } else {
      res = await api.createOrderItem({payload: fixItem})
    }

    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
    }

    this.openModal = false;
  }

  applyActionOrderItem(item: FormAction<T>) {
    const values = item.values;
    const prices = values.priceByUnit?.toString()?.replaceAll(',', '.')?.split('.') ?? []
    const cent = prices[1] ?? '0';
    values.priceByUnitEuro = prices[0] ?? '0';
    values.priceByUnitCent = cent.length < 2 ? cent + '0' : cent;

    if (values.id) {
      values.mvz = store.getters.mvzModuleItems.find(e => e.id == values.mvzId);
      this.updatedOrderItems.push(values);
      const index = this.items.findIndex(e => e.id == values.id);
      if (index != -1) {
        this.items.splice(index, 1, values);
      }
    } else {
      if (values.uid) {
        const index = this.newOrderItems.findIndex(e => e.uid == values.uid);
        if (index != -1) {
          this.newOrderItems.splice(index, 1);
        }
        const index2 = this.items.findIndex(e => e.uid == values.uid);
        if (index2 != -1) {
          this.items.splice(index, 1);
        }
        const res = {...values, uid: Math.random()};
        this.newOrderItems.push(res);
        this.items.unshift(res);
      } else {
        const res = {...values, uid: Math.random()};
        this.newOrderItems.push(res);
        this.items.unshift(res);
      }
    }

    this.openModal = false;
  }

  get id() {
    return Number(this.$route.params.id);
  }

  async handleDeleteRow(item: T) {
    if (this.values.status === OrderStatus.Finished) {
      return
    }

    await ElMessageBox.confirm(
        locale(`orderItem.form.delete`, 'Delete', `${item.id}`),
        locale(`modal.title.warning`, 'Warning'),
        {
          confirmButtonText: locale(`action.delete`, 'Delete'),
          cancelButtonText: locale(`action.cancel`, 'Cancel'),
          type: 'warning',
        }
    )

    if (item.orderId) {
      this.removedOrderItems.push(item.id)
      const index = this.items.findIndex(e => e.id == item.id)
      if (index != -1) {
        this.items.splice(index, 1)
      }
    }

    if (item.uid) {
      const index = this.items.findIndex(e => e.uid == item.uid)
      if (index != -1) {
        this.items.splice(index, 1)
      }
    }

    // const res = await api.deleteRow({id: item.id})
    //
    // if (res.error) {
    //   ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    // } else {
    // }
    // await this.loadItems()
  }

  async loadItems() {
    this.items = (await api.getOrderItem({order_id: Number(this.$route.params.id), page: 1})).payload;
  }

  async load() {
    const resPlaces = await api.getProductPlaces({product_id: this.id})
    const res = await api.getOrderById({id: this.id});
    this.values = res.payload;
  }

  handleEditOrderItem(item: T) {
    this.selectItem = ({...item, priceByUnit: priceByUnitToString(item)});
    this.openModal = true;
  }

  handleOpenAdd() {
    this.selectItem = {...getEmptyOrderItem(Number(this.$route.params.id))};
    this.openModal = true;
  }

  async handleStop() {
    this.loading = true;
    await api.updateOrderStatus({id: this.id, status: OrderStatus.Canceled})
    await this.load();
    this.loading = false;

    ElMessage({type: 'success', message: locale(`request.success`, 'Success'),})
  }

  async handleEnd() {
    this.loading = true;
    await api.updateOrderStatus({id: this.id, status: OrderStatus.Finished})
    await this.load();
    this.loading = false;

    ElMessage({type: 'success', message: locale(`request.success`, 'Success'),})
  }

  handleDownload() {
    const user = JSON.parse(localStorage.getItem('user') ?? '{}')
    window.open(basePath + `/pdf/${this.id}/${user.id}`, '_blank')
  }

  async handleChangeItem(item: OrderItem) {
    if (item.productId != this.selectItem.productId) {
      this.loading = true;
      try {
        const items = await api.getProductById({id: item.productId})
        if (items.payload) {
          this.selectProduct = items.payload
        }

        this.selectItem = {...item};

        this.selectItem.sachkonto = this.selectProduct.sachkonto ? {...this.selectProduct.sachkonto} : getEmptySachkonto();
        this.selectItem.sachkontoId = this.selectProduct.sachkontoId;

        this.selectItem.mvz = this.selectProduct.mvz ? {...this.selectProduct.mvz} : getEmptyMvz();
        this.selectItem.mvzId = this.selectProduct.mvzId;

        this.selectItem.innerOrder = this.selectProduct.innerOrder ? {...this.selectProduct.innerOrder} : getEmptyInnerOrder();
        this.selectItem.innerOrderId = this.selectProduct.innerOrderId;

        this.selectItem.priceByUnitEuro = `${this.selectProduct.priceEuro}`;
        this.selectItem.priceByUnitCent = `${this.selectProduct.priceCent}`;
        this.selectItem.priceByUnit = priceByUnitToString(this.selectItem)
      } catch (e) {
        localeError(e)
      }
      this.loading = false;
    }
  }

}
