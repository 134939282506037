import store from "@/store";
import {Locale} from "@/api/models/locale";
import {camelCase} from "lodash";
import localeEn from "element-plus/lib/locale/lang/en";
import localeDe from "element-plus/lib/locale/lang/de";
import localeRu from "element-plus/lib/locale/lang/ru";

export function localePlaceholder(key: string) {
    if (!key) return ''
    return locale(key, key)
}

export function locale(key: string, placeholder: string | undefined = '----', replace = '') {
    if (placeholder === 'NONE') {
        return ''
    }

    if (store.getters.localeModuleItems?.length) {
        const res1 = store.getters.localeModuleItems.find((e: Locale) => e.key == key)
        const res2 = store.getters.localeModuleItems.find((e: Locale) => e.key == key.split('.').map(e => camelCase(e)).join('.') )
        if (res1 || res2) {
            return ((res1 ?? res2)?.[camelCase(`value_${localStorage.locale}`)] ?? placeholder).replace('?', replace) + (localStorage.getItem('showKey') ? ` (${key})` : '');
        }
    }

    return placeholder
}

export function localeError(text: string, placeholder = '----', keyDefault: string = 'request.error', replace = '') {
    const items = store.getters.localeModuleItems
    if (items?.length) {
        const res = items.find((e: Locale) => e.valueEn == text) ?? items.find((e: Locale) => e.key == keyDefault)
        return (res?.[camelCase(`value_${localStorage.locale}`)] ?? placeholder).replace('?', replace);
    }

    return placeholder
}

export function curLocale() {
    return localStorage.getItem('locale') ?? 'de';
}

export function getLocaleElementUi() {
    switch (curLocale()) {
        case 'de':
            return localeDe;
        case 'en':
            return localeEn;
        case 'ru':
            return localeRu;
        default:
            return localeEn;
    }
}
