
import {Options, Vue} from "vue-class-component";
import AppIcons from "../share/AppIcons.vue";
import LayoutTable from "../share/Layout/LayoutTable.vue";
import AppForm from "@/share/AppForm.vue";
import {doYouClose} from "@/share/utils/doYouClose";
import {providerFields} from "@/form/provider_form";
import {ElMessage, ElMessageBox} from "element-plus";
import {FormAction} from "@/share/forms/forms_definitions";
import store from "@/store";
import {ApiResponse} from "@/api/utils";
import {providerTable} from "@/table/provider_columns";
import {getEmptyLocale, Locale} from "@/api/models/locale";
import {ACTIONS_LOCALE} from "@/store/LocaleModule";
import {localeTable} from "@/table/locale_columns";
import {localeFields} from "@/form/locale_form";

@Options({
  components: {
    AppForm,
    AppIcons,
    LayoutTable,
  },
})
export default class LocaleList extends Vue {

  async created() {
  }

  filterEntity = ''
  filterEntityType = ''

  get items() {
    if (this.filterEntity) {
      let res =  (store.getters.localeModuleItems as Locale[]).filter(e => (e.key.split('.')).includes(this.filterEntity))

      if (this.filterEntityType) {
        res =  res.filter(e => e.key.split('.')[1] == this.filterEntityType)
      }

      return res;
    }
    return store.getters.localeModuleItems
  }

  columnsTable = localeTable

  form = localeFields;

  selectItem?: Locale

  openModal = false;

  doYouClose = doYouClose;

  openLocale(item) {
    this.selectItem = item;
    this.openModal = true;
  }

  get loading(): boolean {
    return store.getters.providerModuleIsPending;
  }

  async handleAction(item: FormAction<Locale>) {
    let res: ApiResponse<any>
    if (item.values.key) {
      res = await store.dispatch(ACTIONS_LOCALE.UPDATE, item.values);
    } else {
      res = await store.dispatch(ACTIONS_LOCALE.CREATE, item.values);
    }
    if (res.error) {
      ElMessage({type: 'error', message: 'Не удалось выполнить действие',})
    } else {
      this.openModal = false;
    }
  }

  handleOpenCreate() {
    this.selectItem = getEmptyLocale();
    this.openModal = true;
  }

  async handleDelete(item: Locale) {
    await ElMessageBox.confirm(
        `Удалить перевод?`,
        'Внимание',
        {
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Отмена',
          type: 'warning',
        }
    )
    const res: ApiResponse<any> = await store.dispatch(ACTIONS_LOCALE.DELETE, item.key);
    if (res.error) {
      ElMessage({type: 'error', message: 'Не удалось выполнить действие',})
    }
  }

  setLocale(locale: string) {
    localStorage.locale = locale;
  }

  setKey(flag = false) {
    if (flag) {
      localStorage.setItem('showKey', 'true')
    } else {
      localStorage.removeItem('showKey')
    }
  }

  handleClear() {
    this.filterEntity = ''
  }
}
