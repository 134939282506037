
import {Options, Vue} from "vue-class-component";
import AppIcons from "../share/AppIcons.vue";
import LayoutTable from "../share/Layout/LayoutTable.vue";
import AppForm from "@/share/AppForm.vue";
import {doYouClose} from "@/share/utils/doYouClose";
import {ElMessage, ElMessageBox} from "element-plus";
import {FormAction} from "@/share/forms/forms_definitions";
import store from "@/store";
import {ApiResponse} from "@/api/utils";
import {locale, localeError} from "@/share/locale";
import {shopTable} from "@/table/shop_columns";
import {getEmptyShop, Shop} from "@/api/models/shop";
import {ACTIONS_SHOP} from "@/store/ShopModule";
import {shopFields} from "@/form/shop_form";

@Options({
  components: {
    AppForm,
    AppIcons,
    LayoutTable,
  },
})
export default class ShopList extends Vue {

  async created() {
  }

  columnsTable = shopTable;

  form = shopFields;

  selectItem?: Shop

  openModal = false;

  doYouClose = doYouClose;
  locale = locale;
  nameEntity = 'shop'

  openItem(item) {
    this.selectItem = item;
    this.openModal = true;
  }

  get loading(): boolean {
    return store.getters.productModuleIsPending;
  }

  async handleAction(item: FormAction<Shop>) {
    let res: ApiResponse<any>
    const payload: Shop = {...item.values}
    if (item.values.id) {
      res = await store.dispatch(ACTIONS_SHOP.UPDATE, payload);
    } else {
      res = await store.dispatch(ACTIONS_SHOP.CREATE, payload);
    }
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
      this.openModal = false;
    }
  }

  handleOpenCreate() {
    this.selectItem = getEmptyShop();
    this.openModal = true;
  }

  async handleDelete(item: Shop) {
    await ElMessageBox.confirm(
        locale(`${this.nameEntity}.form.delete`, 'Delete', item.title),
        locale(`modal.title.warning`, 'Warning'),
        {
          confirmButtonText: locale(`action.delete`, 'Delete'),
          cancelButtonText: locale(`action.cancel`, 'Cancel'),
          type: 'warning',
        }
    )
    const res: ApiResponse<any> = await store.dispatch(ACTIONS_SHOP.DELETE, item.id);
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
      ElMessage({type: 'success', message: locale(`request.success`, 'Success'),})
    }
  }
}
