import {AppFormField, AppFormFieldType, AppFormFieldTypeView} from "@/share/forms/forms_definitions";
import {Order} from "@/api/models/order";
import {FieldBtnSave} from "@/share/forms/form";
import store from "@/store";
import {Provider} from "@/api/models/provider";
import {Shop} from "@/api/models/shop";
import {fieldRequired} from "@/share/fieldRequired";

export const orderFieldsView: AppFormField<Order>[] = [
    {
        label: 'Number',
        key: 'number',
        id: 'order-draft-number-input',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        width: '100%',
        params: {
            grid: '1fr',
            gridRow: 'number dateSend shopId providerId',
        },
    },
    {
        label: 'Date send',
        key: 'dateSend',
        id: 'order-draft-date-input',
        type: AppFormFieldType.date,
        typeView: AppFormFieldTypeView.dateSingle,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        width: '100%',
        params: {
            grid: '1fr',
            gridRow: 'number dateSend shopId providerId',
        },
    },
    {
        label: 'Shop',
        key: 'shopId',
        id: 'order-draft-shop-input',
        wrapDropdown: 'order-shop-dropdown',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.list,
        options: () => (store.getters.shopModuleItems as Shop[]).map(e => ({label: e.title, value: e.id})),
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        placeholder: 'Select',
        width: '100%',
        params: {
            grid: '1fr',
            gridRow: 'number dateSend shopId providerId',
        },
    },
    {
        label: 'Provider',
        key: 'providerId',
        id: 'order-draft-provider-input',
        wrapDropdown: 'order-provider-dropdown',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.list,
        options: () => (store.getters.providerModuleItems as Provider[]).map(e => ({label: e.title, value: e.id})),
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        placeholder: 'Select',
        width: '100%',
        params: {
            grid: '1fr',
            gridRow: 'number dateSend shopId providerId',
        },
    },
];
