
import {Options, Vue} from "vue-class-component";
import {routesKeys} from "@/router";
import store from "@/store";
// import {ACTIONS_USER, MUTATIONS_USER} from "@/store/AuthModule";
import ChangePassword from "@/components/ChangePassword.vue";
import {ACTIONS_LOCALE} from "@/store/LocaleModule";
import {locale} from "@/share/locale";
import {ACTIONS_PROVIDER} from "@/store/ProviderModule";
import {ACTIONS_STORAGE} from "@/store/StorageModule";
import {ACTIONS_SHOP} from "@/store/ShopModule";
import {ACTIONS_UNIT} from "@/store/UnitModule";
import {ACTIONS_MVZ} from "@/store/MvzModule";
import {api, basePath, headers} from "@/api/api";
import {Watch} from "vue-property-decorator";

const listensOpenChangePass: Function[] = [];

@Options({
  components: {ChangePassword},
  provide: {
    handleOpenChangePass(value) {
      console.log(value)
      listensOpenChangePass.forEach(func => func(value));
    }
  }
})
export default class LayoutTable extends Vue {
  listensOpenChangePass: Function[] = listensOpenChangePass;

  isLoading = true;

  locale = locale;

  @Watch('route')
  handleWatch1() {
    if (!localStorage.getItem('token') && this.route != 'auth') {
      this.$router.push({name: 'auth'})
    }
  }

  get route() {
    return this.$route.name;
  }

  get routePath() {
    return this.$route.path;
  }

  get locales() {
    return store.getters.localeModuleItems ?? []
  }

  async mounted() {
    const res = await fetch(`${basePath}/sanctum/csrf-cookie`)
    if (!localStorage.locale) {
      localStorage.locale = 'de'
    }
    await store.dispatch(ACTIONS_LOCALE.LOAD)

    if (localStorage.token) {
      headers.Authorization = `Bearer ${localStorage.token}`

      await store.dispatch(ACTIONS_PROVIDER.LOAD, {})
      await store.dispatch(ACTIONS_STORAGE.LOAD, {})
      await store.dispatch(ACTIONS_SHOP.LOAD, {})
      await store.dispatch(ACTIONS_UNIT.LOAD, {})
      await store.dispatch(ACTIONS_MVZ.LOAD, {})
    } else {
      this.$router.push({name: 'auth'})
    }
    this.isLoading = false;
  }

  get isAuth() {
    return window.localStorage.token
  }

  get user() {
    return store.getters.userInfo;
  }

  get roles(): string[] {
    return JSON.parse(localStorage.getItem('roles') ?? '[]');
  }

  get isLoadingUser() {
    return store.getters.isLoadingUser || this.isLoading;
  }

  get isPageAuth() {
    return this.$route.name == 'auth';
  }

  async logout() {
    this.isLoading = true;
    await api.logout()
    localStorage.token = ''
    this.isLoading = false;
    window.location.reload()

    // store.commit(MUTATIONS_USER.UPDATE_IS_AUTH, false);

    // (store.getters.keycloak as Keycloak.KeycloakInstance).logout()
  }

  routesKeys = routesKeys

  canSeeLink(link: any) {
    let hasRole = this.roles?.find(r => link.roles?.includes(r) ?? false);

    return link.roles ? hasRole : true;
  }
}
