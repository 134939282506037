import {AppTable} from "@/share/AppTable.vue";
import {Log} from "@/api/models/log";
import dateFormat from "@/share/utils/dateFormat";
import {locale} from "@/share/locale";

export const logTable: AppTable<Log> = {
    title: '',
    columns: [
        {
            title: 'ID',
            type: 'plain',
            code: 'id',
            width: '40px',
        },
        {
            title: 'Действие',
            type: 'plain',
            code: 'action',
            render: (text) => {
                switch (text) {
                    case 'add': return 'Создан';
                    case 'update': return 'Обновлен';
                    case 'delete': return 'Удален';
                    default: return text;
                }
            },
            width: '100px'
        },
        {
            title: 'Пользователь',
            type: 'plain',
            code: 'userId',
            render: (text, row) => {
                return row.user?.title;
            },
            width: '130px'
        },
        {
            title: 'Сущность',
            type: 'plain',
            code: 'entity',
            width: '130px'
        },
        {
            title: 'Изменения',
            type: 'plain',
            code: 'diff',
            render: (text, row) => {
                const d = JSON.parse(text ?? '{}')
                return `${Object.keys(d).map(key => locale(`${row.entity}.form.${key}`, key))}`
            }
        },
        {
            title: 'Дата',
            type: 'plain',
            code: 'createdAt',
            render: (text) => {
                return dateFormat(text)
            },
            width: '100px'
        },
    ]
};
