import {AppFormField, AppFormFieldType, AppFormFieldTypeView} from "@/share/forms/forms_definitions";
import {FieldBtnSave} from "@/share/forms/form";
import {Delivery} from "@/api/models/delivery";
import {fieldRequired} from "@/share/fieldRequired";

export const deliveryFields: AppFormField<Delivery>[] = [
    {
        label: 'ID',
        key: 'id',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired(), trigger: 'blur'}],
        conditionVisible: (r) => r.id != 0,
        disabled: true,
    },
    {
        label: 'Date send',
        key: 'dateSend',
        id: 'delivery-date-input',
        type: AppFormFieldType.date,
        typeView: AppFormFieldTypeView.dateSingle,
        rules: [{required: true, message: fieldRequired(), trigger: 'blur'}],
    },
    {
        label: 'Responsible',
        key: 'responsible',
        id: 'delivery-responsible-input',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired(), trigger: 'blur'}],
    },
    {
        ...FieldBtnSave, width: '350px', key: 'action',
        conditionVisible: (r) => r.id == 0,
        id: 'delivery-save-btn',
    },
];

export const deliveryViewFields: AppFormField<Delivery>[] = [
    {
        label: 'ID',
        key: 'id',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired(), trigger: 'blur'}],
        conditionVisible: (r) => r.id != 0,
        disabled: true,
        params: {grid: '1fr', gridRow: "id dateSend responsible"},
    },
    {
        label: 'Date send',
        key: 'dateSend',
        id: 'delivery-draft-date-input',
        type: AppFormFieldType.date,
        typeView: AppFormFieldTypeView.dateSingle,
        rules: [{required: true, message: fieldRequired(), trigger: 'blur'}],
        params: {grid: '1fr', gridRow: "id dateSend responsible"},
    },
    {
        label: 'Responsible',
        key: 'responsible',
        id: 'delivery-draft-responsible-input',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired(), trigger: 'blur'}],
        params: {grid: '1fr', gridRow: "id dateSend responsible"},
    },
];
