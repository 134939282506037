
import {Options, Vue} from 'vue-class-component';
import {AppTableColumn} from "@/share/AppTable.vue";

@Options({
  props: {
    column: {type: Object, default: () => ({})},
    row: {type: Object, default: () => ({})},
  },
  components: {},
  emits: ['click-column']
})
export default class AppTableCell<T> extends Vue {
  column!: AppTableColumn<T>;
  row!: T;

  handleClick() {
    // if (this.column.drawer) {
    //   // if (this.column.drawer == DrawerKey.CHANGE_BALANCE_VIEW)
    //   //   this.$store.commit(MUTATIONS_DRAWER.SET, {key: this.column.drawer, transaction: this.row})
    // }

    this.$emit('click-column', {key: this.column.code, payload: this.row})
    this.$root?.$emit('click-column', {key: this.column.code, payload: this.row})
    this.$root?.$root?.$emit('click-column', {key: this.column.code, payload: this.row})

  }
}
