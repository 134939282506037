import {AppTable} from "@/share/AppTable.vue";
import {Product} from "@/api/models/product";

export const productTable: AppTable<Product> = {
    title: 'Список товаров',
    columns: [
        {
            code: 'number',
            type: 'plain',
            title: '#',
            width: '100px',
            render: (v, r) => `<span class="${(Number(r.baseCount) < Number(r.limitWarning)) ? 'warning' : ''}">${v ?? ''}</span>`,
        },
        {
            code: 'title',
            type: 'plain',
            title: 'title',
            render: (v, r) => `<span class="${(Number(r.baseCount) < Number(r.limitWarning)) ? 'warning' : ''}">${v ?? ''}</span>`,
        },
        {
            code: 'provider',
            type: 'plain',
            title: 'provider',
            render: (v, r) => `<span class="${(Number(r.baseCount) < Number(r.limitWarning)) ? 'warning' : ''}">${r.provider?.title ?? ''}</span>`,
        },
        {
            code: 'count',
            type: 'plain',
            title: 'Count',
            width: '100px',
            render: (v, r) => `<span class="${(Number(r.count) < Number(r.limitWarning)) ? 'warning' : ''}">${v ?? '0'}</span>`,
        },
        {
            code: 'limitWarning',
            type: 'plain',
            title: 'Limit Warning',
            width: '150px',
            render: (v, r) => `<span class="${(Number(r.baseCount) < Number(r.limitWarning)) ? 'warning' : ''}">${v ?? ''}</span>`,
        },
    ]
};
