import {Module} from "vuex/types";
import {RootState} from "@/store/index";
import {MetaPage} from "@/Models/MetaPage";
import {Product} from '@/api/models/product'
import {api} from "@/api/api";

type T = Product;

interface State {
    isPending: boolean,
    items: T[],
    meta?: MetaPage,
    params: { [x: string]: any }
}

export enum MUTATIONS_PRODUCT {
    INIT = 'MUTATIONS_PRODUCT/INIT',
    SET = 'MUTATIONS_PRODUCT/SET',
    ADD = 'MUTATIONS_PRODUCT/ADD',
    UPDATE_PENDING = 'MUTATIONS_PRODUCT/UPDATE_PENDING',
}

export enum ACTIONS_PRODUCT {
    INIT = 'ACTIONS_PRODUCT/INIT',
    LOAD = 'ACTIONS_PRODUCT/LOAD',
    RELOAD = 'ACTIONS_PRODUCT/RELOAD',
    CREATE = 'ACTIONS_PRODUCT/CREATE',
    UPDATE = 'ACTIONS_PRODUCT/UPDATE',
    DELETE = 'ACTIONS_PRODUCT/DELETE',
}

export const ProductModule: Module<State, RootState> = {
    // namespaced: true,
    state: {
        isPending: false,
        items: [],
        meta: undefined,
        params: {}
    },
    mutations: {
        [MUTATIONS_PRODUCT.INIT](state) {
            state.items = [];
            state.meta = undefined;
            state.isPending = false;
            state.params = {};
        },
        [MUTATIONS_PRODUCT.SET](state, {
            payload,
            meta,
            isPending,
            params,
        }: { payload: T[], meta: MetaPage, isPending: boolean, params?: {[x: string]: any} }) {
            state.items = payload;
            state.meta = meta;
            state.isPending = isPending;
            state.params = params ?? {};
        },
        [MUTATIONS_PRODUCT.ADD](state, item: T) {
            if (!state.items.find(e => e.id == item.id)) {
                state.items.push(item)
            }
        },
        [MUTATIONS_PRODUCT.UPDATE_PENDING](state, flag: boolean) {
            state.isPending = flag;
        },
    },
    actions: {
        async [ACTIONS_PRODUCT.INIT]({commit}) {
            commit(MUTATIONS_PRODUCT.INIT)
        },
        async [ACTIONS_PRODUCT.LOAD]({commit, state}, params?: { page: number, search?: string }) {
            console.log(`ACTIONS_PRODUCT.LOAD`, params)
            commit(MUTATIONS_PRODUCT.UPDATE_PENDING, true)
            const res = await api.getProducts(params ?? {page: 1});
            commit(MUTATIONS_PRODUCT.SET, {...res, isPending: false, params: params})
        },
        async [ACTIONS_PRODUCT.RELOAD]({commit, state}) {
            console.log(`ACTIONS_PRODUCT.RELOAD`)
            commit(MUTATIONS_PRODUCT.UPDATE_PENDING, true)
            const res = await api.getProducts(state.params as any);
            commit(MUTATIONS_PRODUCT.SET, {...res, isPending: false, params: state.params})
        },
        async [ACTIONS_PRODUCT.CREATE]({commit, state, dispatch}, params: Product) {
            console.log(`ACTIONS_PRODUCT.CREATE`, params)
            commit(MUTATIONS_PRODUCT.UPDATE_PENDING, true)
            const res = await api.createProduct({payload: params});
            await dispatch(ACTIONS_PRODUCT.RELOAD);
            return res;
        },
        async [ACTIONS_PRODUCT.UPDATE]({commit, state, dispatch}, params: Product) {
            console.log(`ACTIONS_PRODUCT.UPDATE`, params)
            commit(MUTATIONS_PRODUCT.UPDATE_PENDING, true)
            const res = await api.updateProduct({payload: params, id: params.id});
            await dispatch(ACTIONS_PRODUCT.RELOAD);
            return res;
        },
        async [ACTIONS_PRODUCT.DELETE]({commit, state, dispatch}, id: number ) {
            console.log(`ACTIONS_PRODUCT.DELETE`, id)
            commit(MUTATIONS_PRODUCT.UPDATE_PENDING, true)
            const res = await api.deleteProduct({id: id});
            await dispatch(ACTIONS_PRODUCT.RELOAD);
            return res;
        },
    },
    getters: {
        productModuleItems: (state): T[] => state.items ?? [],
        productModuleMeta: (state): MetaPage | undefined => state.meta,
        productModuleIsPending: (state): boolean | undefined => state.isPending,
    }
}
