import {camelCase, snakeCase} from "lodash";
import {obj} from "@/share/types";

export function toCameCaseNameProps(obj: any) {
    if (!obj) return obj;
    if (typeof obj !== "object") return obj;
    if (obj?.length !== undefined) return (obj ?? []).map((e: any) => toCameCaseNameProps(e)) ?? [];

    let _resData: obj = {};
    for (const key of Object.keys(obj)) {
        _resData[camelCase(key)] = toCameCaseNameProps(obj[key]);
    }
    return _resData;
}

export function toSnakeCaseNameProps(obj: any) {
    if (!obj) return obj;
    if (typeof obj !== "object") return obj;
    if (obj?.length !== undefined) return (obj ?? []).map((e: any) => toSnakeCaseNameProps(e)) ?? [];

    let _resData: obj = {};
    for (const key of Object.keys(obj)) {
        _resData[snakeCase(key)] = toSnakeCaseNameProps(obj[key]);
    }
    return _resData;
}
