
import {Options, Vue} from "vue-class-component";
import AppTable, {AppTableColumn} from "../AppTable.vue";

@Options({
  components: {AppTable},
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {type: Array, default: () => []},
    columns: {type: Array, default: () => []},
    store: {type: String, default: ''},
    edited: {type: Boolean, default: false,},
    deleted: {type: Boolean, default: false,},
    closed: {type: Boolean, default: false,},
    loading: {type: Boolean, default: false,},
    defaultFilter: {type: Object, default: () => ({})},
    keyFilter: {type: String, default: ''},
    params: {type: Object, default: () => ({})},
  },
  emit: ['click-column', 'click-row', 'edit', 'delete']
})
export default class LayoutTable extends Vue {
  title!: string;
  keyFilter!: string;

  items!: any[]
  columns!: AppTableColumn[]
  store!: string
  edited!: boolean
  deleted!: boolean
  closed!: boolean
  loading!: boolean
  defaultFilter!: { [x: string]: string }
  params!: { [x: string]: string }

}
