export function titleField(item?: {title?: string, number?: string} | string) {
    if (!item) return '';

    if (typeof item == 'string') {
        return item;
    }

    if (item.title && item.number) {
        return `${item.title} (${item.number})`
    }

    return item.title ?? item.number;
}

export function getOptionMap(item: {title?: string, id: number, number?: string}) {
    let label = item.title ?? ''
    if (item.number) label += ` (${item.number})`

    return ({
        value: item.title,
        label: label,
        item: item,
    })
}

export function getOptionMapTitle(item: {title?: string, id: number, number?: string}) {
    if (!item) return '';
    let label = item.title ?? ''
    if (label.length > 100) {
        label = label.substr(0, 100) + '...';
    }
    if (item.number) label += ` (${item.number})`

    return ({
        value: label,
        item: ({...item, title: label}),
    })
}

export function getTitleWithNumber(item: {title?: string, id: number, number?: string}) {
    if (!item) return '';
    let label = item.title ?? item
    if (item.number) label += ` (${item.number})`

    return label;
}
