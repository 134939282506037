import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a024c4ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "SelectRow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString(_ctx.locale('orderItem.form.stack', 'Stack')), 1),
    _createElementVNode("span", null, _toDisplayString(_ctx.locale('orderItem.form.board', 'Board')), 1),
    _createVNode(_component_el_select, {
      modelValue: _ctx.valueStack,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valueStack) = $event)),
      filterable: "",
      remote: "",
      "reserve-keyword": "",
      placeholder: _ctx.locale('orderItem.form.stack', 'Stack'),
      "remote-method": _ctx.remoteMethodStack,
      clearable: "",
      "no-data-text": "Not found here",
      onClear: _ctx.handleClearBoard
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsStacks, (item) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: item.value,
            label: item.label,
            value: item.value
          }, null, 8, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "placeholder", "remote-method", "onClear"]),
    _createVNode(_component_el_select, {
      modelValue: _ctx.valueBoard,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.valueBoard) = $event)),
      filterable: "",
      remote: "",
      "reserve-keyword": "",
      placeholder: _ctx.locale('orderItem.form.board', 'Board'),
      "remote-method": _ctx.remoteMethodBoard,
      clearable: "",
      "no-data-text": "Not found here"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsBoards, (item) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: item.value,
            label: item.label,
            value: item.value
          }, null, 8, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "placeholder", "remote-method"])
  ]))
}