export interface Locale {
    id: number
    key: string
    valueRu: string
    valueEn: string
    valueDe: string

}

export function getEmptyLocale(): Locale {
    return {
        id: 0,
        key: "",
        valueRu: "",
        valueEn: "",
        valueDe: "",
    };
}
