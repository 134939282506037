
import {Options, Vue} from "vue-class-component";
import AppIcons from "../../share/AppIcons.vue";
import LayoutTable from "../../share/Layout/LayoutTable.vue";
import AppForm from "@/share/AppForm.vue";
import {doYouClose} from "@/share/utils/doYouClose";
import {ElMessage, ElMessageBox} from "element-plus";
import {FormAction} from "@/share/forms/forms_definitions";
import store from "@/store";
import {ApiResponse} from "@/api/utils";
import {ACTIONS_STORAGE} from "@/store/StorageModule";
import {locale, localeError} from "@/share/locale";
import LayoutHeader from "@/share/Layout/LayoutHeader.vue";
import AppTable from "@/share/AppTable.vue";
import {ACTIONS_STORAGE_ROW_SINGLE} from "@/store/StorageRowSingleModule";
import SelectRow from "@/views/StorageView/SelectRow.vue";
import {StorageRow} from "@/api/models/storage_row";
import {storageCellTable} from "@/table/storage_cell_columns";
import {getEmptyStorageCell, StorageCell} from "@/api/models/storage_cell";
import {storageCellFields} from "@/form/storage_cell_form";
import {api} from "@/api/api";
import {ACTIONS_GROUP_PRODUCT} from "@/store/GroupProductModule";
import {routesKeys} from "@/router";

type T = StorageRow;

@Options({
  components: {
    SelectRow,
    AppTable,
    LayoutHeader,
    AppForm,
    AppIcons,
    LayoutTable,
  },
})
export default class StorageRowView extends Vue {

  async created() {
    await store.dispatch(ACTIONS_STORAGE_ROW_SINGLE.LOAD, this.$route.params)
  }

  form = storageCellFields;

  tableRows = storageCellTable;

  stackId: number = 0;
  boardId: number = 0;

  openModal = false;
  selectItem: StorageCell = getEmptyStorageCell();

  references = {
    'storageStackId': (values: StorageCell) => {
      return {
        'storageBoardId': ''
      }
    }
  }

  get cells() {
    let items = this.item?.storageCell ?? [];
    if (this.stackId) {
      items = items.filter(e => e.storageStackId == this.stackId)
    }
    if (this.boardId) {
      items = items.filter(e => e.storageBoardId == this.boardId)
    }

    return items;
  }

  get item(): T {
    return store.getters.storageRowSingleModuleByID(this.$route.params.id_row);
  }

  get storageId(): string {
    return this.$route.params.id as string;
  }

  get rowId(): string {
    return this.$route.params.id_row as string;
  }

  doYouClose = doYouClose;
  locale = locale;
  nameEntity = 'storage_cell'

  get loading(): boolean {
    return store.getters.storageRowSingleModuleIsPending;
  }

  async handleAction(item: FormAction<StorageCell>) {
    let res: ApiResponse<any>

    const payload: StorageCell = {
      ...item.values, storageSubCells: item.values.storageSubCells.map(e => ({
        ...e,
        storageId: Number(this.storageId),
        rowId: Number(this.rowId),
        stackId: item.values.storageStackId,
        boardId: item.values.storageBoardId,
      }))}

    if (item.values.id) {
      res = await api.updateCell({payload: payload})
    } else {
      res = await api.createCell({payload: payload})
    }
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
    }
    await store.dispatch(ACTIONS_STORAGE_ROW_SINGLE.LOAD, this.$route.params)
    this.openModal = false;
  }

  handleOpenCreate() {
    this.selectItem = getEmptyStorageCell();
    this.openModal = true
  }

  async handleDeleteCell(item: StorageCell) {
    await ElMessageBox.confirm(
        locale(`${this.nameEntity}.form.delete`, 'Delete', item.title),
        locale(`modal.title.warning`, 'Warning'),
        {
          confirmButtonText: locale(`action.delete`, 'Delete'),
          cancelButtonText: locale(`action.cancel`, 'Cancel'),
          type: 'warning',
        }
    )
    const res = await api.deleteCell({id: item.id})
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    }
    await store.dispatch(ACTIONS_STORAGE_ROW_SINGLE.LOAD, this.$route.params)
  }

  handleOpenCell(item: StorageCell) {
    this.selectItem = {...item, storageBoard: [], storageStack: [] };
    this.openModal = true
  }

  handleOpenStack() {
    this.$router.push({name: routesKeys.StorageStackView.name, params: this.$route.params})
  }
}
