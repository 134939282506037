import moment from "moment";

function dateFormatter(date: string) {
    return moment(date).format('DD.MM.YYYY')
}

export default dateFormatter;

export const DateFormatter = {
    beforeMount(el: Node, binding: any, vnode: any) {
        el.textContent = dateFormatter(el.textContent ?? '');
    }
}

export function getDateISO(date: Date | string) {
    if (typeof date == "string") return date;
    const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    return (new Date(date.getTime() - tzoffset)).toISOString();
}
