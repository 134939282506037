
import {Options, Vue} from "vue-class-component";
import {AppFormField, AppFormFieldType, AppFormFieldTypeView, AppOption} from "@/share/forms/form";
import {Watch} from "vue-property-decorator";
import AppFormTags from "@/share/AppFormTags.vue";
import {obj} from "@/share/types";
import {debounce} from "lodash";
import {locale, localePlaceholder} from "@/share/locale";

@Options({
  components: {AppFormTags},
  props: {
    nameEntity: {type: String, default: ''},
    fields: {type: Array, default: () => []},
    values: {type: Object, default: () => ({})},
    isSending: {type: Boolean, default: false},
    horizontal: {type: Boolean, default: false},
    list: {type: Boolean, default: false},
    parentValues: {type: Object, default: () => ({})},
    errors: {type: Object, default: () => ({})},
    references: {type: Object, default: () => ({})},
    layout: {type: String, default: "grid"},
  },
  emits: ['action', 'validate-form', 'change', 'change-form']
})
export default class AppForm extends Vue {
  fields!: AppFormField[];
  values!: obj
  parentValues!: obj
  isSending!: boolean
  horizontal!: boolean
  list!: boolean
  nameEntity!: string
  errors!: any[]
  layout!: string

  valuesLocal: { [x: string]: any } = {}
  emptyRow = {}

  autoCompleteValues: { [x: string]: string } = {}
  autoCompleteValuesChange: { [x: string]: boolean } = {}
  autoCompleteDebounce: { [x: string]: Function } = {}

  validateFormFields: { [x: string]: boolean[] } = {};

  options: { [x: string]: AppOption[] } = {}
  references: { [x: string]: (values: any) => { [x: string]: any } } = {}

  locale = locale;
  localePlaceholder = localePlaceholder;

  get rules() {
    const res: obj = {}

    for (const item of this.fields) {
      if (!this.errors[item.key]) {
        res[item.key as string] = this.fields.find(e => e.key == item.key)?.rules;
        if (typeof res[item.key as string]?.message == 'function') {
          res[item.key as string].message = res[item.key as string].message();
        }
      }
    }

    return res;
  }

  get _errors() {
    const res: obj = {}

    for (const item of this.fields) {
      if (this.errors[item.key]) {
        res[item.key as string] = this.errors[item.key];
      }
    }

    return res;
  }

  get styleGrid() {
    if (this.layout != 'grid') return ``

    let res: string[] = []

    const listCount = this.fields.map(e => e.params?.gridRow?.split(' ')?.length ?? 0).filter(e => e);
    const count = Math.max(...listCount, 1);

    console.log({count})

    for (let item of this.fields) {
      res.push(`"${item.params?.gridRow ?? (new Array(count).fill(0).map(e => item.key).join(' ')) as string}"`)
    }

    return `grid-template-areas: ${res.join(' ')}`;
  }

  @Watch('fields')
  handleWatch1() {

  }

  @Watch('valuesLocal')
  handleWatch4() {
    this.$emit('change', this.valuesLocal)
  }

  mounted() {
    (window as any).ruleForm = this.$refs['ruleForm']
    this.valuesLocal = JSON.parse(JSON.stringify(this.values))

    this.updateOptions()
  }

  @Watch('values')
  handleWatch2() {
    this.valuesLocal = JSON.parse(JSON.stringify(this.values))
    this.updateOptions()
  }

  getValidateAutoComplete(key: string, allowEmpty = false) {
    if (this.fields.find(e => e.key == key)?.validate) return false;
    if (allowEmpty && !this.valuesLocal[key]) return false;
    return (this.autoCompleteValuesChange[key] === false && this.values[key] != this.valuesLocal[key])
  }

  get validate() {
    // console.log(`validate`)
    if (!this.valuesLocal || !this.fields) return false;
    // console.log(`validate 1`)
    let flag = true;
    for (const field of this.fields) {
      const key = field.key as string;
      if (field.type == AppFormFieldType.button) continue;
      if (field.required || field.rules?.find(e => e.required)) {
        if (!this.checkConditionView(field)) continue;
        if (field.type == AppFormFieldType.select) {
          if (field.typeView == AppFormFieldTypeView.tags) {
            if (!this.valuesLocal[key]?.length) {
              flag = false;
              // console.log({...field})
              // console.log(`validate1: false`, this.valuesLocal[key])
            }
          } else {
            if (this.valuesLocal[key] === undefined || this.valuesLocal[key] === '') {
              flag = false;
              // console.log({...field})
              // console.log(`validate2-0: false`, this.valuesLocal[key])
            }

            if (field.typeView == AppFormFieldTypeView.autocomplete) {
              // console.log(`validate2-1: any`, this.valuesLocal[key])
              if (this.getValidateAutoComplete(key)) {
                // console.log(`validate2-2: false`, this.values[key], this.valuesLocal[key])
                flag = false;
              }
            }
          }
        } else if (field.type == AppFormFieldType.array) {
          if (this.validateFormFields[key]) {
            const res = this.validateFormFields[key].length ? this.validateFormFields[key].find(e => !e) ?? true : false;
            if (!res) {
              flag = false;
              // console.log({...field})
              // console.log(`validate4-1: false`, this.valuesLocal[key])
            }
          } else {
            flag = false;
            // console.log({...field})
            // console.log(`validate4-0: false`, this.valuesLocal[key])
          }
        } else if (field.type == AppFormFieldType.date && field.typeView == AppFormFieldTypeView.dateRange) {
          if (!(this.valuesLocal[key] && this.valuesLocal[key].length)) {
            flag = false;
            // console.log({...field})
            // console.log(`validate5: false`, this.valuesLocal[key])
          }
        } else if (field.type == AppFormFieldType.text && field.typeView == AppFormFieldTypeView.number) {
          if (!(Number(this.valuesLocal[key]))) {
            flag = false;
            // console.log({...field})
            // console.log(`validate6: false`, this.valuesLocal[key])
          }

        } else if (!this.valuesLocal[key]) {
          flag = false;
          // console.log({...field})
          // console.log(`validate7: false`, this.valuesLocal[key])
        }
      }
      if (field?.rules?.length) {
        for (let item of field.rules) {
          if (item.min) {
            if (this.valuesLocal[key]?.length < item.min)
              flag = false;
          }
          if (item.max) {
            if (this.valuesLocal[key]?.length > item.max)
              flag = false;
          }
        }
      }

      if (field.validate) {
        console.log({key, flag, val: this.valuesLocal[key]})
        if (flag) {
          flag = field.validate(this.valuesLocal[key], this.values[key])
        }
      }
    }

    // // this.$refs['ruleForm'].
    //
    // if (document.querySelectorAll(".el-form-item__error").length) return false;

    return flag;
  }

  @Watch('validate')
  handleWatch3() {
    this.$emit('validate-form', this.validate);
  }

  onSubmit(key: string, values: obj) {
    this.$emit('action', {key, values: {...values}})
  }

  handleSelectTags(key: string, val: any) {
    console.log({key, val})
    this.valuesLocal[key] = val;
  }

  handleInputAutoComplete(item: AppFormField, text: string, cb: Function) {
    const key = item.key as string;
    console.log(`handleInputAutoComplete ${key} ${text}`)
    this.valuesLocal[key] = text;
    this.autoCompleteValues[key] = text
    this.autoCompleteValuesChange[key] = false

    if (!this.autoCompleteDebounce[key]) {
      this.autoCompleteDebounce[key] = debounce(item.actionGet, 300);
    }

    this.autoCompleteDebounce[key](text, cb, this.valuesLocal);
  }

  handleSelectAutoComplete(key: string, val: any) {
    console.log({key, val: val.item})
    this.valuesLocal[key] = val.item.name ?? val.item.title ?? val.item.id;
    this.valuesLocal[`${key}_id`] = val.item?.id;
    this.valuesLocal[`${key}Id`] = val.item?.id;
    this.autoCompleteValues[key] = val.item.name ?? val.item.title ?? val.item.id
    this.autoCompleteValuesChange[key] = true

    this.$emit('change-form', {...this.valuesLocal});

    const field = this.fields.find(e => e.key == key);
    if (field)
    this.updateReferences(field);
  }

  handleSelectTree(key: string, val: any) {
    console.log({key, val: val})

    const field = this.fields.find(e => e.key == key);

    if (field?.mapValues?.from) {

      const keys = val.checkedKeys;

      this.valuesLocal = {...this.valuesLocal, ...field.mapValues.from(keys, this.valuesLocal)};

      this.$emit('change-form', {...this.valuesLocal});
    }

    // this.valuesLocal[key] = val.item;
    // this.autoCompleteValues[key] = val.item.name
    // this.autoCompleteValuesChange[key] = true
    //
    // this.$emit('change-form', {...this.valuesLocal});
  }

  handleUpdateValues() {
    this.$emit('change-form', {...this.valuesLocal});
  }

  handleRemoveRow(item: AppFormField, index: number) {
    this.valuesLocal[item.key as string].splice(index, 1);
    this.validateFormFields[item.key as string].splice(index, 1)
  }

  handleAddRow(item: AppFormField, values: any) {
    this.valuesLocal[item.key as string].push(values.values)
    this.emptyRow = {}
  }

  checkConditionView(item: AppFormField) {
    if (item.conditionVisible) {
      const obj = Object.keys(this.parentValues).length ? this.parentValues : this.valuesLocal;
      return item.conditionVisible(obj);
    }
    return true;
  }

  checkView(item: AppFormField, type: AppFormFieldTypeView | AppFormFieldTypeView[]) {
    // console.log(`checkView`, item.key, type)
    if (type == AppFormFieldTypeView.date) {
      // console.log(`checkView`, this.checkConditionView(item), item.typeView == type || type.includes(item.typeView))

      return false;
    }
    if (!this.checkConditionView(item)) return false;

    return item.typeView == type || type.includes(item.typeView)
  }

  updateValidateForm(field: AppFormField, index: number, value: boolean) {
    console.log(`updateValidateForm`, {field, index, value})
    const key = field.key as string;
    if (!this.validateFormFields[key]) {
      this.validateFormFields[key] = []
    }
    this.validateFormFields[key][index] = value;
  }

  onEnter(e: any) {
    return false;
  }

  async updateOptions() {
    for (let item of this.fields) {
      if (typeof item.options === 'object')
        this.options[item.key as string] = item.options as AppOption[];
      if (typeof item.options === 'function')
        this.options[item.key as string] = await item.options(this.valuesLocal) as AppOption[];
    }
  }

  updateReferences(item: AppFormField) {
    if (this.references[item.key as any]) {
      const res: { [x: string]: any } = this.references[item.key as any](this.valuesLocal)
      this.valuesLocal = {...this.valuesLocal, ...res};
    }
    this.updateOptions()
  }


  AppFormFieldType = AppFormFieldType;
  AppFormFieldTypeView = AppFormFieldTypeView;
}
