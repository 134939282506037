import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f30fdedc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "PageAuth" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_input, {
      modelValue: _ctx.email,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
      class: "email",
      placeholder: "Email"
    }, null, 8, ["modelValue"]),
    _createVNode(_component_el_input, {
      modelValue: _ctx.password,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
      type: "password",
      class: "password",
      placeholder: "Password"
    }, null, 8, ["modelValue"]),
    _createVNode(_component_el_button, {
      class: "login",
      onClick: _ctx.auth,
      disabled: !_ctx.email || !_ctx.password
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.locale('formAuth.login', 'Login')), 1)
      ]),
      _: 1
    }, 8, ["onClick", "disabled"])
  ]))
}