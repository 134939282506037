
import {Options, Vue} from "vue-class-component";
import {logTable} from "@/table/log_columns";
import {locale} from "@/share/locale";
import store from "@/store";
import {ACTIONS_LOG} from "@/store/LogModule";
import AppForm from "@/share/AppForm.vue";
import AppIcons from "@/share/AppIcons.vue";
import LayoutTable from "@/share/Layout/LayoutTable.vue";
import {Log} from "@/api/models/log";

type T = Log;

@Options({
  components: {
    AppForm,
    AppIcons,
    LayoutTable,
  },
})
export default class LogList extends Vue {
  columnsTable = logTable;

  locale = locale;

  nameEntity = 'Log'
  openModal = false;
  selectItem?: T
  diff = []

  openItem(item) {
    this.selectItem = item;
    this.diff = JSON.parse(item.diff ?? '[]')
    console.log(this.diff)
    this.openModal = true;
  }

  mounted() {
    store.dispatch(ACTIONS_LOG.LOAD)
  }
}
