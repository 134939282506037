
import {Options, Vue} from "vue-class-component";
import {api} from "@/api/api";
import {ElMessage} from "element-plus";
import {locale} from "@/share/locale";

@Options({})
export default class PageAuth extends Vue {
  email = '';
  password = ''

  loading = false

  locale = locale

  async auth() {
    this.loading = true
    const res = await api.auth(this.email, this.password)
    if (res.error) {
      ElMessage({message: res.error.message, type: 'error'});
    } else {
      localStorage.token = res.payload.token
      localStorage.roles = JSON.stringify(res.payload.roles)
      localStorage.user = JSON.stringify(res.payload.user)
      window.location.href = '/'
    }
    this.loading = false
  }
}
