import {ElMessageBox} from "element-plus";
import {locale} from "@/share/locale";

export async function doYouClose(done: Function) {
    try {
        await ElMessageBox.confirm(
            locale(`modal.text.continue_save`, 'Continue'),
            locale(`modal.title.continue_save`, 'Continue'),
            {
                confirmButtonText:  locale(`modal.action.leave`, 'Leave'),
                confirmButtonClass: 'msgbox-leave-btn',
                cancelButtonText: locale(`action.cancel`, 'Cancel'),
                cancelButtonClass: 'msgbox-cancel-btn',
                type: 'warning',
            }
        )
        done();
    } catch (e) {
    }
}
