import {AppTable} from "@/share/AppTable.vue";
import {Delivery} from "@/api/models/delivery";
import dateFormat from "@/share/utils/dateFormat";

export const deliveryTable: AppTable<Delivery> = {
    title: 'Поставки',
    columns: [
        {
            title: 'ID',
            type: 'plain',
            code: 'id',
            width: '100px',
        },
        {
            title: 'Дата поставки',
            type: 'plain',
            code: 'dateSend',
            render: (t) => dateFormat(t),
        },
        {
            title: 'Ответсвенный',
            type: 'plain',
            code: 'responsible',
        }
    ]
};
