import {toCameCaseNameProps} from "@/share/word";

export class ApiResponse<T> {
    payload: any;
    meta: MetaPage | undefined;
    error: any

    constructor(values?: {data: any, meta?: MetaPage} & LaravelDefaultPaginate, error?: any) {
        this.payload = toCameCaseNameProps((values?.data || values) ?? {});
        this.meta = values?.meta ?? {
            page: values?.current_page ?? 1,
            total: values?.meta?.total ?? values?.total ?? this.payload,
            perPage: values?.meta?.perPage ?? values?.per_page ?? this.payload,
        };
        this.error = error;
    }
}

interface MetaPage {
    page: number,
    total: number,
    perPage: number
}

interface LaravelDefaultPaginate {
    current_page?: number,
    last_page?: number,
    next_page?: number,
    per_page?: number,
    total?: number,
}

function apiClient() {
    return fetch
}
