import {Module} from "vuex/types";
import {RootState} from "@/store/index";

import {GroupProduct} from '@/api/models/group_product.ts'
import {api} from "@/api/api";
import {Storage} from "@/api/models/storage";
import {StorageRow} from "@/api/models/storage_row";
import {StorageCell} from "@/api/models/storage_cell";
import {last} from "lodash";

type T = StorageRow;

interface State {
    isPending: boolean,
    items: T[],
    lastId: number,
}

export enum MUTATIONS_STORAGE_ROW_SINGLE {
    INIT = 'MUTATIONS_STORAGE_ROW_SINGLE/INIT',
    SET = 'MUTATIONS_STORAGE_ROW_SINGLE/SET',
    UPDATE_PENDING = 'MUTATIONS_STORAGE_ROW_SINGLE/UPDATE_PENDING',
}

export enum ACTIONS_STORAGE_ROW_SINGLE {
    INIT = 'ACTIONS_STORAGE_ROW_SINGLE/INIT',
    LOAD = 'ACTIONS_STORAGE_ROW_SINGLE/LOAD',
}

export const StorageRowSingleModule: Module<State, RootState> = {
    // namespaced: true,
    state: {
        isPending: false,
        items: [],
        lastId: 0,
    },
    mutations: {
        [MUTATIONS_STORAGE_ROW_SINGLE.INIT](state) {
            state.items = [];
            state.isPending = false;
        },
        [MUTATIONS_STORAGE_ROW_SINGLE.SET](state, {
            items,
            lastId,
            isPending
        }: { items: T[], isPending: boolean, lastId: number}) {
            state.items = items;
            state.isPending = isPending;
            state.lastId = lastId;
        },
        [MUTATIONS_STORAGE_ROW_SINGLE.UPDATE_PENDING](state, flag: boolean) {
            state.isPending = flag;
        },
    },
    actions: {
        async [ACTIONS_STORAGE_ROW_SINGLE.INIT]({commit}) {
            commit(MUTATIONS_STORAGE_ROW_SINGLE.INIT)
        },
        async [ACTIONS_STORAGE_ROW_SINGLE.LOAD]({commit, state, dispatch}, params: {id: number, id_row: number}) {
            console.log('ACTIONS_STORAGE_ROW_SINGLE.LOAD')
            commit(MUTATIONS_STORAGE_ROW_SINGLE.UPDATE_PENDING, true)
            const res = await api.getStorageRow(params);
            const item: StorageRow = {
                ...res.payload,
                storageCell: res.payload.storageCell.map((e: StorageCell) => ({...e, storageBoard: res.payload.storageBoard, storageStack: res.payload.storageStack}))
            }
            const items = [item, ...state.items.filter(e => e.id != params.id)]

            commit(MUTATIONS_STORAGE_ROW_SINGLE.SET, {isPending: false, items, lastId: params.id_row})
        },
    },
    getters: {
        storageRowSingleModuleItems: (state): T[] => state.items ?? [],
        storageRowSingleModuleByID: (state) => (id: string | number): T | undefined => state.items?.find((e: T) => e.id == id),
        storageRowSingleModuleLast: (state) => state.items?.find((e: T) => e.id == state.lastId),
        storageRowSingleModuleIsPending: (state): boolean | undefined => state.isPending,
    }
}
