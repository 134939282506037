import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!

  return (_openBlock(), _createBlock(_component_el_breadcrumb, { separator: "/" }, {
    default: _withCtx(() => [
      (_ctx.deliveryList)
        ? (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
            key: 0,
            to: { name: _ctx.routesKeys.Delivery.name }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.locale(`delivery.table`, 'Delivery list')), 1)
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true),
      (_ctx.orderList)
        ? (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
            key: 1,
            to: { name: _ctx.routesKeys.Order.name }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.locale(`order.table`, 'Order list')), 1)
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true),
      (_ctx.storageList)
        ? (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
            key: 2,
            to: { name: _ctx.routesKeys.Storages.name }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.locale(`storage.table`, 'Storage list')), 1)
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true),
      (_ctx.storage)
        ? (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
            key: 3,
            to: { name: _ctx.routesKeys.StorageView.name, params: _ctx.params }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.storage.title), 1)
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true),
      _createVNode(_component_el_breadcrumb_item, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}