import {AppTable} from "@/share/AppTable.vue";
import {StorageCell} from "@/api/models/storage_cell.ts";

export const storageCellTable: AppTable<StorageCell> = {
    title: '',
    columns: [
        {
            title: 'ID',
            type: 'plain',
            code: 'id',
        },
        {
            title: 'Number cell',
            type: 'plain',
            code: 'title',
        },
        {
            title: 'Board',
            type: 'plain',
            code: 'storageBoardId',
            render: (val, row) => {
                return row.storageBoard.find(e => e.id === Number(val))?.title ?? '';
            }
        },
        {
            title: 'Stack',
            type: 'plain',
            code: 'storageStackId',
            render: (val, row) => {
                return row.storageStack.find(e => e.id === Number(val))?.title ?? '';
            }
        },
        {
            title: 'Sub cells',
            type: 'plain',
            code: 'storageSubCells',
            render: (val, row) => {
                return row.storageSubCells?.map(e => e.title)?.join(', ') ?? '';
            }
        },
    ]
};
