
import {Options, Vue} from "vue-class-component";
import AppIcons from "../../share/AppIcons.vue";
import LayoutTable from "../../share/Layout/LayoutTable.vue";
import AppForm from "@/share/AppForm.vue";
import {doYouClose} from "@/share/utils/doYouClose";
import {ElMessage, ElMessageBox} from "element-plus";
import {FormAction} from "@/share/forms/forms_definitions";
import store from "@/store";
import {ApiResponse} from "@/api/utils";
import {storageTable} from "@/table/storage_columns";
import {storageFields} from "@/form/storage_form";
import {getEmptyStorage, Storage} from "@/api/models/storage";
import {ACTIONS_STORAGE} from "@/store/StorageModule";
import {locale, localeError} from "@/share/locale";
import LayoutHeader from "@/share/Layout/LayoutHeader.vue";
import {ACTIONS_STORAGE_SINGLE} from "@/store/StorageSingleModule";
import AppTable from "@/share/AppTable.vue";
import {storageRowTable} from "@/table/storage_row_columns";
import {routesKeys} from "@/router";
import {getEmptyStorageRow, StorageRow} from "@/api/models/storage_row";
import {Watch} from "vue-property-decorator";
import {storageRowFields} from "@/form/storage_row_form";
import {api} from "@/api/api";
import {ACTIONS_STORAGE_ROW} from "@/store/StorageRowModule";

type T = Storage;

@Options({
  components: {
    AppTable,
    LayoutHeader,
    AppForm,
    AppIcons,
    LayoutTable,
  },
})
export default class StorageView extends Vue {

  items: StorageRow[] = []

  isLoaded = false;

  async created() {
    await store.dispatch(ACTIONS_STORAGE_SINGLE.LOAD, this.$route.params)
    this.items = (await api.getStorageRowByStorage({id: Number(this.$route.params.id)})).payload;

    const _this = this;
    setTimeout(() => _this.isLoaded = true, 1000);
  }

  form = storageFields;
  formRow = storageRowFields;

  tableRows = storageRowTable;

  openModal = false;
  selectItem = getEmptyStorageRow();

  get item() {
    return store.getters.storageSingleModuleItem;
  }

  values: Storage = getEmptyStorage();

  doYouClose = doYouClose;
  locale = locale;
  nameEntity = 'storage'

  @Watch('item')
  handleWatch1() {
    this.values = this.item;
  }

  get loading(): boolean {
    return store.getters.storageSingleModuleIsPending;
  }

  async handleAction() {
    let res: ApiResponse<any>
    if (this.values.id) {
      res = await store.dispatch(ACTIONS_STORAGE.UPDATE, this.values);
    } else {
      res = await store.dispatch(ACTIONS_STORAGE.CREATE, this.values);
    }
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
    }
    this.items = (await api.getStorageRowByStorage({id: this.id})).payload;
  }

  async handleDelete(item: Storage) {
    await ElMessageBox.confirm(
        locale(`${this.nameEntity}.form.delete`, 'Delete', item.title),
        locale(`modal.title.warning`, 'Warning'),
        {
          confirmButtonText: locale(`action.delete`, 'Delete'),
          cancelButtonText: locale(`action.cancel`, 'Cancel'),
          type: 'warning',
        }
    )
    const res: ApiResponse<any> = await store.dispatch(ACTIONS_STORAGE.DELETE, item.id);
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
      ElMessage({type: 'success', message: locale(`request.success`, 'Success'),})
    }
  }

  handleAddRow() {
    this.selectItem = getEmptyStorageRow(Number(this.$route.params.id))
    this.openModal = true;
  }

  handleOpenEditRow(item: StorageRow) {
    this.selectItem = item
    this.openModal = true;
  }

  async handleActionRow(item: FormAction<StorageRow>) {
    let res: ApiResponse<any>
    if (item.values.id) {
      res = await api.updateRow({payload: item.values, id: item.values.id})
    } else {
      res = await api.createRow({payload: item.values})
    }

    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
    }

    this.items = (await api.getStorageRowByStorage({id: this.id})).payload;
    this.openModal = false;
  }

  get id() {
    return Number(this.$route.params.id);
  }

  async handleDeleteRow(item: StorageRow) {
    await ElMessageBox.confirm(
        locale(`${this.nameEntity}.form.delete`, 'Delete', item.title),
        locale(`modal.title.warning`, 'Warning'),
        {
          confirmButtonText: locale(`action.delete`, 'Delete'),
          cancelButtonText: locale(`action.cancel`, 'Cancel'),
          type: 'warning',
        }
    )

    const res = await api.deleteRow({id: item.id})

    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
    }
    this.items = (await api.getStorageRowByStorage({id: this.id})).payload;
  }

  handleOpenRow(item: StorageRow) {
    this.$router.push({name: routesKeys.StorageRowView.name, params: {id: this.$route.params.id, id_row: item.id}})
  }

  handleChangeStorage(item: Storage) {
    console.log({item})
    if (!this.isLoaded) return;
    this.values = item;
  }

}
