export interface Sachkonto {
    id: number
    number: string
    title: string

}

export function getEmptySachkonto(): Sachkonto {
    return {
        id: 0,
        number: "",
        title: "",
    };
}
