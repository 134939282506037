import _ from "lodash";

export function toApiObj(obj: { [x: string]: any }): { [x: string]: any } {
    const keys = Object.keys(obj);

    const res: { [x: string]: any } = {}

    for (const key of keys) {
        res[_.camelCase(key)] = obj[key];
    }

    return res;
}

export function snackCaseDeep(obj: { [x: string]: any }): { [x: string]: any } | null {
    if (obj === null) return null;

    const keys = Object.keys(obj);

    const res: { [x: string]: any } = {}

    for (const key of keys) {
        if (typeof obj[key] == "object") {
            res[_.snakeCase(key)] = snackCaseDeep(obj[key]);
        } else {
            res[_.snakeCase(key)] = obj[key];
        }
    }

    return res;
}
