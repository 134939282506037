import {StorageBoard} from "@/api/models/storage_board";

export interface StorageStack {
    id: number
    title: string
    storageRowId: number
    storageBoards: StorageBoard[]

}

export function getEmptyStorageStack(storageRowId = 0): StorageStack {
    return {
        id: 0,
        title: "",
        storageRowId: storageRowId,
        storageBoards: [],
    };
}
