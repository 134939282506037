import {AppTable} from "@/share/AppTable.vue";
import {GroupProduct} from "@/api/models/group_product";

export const groupProductTable: AppTable<GroupProduct> = {
    title: 'Список групп товаров',
    columns: [
        {
            title: 'ID',
            type: 'plain',
            code: 'id',
            width: '100px'
        },
        {
            title: 'Заголовок',
            type: 'plain',
            code: 'title',
        }
    ]
};
