import {AppTable} from "@/share/AppTable.vue";
import {User} from '@/api/models/user'

export const userTable: AppTable<User> = {
    title: 'Список пользователей',
    columns: [
        {
            title: 'Title',
            type: 'plain',
            code: 'title',
        },
        {
            title: 'Postfix',
            type: 'plain',
            code: 'postfix',
        },
        {
            title: 'Email',
            type: 'plain',
            code: 'email',
        },
        {
            title: 'Телефон',
            type: 'plain',
            code: 'phone',
        },
        {
            title: 'Комментарий',
            type: 'plain',
            code: 'comment',
        },

    ]
};
