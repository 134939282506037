import {obj} from "@/share/types";

export enum AppFormFieldType {
    plain = 'plain',
    text = 'text',
    array = 'array',
    select = 'select',
    date = 'date',
    button = 'button',
}

export enum AppFormFieldTypeView {
    plain = 'plain',
    plainColumn = 'plainColumn',
    number = 'number',
    text = 'text',
    textarea = 'textarea',
    date = 'date',
    dateSingle = 'dateSingle',
    dateRange = 'dateRange',
    list = 'list',
    tree = 'tree',
    autocomplete = 'autocomplete',
    autocomplete_2 = 'autocomplete_2',
    tags = 'tags',
    button = 'button',
    switch = 'switch',
    form = 'form',
}

export interface AppOption {
    value: string | number | boolean,
    label: string | Function
}

export interface AppTreeOption {
    id: number,
    label: string,
    children: AppTreeOption[],
}

export interface AppFormField<T = any> {
    label?: string
    placeholder?: string
    type: AppFormFieldType,
    typeView: AppFormFieldTypeView,
    required?: boolean
    key: keyof T | string
    options?: AppOption[] | AppTreeOption[] | Function | Promise<any>,
    render?: (text: string, values: T) => void
    renderValue?: (text: string, values: T) => void
    form?: AppFormField<T>[]
    actionGet?: any,
    class?: string,
    style?: string,
    conditionVisible?: (values: T) => void
    width?: string
    rules?: { [x: string]: any }[]
    params?: {
        max?: number,
        min?: number,
        maxlength?: number,
        minlength?: number,
        mask?: string,
        grid?: string,
        gridRow?: string,
    },
    placeholderError?: string,
    mapValues?: {
        to: (value: T) => void,
        from: (value: any, row: T) => T,
    },
    disabled?: boolean,
    validate?: Function,
    id?: string,
    wrapDropdown?: string,
}

export interface IAppForm<T = any> {
    name: string
    items: AppFormField<T>[]
}

export interface FormAction<T extends obj> {
    key: string,
    values: T
}

export default IAppForm;

