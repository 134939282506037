import {AppTable} from "@/share/AppTable.vue";
import {Mvz} from "@/api/models/mvz";

export const mvzTable: AppTable<Mvz> = {
    title: '',
    columns: [
        {
            title: 'ID',
            type: 'plain',
            code: 'id',
            width: '100px',
        },
        {
            title: 'Заголовок',
            type: 'plain',
            code: 'number',
        },
        {
            title: '',
            type: 'plain',
            code: 'title',
        }
    ]
};
