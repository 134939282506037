export interface GroupProduct {
    id: number
    title: string
}

export function getEmptyGroupProduct() {
    return {
        id: 0,
        title: "",
    };
}

export default GroupProduct;
