
import {Options, Vue} from "vue-class-component";
import AppIcons from "../share/AppIcons.vue";
import LayoutTable from "../share/Layout/LayoutTable.vue";
import AppForm from "@/share/AppForm.vue";
import {doYouClose} from "@/share/utils/doYouClose";
import {ElMessage, ElMessageBox} from "element-plus";
import {FormAction} from "@/share/forms/forms_definitions";
import store from "@/store";
import {ApiResponse} from "@/api/utils";
import {locale, localeError} from "@/share/locale";
import {getEmptyUser, User} from "@/api/models/user";
import {userTable} from "@/table/user_columns";
import {userFields, userFieldsCreate} from "@/form/user_form";
import {ACTIONS_USER} from "@/store/UserModule";
import ChangePassword from "@/components/ChangePassword.vue";

type T = User;

@Options({
  components: {
    ChangePassword,
    AppForm,
    AppIcons,
    LayoutTable,
  },
})
export default class UserList extends Vue {

  async created() {
  }

  columnsTable = userTable;

  form = userFields;
  formCreate = userFieldsCreate;

  isOpenPassword = false;

  selectItem?: T

  openModal = false;

  doYouClose = doYouClose;
  locale = locale;
  nameEntity = 'user'

  openItem(item) {
    this.selectItem = item;
    this.openModal = true;
  }

  get loading(): boolean {
    return store.getters.sachkontoModuleIsPending;
  }

  handleClosePassword() {
    this.isOpenPassword = false;
    // this.openModal = false;
  }

  async handleAction(item: FormAction<T>) {
    if (item.key == 'action_password') {
      this.isOpenPassword = true;

      return
    }

    let res: ApiResponse<any>
    const payload: T = {...item.values}
    if (item.values.id) {
      res = await store.dispatch(ACTIONS_USER.UPDATE, payload);
    } else {
      res = await store.dispatch(ACTIONS_USER.CREATE, payload);
    }
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
      ElMessage({type: 'success', message: locale(`request.success`, 'Success'),})
      this.openModal = false;
    }
  }

  handleOpenCreate() {
    this.selectItem = getEmptyUser();
    this.openModal = true;
  }

  async handleDelete(item: T) {
    await ElMessageBox.confirm(
        locale(`${this.nameEntity}.form.delete`, 'Delete', item.title),
        locale(`modal.title.warning`, 'Warning'),
        {
          confirmButtonText: locale(`action.delete`, 'Delete'),
          cancelButtonText: locale(`action.cancel`, 'Cancel'),
          type: 'warning',
        }
    )
    const res: ApiResponse<any> = await store.dispatch(ACTIONS_USER.DELETE, item.id);
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
      ElMessage({type: 'success', message: locale(`request.success`, 'Success'),})
    }
  }
}
