import {AppTable} from "@/share/AppTable.vue";
import {InnerOrder} from "@/api/models/inner_order";

export const innerOrderTable: AppTable<InnerOrder> = {
    title: 'Внутренний заказ',
    columns: [
        {
            title: 'Номер',
            type: 'plain',
            code: 'number',
            width: '100px',
        },
        {
            title: 'Наименование',
            type: 'plain',
            code: 'title',
        }
    ]
};
