import {AppFormField, AppFormFieldType, AppFormFieldTypeView} from "@/share/forms/forms_definitions";
import {Locale} from "@/api/models/locale";
import {FieldBtnSave} from "@/share/forms/form";
import {fieldRequired} from "@/share/fieldRequired";

export const localeFields: AppFormField<Locale>[] = [
    {
        label: 'Полный ключ до элемента',
        key: 'key',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [ {required: true, message: fieldRequired, trigger: 'blur'} ],
    },
    {
        label: 'Russian',
        key: 'valueRu',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [ {required: true, message: fieldRequired, trigger: 'blur'} ],
    },
    {
        label: 'English',
        key: 'valueEn',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [ {required: true, message: fieldRequired, trigger: 'blur'} ],
    },
    {
        label: 'Germany',
        key: 'valueDe',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [ {required: true, message: fieldRequired, trigger: 'blur'} ],
    },
    {...FieldBtnSave, width: '350px', key: 'action'},
];
