import {StorageBoard} from "@/api/models/storage_board";
import {StorageStack} from "@/api/models/storage_stack";

export interface StorageSubCell {
    id: number
    title: string
    storageId: number | string
    rowId: number | string
    stackId: number | string
    boardId: number | string
    cellId: number | string

}

export function getEmptyStorageSubCell(): StorageSubCell {
    return {
        id: 0,
        title: "",
        storageId: '',
        rowId: '',
        stackId: '',
        boardId: '',
        cellId: '',
    };
}
