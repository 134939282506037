import {Module} from "vuex/types";
import {RootState} from "@/store/index";
import {MetaPage} from "@/Models/MetaPage";
import {Sachkonto} from '@/api/models/sachkonto.ts'
import {api} from "@/api/api";

type T = Sachkonto;

interface State {
    isPending: boolean,
    items: T[],
    meta?: MetaPage,
    params: { [x: string]: any }
}

export enum MUTATIONS_SACHKONTO {
    INIT = 'MUTATIONS_SACHKONTO/INIT',
    SET = 'MUTATIONS_SACHKONTO/SET',
    UPDATE_PENDING = 'MUTATIONS_SACHKONTO/UPDATE_PENDING',
}

export enum ACTIONS_SACHKONTO {
    INIT = 'ACTIONS_SACHKONTO/INIT',
    LOAD = 'ACTIONS_SACHKONTO/LOAD',
    RELOAD = 'ACTIONS_SACHKONTO/RELOAD',
    CREATE = 'ACTIONS_SACHKONTO/CREATE',
    UPDATE = 'ACTIONS_SACHKONTO/UPDATE',
    DELETE = 'ACTIONS_SACHKONTO/DELETE',
}

export const SachkontoModule: Module<State, RootState> = {
    // namespaced: true,
    state: {
        isPending: false,
        items: [],
        meta: undefined,
        params: {}
    },
    mutations: {
        [MUTATIONS_SACHKONTO.INIT](state) {
            state.items = [];
            state.meta = undefined;
            state.isPending = false;
            state.params = {};
        },
        [MUTATIONS_SACHKONTO.SET](state, {
            payload,
            meta,
            isPending,
            params,
        }: { payload: T[], meta: MetaPage, isPending: boolean, params?: {[x: string]: any} }) {
            state.items = payload;
            state.meta = meta;
            state.isPending = isPending;
            state.params = params ?? {};
        },
        [MUTATIONS_SACHKONTO.UPDATE_PENDING](state, flag: boolean) {
            state.isPending = flag;
        },
    },
    actions: {
        async [ACTIONS_SACHKONTO.INIT]({commit}) {
            commit(MUTATIONS_SACHKONTO.INIT)
        },
        async [ACTIONS_SACHKONTO.LOAD]({commit, state}, params?: { page: number }) {
            console.log('ACTIONS_SACHKONTO.LOAD', params)
            commit(MUTATIONS_SACHKONTO.UPDATE_PENDING, true)
            const res = await api.getSachkonto(params as any);
            commit(MUTATIONS_SACHKONTO.SET, {...res, isPending: false, params: params})
        },
        async [ACTIONS_SACHKONTO.RELOAD]({commit, state}) {
            console.log('ACTIONS_SACHKONTO.RELOAD')
            commit(MUTATIONS_SACHKONTO.UPDATE_PENDING, true)
            const res = await api.getSachkonto(state.params as any);
            commit(MUTATIONS_SACHKONTO.SET, {...res, isPending: false, params: state.params})
        },
        async [ACTIONS_SACHKONTO.CREATE]({commit, state, dispatch}, params: T) {
            console.log('ACTIONS_SACHKONTO.CREATE', params)
            commit(MUTATIONS_SACHKONTO.UPDATE_PENDING, true)
            const res = await api.createSachkonto({payload: params as any});
            await dispatch(ACTIONS_SACHKONTO.RELOAD);
            return res;
        },
        async [ACTIONS_SACHKONTO.UPDATE]({commit, state, dispatch}, params: T) {
            console.log('ACTIONS_SACHKONTO.UPDATE', params)
            commit(MUTATIONS_SACHKONTO.UPDATE_PENDING, true)
            const res = await api.updateSachkonto({payload: params, id: params.id});
            await dispatch(ACTIONS_SACHKONTO.RELOAD);
            return res;
        },
        async [ACTIONS_SACHKONTO.DELETE]({commit, state, dispatch}, id: number ) {
            console.log('ACTIONS_SACHKONTO.DELETE', id)
            commit(MUTATIONS_SACHKONTO.UPDATE_PENDING, true)
            const res = await api. deleteSachkonto({id: id});
            await dispatch(ACTIONS_SACHKONTO.RELOAD);
            return res;
        },
    },
    getters: {
        sachkontoModuleItems: (state): T[] => state.items ?? [],
        sachkontoModuleMeta: (state): MetaPage | undefined => state.meta,
        sachkontoModuleIsPending: (state): boolean | undefined => state.isPending,
    }
}
