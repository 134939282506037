export interface Provider {
    id: number
    number: number | null
    title: string
    email: string
    phone: string
    comment: string
    address: string
    responsible: string

}

export function getEmptyProvider() : Provider{
    return {
        id: 0,
        number: null,
        title: "",
        email: "",
        phone: "",
        comment: "",
        address: "",
        responsible: "",
    };
}
