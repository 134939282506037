
import {Options, Vue} from "vue-class-component";

import {routesKeys} from "@/router";
import store from "@/store";
import {locale} from "@/share/locale";

@Options({
  props: {
    title: {
      type: String,
      default: ''
    }
  }
})
export default class Breadcrumb extends Vue {
  title!: string;
  locale = locale;

  routesKeys = routesKeys;

  get params() {
    if (!this.$route) return {};
    return this.$route.params;
  }

  get name() {
    if (!this.$route) return {};
    return this.$route.name;
  }

  get storage() {
    if ([routesKeys.StorageStackView.name, routesKeys.StorageRowView.name].includes(this.$route.name as any) && store) {
      return store.getters.storageSingleModuleItem;
    }
    return false;
  }

  get dd() {
    return store.getters.storageSingleModuleItem
    // return routesKeys.StorageStackView.name == this.name
  }

  get storageList() {
    return !!([routesKeys.StorageView.name, routesKeys.StorageRowView.name, routesKeys.StorageStackView.name].includes(this.$route.name as any) && store);
  }

  get orderList() {
    return !!([routesKeys.OrderView.name].includes(this.$route.name as any) && store);
  }

  get deliveryList() {
    return !!([routesKeys.DeliveryView.name].includes(this.$route.name as any) && store);
  }

  get challenge() {
    return false;
    // return this.$store.getters.challengeSingleModuleByID(this.params.id)
  }

  get isUserPage() {
    return false;
    // return [routesKeys.Users.name, routesKeys.UserView.name].includes(this.$route.name as string)
  }

  get isChallengePage() {
    return false;
    // return [routesKeys.ChallengesPage.name, routesKeys.ChallengesViewPage.name, routesKeys.ChallengesViewMembersPage.name].includes(this.$route.name as string)
  }

  get isChallengeMembersPage() {
    return false;
    // return [routesKeys.ChallengesViewMembersPage.name].includes(this.$route.name as string)
  }
}
