import {Storage} from "@/api/models/storage";

export interface User {
  	 id: number
	 title: string
	 email: string
	 phone: string
	 comment: string
	 postfix: string
	 storages: Storage[]
}

export function getEmptyUser () {
	return {
		id: 0,
		title: "",
		email: "",
		phone: "",
		comment: "",
		postfix: "",
		storages: [],
	};
}
