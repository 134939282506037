import {Module} from "vuex/types";
import {RootState} from "@/store/index";
import {MetaPage} from "@/Models/MetaPage";
import {Shop} from '@/api/models/shop.ts'
import {api} from "@/api/api";

type T = Shop;

interface State {
    isPending: boolean,
    items: T[],
    meta?: MetaPage,
    params: { [x: string]: any }
}

export enum MUTATIONS_SHOP {
    INIT = 'MUTATIONS_SHOP/INIT',
    SET = 'MUTATIONS_SHOP/SET',
    UPDATE_PENDING = 'MUTATIONS_SHOP/UPDATE_PENDING',
}

export enum ACTIONS_SHOP {
    INIT = 'ACTIONS_SHOP/INIT',
    LOAD = 'ACTIONS_SHOP/LOAD',
    RELOAD = 'ACTIONS_SHOP/RELOAD',
    CREATE = 'ACTIONS_SHOP/CREATE',
    UPDATE = 'ACTIONS_SHOP/UPDATE',
    DELETE = 'ACTIONS_SHOP/DELETE',
}

export const ShopModule: Module<State, RootState> = {
    // namespaced: true,
    state: {
        isPending: false,
        items: [],
        meta: undefined,
        params: {}
    },
    mutations: {
        [MUTATIONS_SHOP.INIT](state) {
            state.items = [];
            state.meta = undefined;
            state.isPending = false;
            state.params = {};
        },
        [MUTATIONS_SHOP.SET](state, {
            payload,
            meta,
            isPending,
            params,
        }: { payload: T[], meta: MetaPage, isPending: boolean, params?: {[x: string]: any} }) {
            state.items = payload;
            state.meta = meta;
            state.isPending = isPending;
            state.params = params ?? {};
        },
        [MUTATIONS_SHOP.UPDATE_PENDING](state, flag: boolean) {
            state.isPending = flag;
        },
    },
    actions: {
        async [ACTIONS_SHOP.INIT]({commit}) {
            commit(MUTATIONS_SHOP.INIT)
        },
        async [ACTIONS_SHOP.LOAD]({commit, state}, params?: { page: number }) {
            console.log('ACTIONS_SHOP.LOAD', params)
            commit(MUTATIONS_SHOP.UPDATE_PENDING, true)
            const res = await api.getShops();
            commit(MUTATIONS_SHOP.SET, {...res, isPending: false, params: params})
        },
        async [ACTIONS_SHOP.RELOAD]({commit, state}) {
            console.log('ACTIONS_SHOP.RELOAD')
            commit(MUTATIONS_SHOP.UPDATE_PENDING, true)
            const res = await api.getShops();
            commit(MUTATIONS_SHOP.SET, {...res, isPending: false, params: state.params})
        },
        async [ACTIONS_SHOP.CREATE]({commit, state, dispatch}, params: T) {
            console.log('ACTIONS_SHOP.CREATE', params)
            commit(MUTATIONS_SHOP.UPDATE_PENDING, true)
            const res = await api.createShop({payload: params as any});
            await dispatch(ACTIONS_SHOP.RELOAD);
            return res;
        },
        async [ACTIONS_SHOP.UPDATE]({commit, state, dispatch}, params: T) {
            console.log('ACTIONS_SHOP.UPDATE', params)
            commit(MUTATIONS_SHOP.UPDATE_PENDING, true)
            const res = await api.updateShop({payload: params, id: params.id});
            await dispatch(ACTIONS_SHOP.RELOAD);
            return res;
        },
        async [ACTIONS_SHOP.DELETE]({commit, state, dispatch}, id: number ) {
            console.log('ACTIONS_SHOP.DELETE', id)
            commit(MUTATIONS_SHOP.UPDATE_PENDING, true)
            const res = await api.deleteShop({id: id});
            await dispatch(ACTIONS_SHOP.RELOAD);
            return res;
        },
    },
    getters: {
        shopModuleItems: (state): T[] => state.items ?? [],
        shopModuleMeta: (state): MetaPage | undefined => state.meta,
        shopModuleIsPending: (state): boolean | undefined => state.isPending,
    }
}
