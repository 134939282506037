import {AppTable} from "@/share/AppTable.vue";
import {Shop} from "@/api/models/shop";

export const shopTable: AppTable<Shop> = {
    title: 'Shop list',
    columns: [
        {
            title: 'ID',
            type: 'plain',
            code: 'id',
            width: '100px',
        },
        {
            title: 'Заголовок',
            type: 'plain',
            code: 'title',
        },
        {
            title: 'Ответственный',
            type: 'plain',
            code: 'responsible',
        }
    ]
};
