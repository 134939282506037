import {AppFormField, AppFormFieldType, AppFormFieldTypeView, FieldBtnSave} from "@/share/forms/form";
import {Storage} from "@/api/models/storage";
import store from "@/store";
import {Shop} from "@/api/models/shop";
import {fieldRequired} from "@/share/fieldRequired";

export const storageFields: AppFormField<Storage>[] = [
    // {
    //     label: 'ID',
    //     key: 'id',
    //     type: AppFormFieldType.text,
    //     typeView: AppFormFieldTypeView.text,
    //     rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
    //     disabled: true,
    //     conditionVisible: (r) => r.id != 0,
    // },
    {
        label: 'Werk',
        key: 'shopId',
        placeholder: 'Select',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.list,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        options: () => (store.getters.shopModuleItems as Shop[])?.map(e => ({label: e.title, value: e.id})) ?? [],
        params: { gridRow: "shopId responsible title" },
    },
    {
        label: 'Responsible',
        key: 'responsible',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        params: { gridRow: "shopId responsible title" },
    },
    {
        label: 'Title',
        key: 'title',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        params: { gridRow: "shopId responsible title" },
    },
    // {...FieldBtnSave, width: '350px', key: 'action'},
];
