import {AppFormField, AppFormFieldType, AppFormFieldTypeView} from "@/share/forms/forms_definitions";
import {User} from "@/api/models/user";
import {FieldBtnDelete, FieldBtnPassword, FieldBtnSave} from "@/share/forms/form";
import {fieldRequired} from "@/share/fieldRequired";

export const userFields: AppFormField<User>[] = [
    {
        label: 'ID',
        key: 'id',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        conditionVisible: (r) => r.id != 0,
        disabled: true,
    },
    {
        label: 'Title',
        key: 'title',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
    },
    {
        label: 'Postfix',
        key: 'postfix',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
    },
    {
        label: 'Email',
        key: 'email',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
    },
    {
        label: 'Phone',
        key: 'phone',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
    },
    {
        label: 'Comment',
        key: 'comment',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
    },
    {...FieldBtnSave, width: '350px', key: 'action'},
    {...FieldBtnPassword, width: '100%', key: 'action_password', class: 'btn_password', style: 'text-align: right; margin-top: -56px; margin-left: auto; width: 157px;'},
];

export const userFieldsCreate: AppFormField<User>[] = [
    {
        label: 'ID',
        key: 'id',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        conditionVisible: (r) => r.id != 0,
        disabled: true,
    },
    {
        label: 'Title',
        key: 'title',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
    },
    {
        label: 'Postfix',
        key: 'postfix',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
    },
    {
        label: 'Email',
        key: 'email',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
    },
    {
        label: 'Phone',
        key: 'phone',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
    },
    {
        label: 'Comment',
        key: 'comment',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
    },
    {...FieldBtnSave, width: '350px', key: 'action'},
];
