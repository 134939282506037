import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e8f15372"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 4,
  class: "placeholder_error"
}
const _hoisted_2 = {
  key: 5,
  class: "app-form-comment"
}
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_app_form_tags = _resolveComponent("app-form-tags")!
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_AppForm = _resolveComponent("AppForm", true)!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _directive_mask = _resolveDirective("mask")!
  const _directive_loading = _resolveDirective("loading")!

  return (_ctx.valuesLocal)
    ? _withDirectives((_openBlock(), _createBlock(_component_el_form, {
        key: 0,
        model: _ctx.valuesLocal,
        ref: "ruleForm",
        inline: _ctx.horizontal,
        onSubmit: _withModifiers(_ctx.onEnter, ["prevent"]),
        style: _normalizeStyle(_ctx.styleGrid),
        class: _normalizeClass(_ctx.layout)
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (item.type === _ctx.AppFormFieldType.date)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (_ctx.checkView(item, _ctx.AppFormFieldTypeView.dateRange))
                      ? (_openBlock(), _createBlock(_component_el_form_item, {
                          key: 0,
                          prop: item.key,
                          label: _ctx.locale(`${_ctx.nameEntity}.form.${item.key}`, item.label),
                          required: item.required,
                          rules: _ctx.rules[item.key],
                          class: "local-form",
                          style: _normalizeStyle(`grid-area: ${item.key}`),
                          id: item.id
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_date_picker, {
                              modelValue: _ctx.valuesLocal[item.key],
                              "onUpdate:modelValue": ($event: any) => ((_ctx.valuesLocal[item.key]) = $event),
                              type: "daterange",
                              "start-placeholder": "Дата начала",
                              "end-placeholder": "Дата окончания",
                              format: "DD.MM.YYYY"
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 2
                        }, 1032, ["prop", "label", "required", "rules", "style", "id"]))
                      : _createCommentVNode("", true),
                    (_ctx.checkView(item, _ctx.AppFormFieldTypeView.dateSingle))
                      ? (_openBlock(), _createBlock(_component_el_form_item, {
                          key: 1,
                          prop: item.key,
                          label: _ctx.locale(`${_ctx.nameEntity}.form.${item.key}`, item.label),
                          required: item.required,
                          rules: _ctx.rules[item.key],
                          style: _normalizeStyle(`grid-area: ${item.key}`),
                          id: item.id
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_date_picker, {
                              modelValue: _ctx.valuesLocal[item.key],
                              "onUpdate:modelValue": ($event: any) => ((_ctx.valuesLocal[item.key]) = $event),
                              "start-placeholder": "Дата начала",
                              "end-placeholder": "Дата окончания",
                              format: "DD.MM.YYYY"
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 2
                        }, 1032, ["prop", "label", "required", "rules", "style", "id"]))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true),
              (item.type === _ctx.AppFormFieldType.select)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (_ctx.checkView(item, [_ctx.AppFormFieldTypeView.list, _ctx.AppFormFieldTypeView.autocomplete,_ctx.AppFormFieldTypeView.autocomplete_2, _ctx.AppFormFieldTypeView.tags, _ctx.AppFormFieldTypeView.tree, ]))
                      ? (_openBlock(), _createBlock(_component_el_form_item, {
                          key: 0,
                          prop: item.key,
                          label: _ctx.locale(`${_ctx.nameEntity}.form.${item.key}`, item.label),
                          style: _normalizeStyle({width: item.width, 'grid-area': item.key}),
                          required: item.required,
                          rules: _ctx.rules[item.key],
                          error: _ctx._errors[item.key],
                          id: item.id
                        }, {
                          default: _withCtx(() => [
                            (item.typeView === _ctx.AppFormFieldTypeView.list)
                              ? (_openBlock(), _createBlock(_component_el_select, {
                                  key: 0,
                                  disabled: item.disabled,
                                  modelValue: _ctx.valuesLocal[item.key],
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.valuesLocal[item.key]) = $event),
                                  placeholder: _ctx.localePlaceholder(item.placeholder),
                                  onChange: ($event: any) => (_ctx.updateReferences(item)),
                                  "no-data-text": "No data"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options[item.key], (option) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        label: option.label,
                                        value: option.value
                                      }, null, 8, ["label", "value"]))
                                    }), 256))
                                  ]),
                                  _: 2
                                }, 1032, ["disabled", "modelValue", "onUpdate:modelValue", "placeholder", "onChange"]))
                              : _createCommentVNode("", true),
                            (item.typeView === _ctx.AppFormFieldTypeView.tags)
                              ? (_openBlock(), _createBlock(_component_app_form_tags, {
                                  key: 1,
                                  value: _ctx.valuesLocal[item.key],
                                  onSelect2: val => _ctx.handleSelectTags(item.key, val),
                                  item: item,
                                  get: item.actionGet
                                }, null, 8, ["value", "onSelect2", "item", "get"]))
                              : _createCommentVNode("", true),
                            (item.typeView === _ctx.AppFormFieldTypeView.autocomplete)
                              ? (_openBlock(), _createBlock(_component_el_autocomplete, {
                                  key: 2,
                                  disabled: item.disabled,
                                  "model-value": item.renderValue(_ctx.valuesLocal[item.key]),
                                  "fetch-suggestions": (text, cb) => _ctx.handleInputAutoComplete(item, text, cb),
                                  placeholder: _ctx.localePlaceholder(item.placeholder),
                                  onSelect: val => _ctx.handleSelectAutoComplete(item.key, val),
                                  debounce: 0,
                                  "no-data-text": "No data"
                                }, null, 8, ["disabled", "model-value", "fetch-suggestions", "placeholder", "onSelect"]))
                              : _createCommentVNode("", true),
                            (item.typeView === _ctx.AppFormFieldTypeView.tree)
                              ? (_openBlock(), _createBlock(_component_el_tree, {
                                  key: 3,
                                  data: item.options,
                                  disabled: item.disabled,
                                  "show-checkbox": "",
                                  "node-key": "id",
                                  props: { children: 'children', label: 'label', },
                                  "default-checked-keys": item.mapValues.to(_ctx.valuesLocal),
                                  onCheck: (item1, val) => _ctx.handleSelectTree(item.key, val)
                                }, null, 8, ["data", "disabled", "default-checked-keys", "onCheck"]))
                              : _createCommentVNode("", true),
                            (item.placeholderError && _ctx.getValidateAutoComplete(item.key, true))
                              ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(item.placeholderError), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["prop", "label", "style", "required", "rules", "error", "id"]))
                      : _createCommentVNode("", true),
                    (_ctx.checkView(item, _ctx.AppFormFieldTypeView.switch))
                      ? (_openBlock(), _createBlock(_component_el_form_item, {
                          key: 1,
                          prop: item.key,
                          label: _ctx.locale(`${_ctx.nameEntity}.form.${item.key}`, item.label),
                          class: "app-switch",
                          required: item.required,
                          style: _normalizeStyle(`grid-area: ${item.key}`),
                          id: item.id
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_switch, {
                              modelValue: _ctx.valuesLocal[item.key],
                              "onUpdate:modelValue": ($event: any) => ((_ctx.valuesLocal[item.key]) = $event)
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 2
                        }, 1032, ["prop", "label", "required", "style", "id"]))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true),
              (item.type === _ctx.AppFormFieldType.text && _ctx.checkView(item, [_ctx.AppFormFieldTypeView.text, _ctx.AppFormFieldTypeView.textarea, _ctx.AppFormFieldTypeView.number]))
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 2,
                    prop: item.key,
                    label: _ctx.locale(`${_ctx.nameEntity}.form.${item.key}`, item.label),
                    required: item.required,
                    rules: _ctx.rules[item.key],
                    error: _ctx._errors[item.key],
                    placeholder: _ctx.localePlaceholder(item.placeholder),
                    style: _normalizeStyle(`grid-area: ${item.key}`),
                    id: item.id
                  }, {
                    default: _withCtx(() => [
                      (item.typeView !== _ctx.AppFormFieldTypeView.number)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (!item?.params?.mask)
                              ? (_openBlock(), _createBlock(_component_el_input, {
                                  key: 0,
                                  disabled: item.disabled,
                                  modelValue: _ctx.valuesLocal[item.key],
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.valuesLocal[item.key]) = $event),
                                  type: item.typeView,
                                  placeholder: _ctx.localePlaceholder(item.placeholder),
                                  maxlength: item?.params?.maxlength,
                                  minlength: item?.params?.minlength,
                                  onChange: _ctx.handleUpdateValues
                                }, null, 8, ["disabled", "modelValue", "onUpdate:modelValue", "type", "placeholder", "maxlength", "minlength", "onChange"]))
                              : _withDirectives((_openBlock(), _createBlock(_component_el_input, {
                                  key: 1,
                                  disabled: item.disabled,
                                  modelValue: _ctx.valuesLocal[item.key],
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.valuesLocal[item.key]) = $event),
                                  type: item.typeView,
                                  placeholder: _ctx.localePlaceholder(item.placeholder),
                                  masked: true,
                                  onChange: _ctx.handleUpdateValues
                                }, null, 8, ["disabled", "modelValue", "onUpdate:modelValue", "type", "placeholder", "onChange"])), [
                                  [_directive_mask, item?.params?.mask ?? '']
                                ])
                          ], 64))
                        : (_openBlock(), _createBlock(_component_el_input, {
                            key: 1,
                            disabled: item.disabled,
                            modelValue: _ctx.valuesLocal[item.key],
                            "onUpdate:modelValue": ($event: any) => ((_ctx.valuesLocal[item.key]) = $event),
                            modelModifiers: { number: true },
                            type: item.typeView,
                            placeholder: _ctx.localePlaceholder(item.placeholder),
                            masked: true,
                            onChange: _ctx.handleUpdateValues
                          }, null, 8, ["disabled", "modelValue", "onUpdate:modelValue", "type", "placeholder", "onChange"]))
                    ]),
                    _: 2
                  }, 1032, ["prop", "label", "required", "rules", "error", "placeholder", "style", "id"]))
                : _createCommentVNode("", true),
              (item.type === _ctx.AppFormFieldType.array && _ctx.checkView(item, _ctx.AppFormFieldTypeView.form))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.locale(`${_ctx.nameEntity}.form.${item.key}`, item.label),
                      required: item.required,
                      style: _normalizeStyle(`grid-area: ${item.key}`),
                      id: item.id
                    }, null, 8, ["label", "required", "style", "id"]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([...(_ctx.valuesLocal[item.key] ?? [])], (itemForm, index) => {
                      return (_openBlock(), _createBlock(_component_AppForm, {
                        fields: item.form,
                        values: itemForm,
                        horizontal: true,
                        parentValues: _ctx.valuesLocal,
                        nameEntity: _ctx.nameEntity,
                        onChangeForm: val => _ctx.valuesLocal[item.key][index] = val,
                        onAction: val => _ctx.handleRemoveRow(item, index),
                        onValidateForm: val => _ctx.updateValidateForm(item, index, val)
                      }, null, 8, ["fields", "values", "parentValues", "nameEntity", "onChangeForm", "onAction", "onValidateForm"]))
                    }), 256)),
                    _createVNode(_component_AppForm, {
                      fields: item.form.map(e => ({...e, required: false, rules: []})).map((e, index) => index === item.form.length - 1 ? ({...e, label: '+'}) : e),
                      values: _ctx.emptyRow,
                      horizontal: true,
                      parentValues: _ctx.valuesLocal,
                      nameEntity: _ctx.nameEntity,
                      onAction: val => _ctx.handleAddRow(item, val)
                    }, null, 8, ["fields", "values", "parentValues", "nameEntity", "onAction"])
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.checkView(item, _ctx.AppFormFieldTypeView.button))
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 4,
                    prop: item.key,
                    required: item.required,
                    style: _normalizeStyle(`width: ${item.width}; grid-area: ${item.key}; ${item.style}`),
                    class: _normalizeClass(item.class)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, {
                        disabled: (item.required ? !_ctx.validate : false) || _ctx.isSending,
                        type:  _ctx.horizontal ? '' : `primary`,
                        onClick: ($event: any) => (_ctx.onSubmit(item.key, _ctx.valuesLocal)),
                        id: item.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.locale(`${item.label}`, item.label)), 1)
                        ]),
                        _: 2
                      }, 1032, ["disabled", "type", "onClick", "id"])
                    ]),
                    _: 2
                  }, 1032, ["prop", "required", "style", "class"]))
                : _createCommentVNode("", true),
              (_ctx.checkView(item, _ctx.AppFormFieldTypeView.plain))
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("pre", null, _toDisplayString(item.label), 1),
                    _createElementVNode("div", {
                      class: "text",
                      innerHTML: item.render ? item.render(_ctx.values[item.key], _ctx.values) : _ctx.values[item.key]
                    }, null, 8, _hoisted_3)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 256))
        ]),
        _: 1
      }, 8, ["model", "inline", "onSubmit", "style", "class"])), [
        [_directive_loading, _ctx.isSending]
      ])
    : _createCommentVNode("", true)
}