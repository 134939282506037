import {Module} from "vuex/types";
import {RootState} from "@/store/index";
import {MetaPage} from "@/Models/MetaPage";
import {api} from "@/api/api";
import {Storage} from "@/api/models/storage";

type T = Storage;

interface State {
    isPending: boolean,
    items: T[],
    meta?: MetaPage,
    params: { [x: string]: any }
}

export enum MUTATIONS_STORAGE {
    INIT = 'MUTATIONS_STORAGE/INIT',
    SET = 'MUTATIONS_STORAGE/SET',
    ADD_MANY = 'MUTATIONS_STORAGE/ADD_MANY',
    UPDATE_PENDING = 'MUTATIONS_STORAGE/UPDATE_PENDING',
}

export enum ACTIONS_STORAGE {
    INIT = 'ACTIONS_STORAGE/INIT',
    LOAD = 'ACTIONS_STORAGE/LOAD',
    RELOAD = 'ACTIONS_STORAGE/RELOAD',
    CREATE = 'ACTIONS_STORAGE/CREATE',
    UPDATE = 'ACTIONS_STORAGE/UPDATE',
    DELETE = 'ACTIONS_STORAGE/DELETE',
}

export const StorageModule: Module<State, RootState> = {
    // namespaced: true,
    state: {
        isPending: false,
        items: [],
        meta: undefined,
        params: {}
    },
    mutations: {
        [MUTATIONS_STORAGE.INIT](state) {
            state.items = [];
            state.meta = undefined;
            state.isPending = false;
            state.params = {};
        },
        [MUTATIONS_STORAGE.SET](state, {
            payload,
            meta,
            isPending,
            params,
        }: { payload: T[], meta: MetaPage, isPending: boolean, params?: {[x: string]: any} }) {
            state.items = payload;
            state.meta = meta;
            state.isPending = isPending;
            state.params = params ?? {};
        },
        [MUTATIONS_STORAGE.UPDATE_PENDING](state, flag: boolean) {
            state.isPending = flag;
        },
        [MUTATIONS_STORAGE.ADD_MANY](state, payload: T[]) {
            payload.forEach(item => {
                const index = state.items.findIndex(e => e.id == item.id);
                if (index != -1) {
                    state.items.splice(index, 1)
                }
                state.items.push(item);
            })
        },
        [MUTATIONS_STORAGE.UPDATE_PENDING](state, flag: boolean) {
            state.isPending = flag;
        },
    },
    actions: {
        async [ACTIONS_STORAGE.INIT]({commit}) {
            commit(MUTATIONS_STORAGE.INIT)
        },
        async [ACTIONS_STORAGE.LOAD]({commit, state}, params?: { page: number }) {
            console.log('ACTIONS_STORAGE.LOAD', params)
            commit(MUTATIONS_STORAGE.UPDATE_PENDING, true)
            const res = await api.getStorage(params as any);
            commit(MUTATIONS_STORAGE.SET, {...res, isPending: false, params: params})
        },
        async [ACTIONS_STORAGE.RELOAD]({commit, state}) {
            console.log('ACTIONS_STORAGE.RELOAD')
            commit(MUTATIONS_STORAGE.UPDATE_PENDING, true)
            const res = await api.getStorage(state.params as any);
            commit(MUTATIONS_STORAGE.SET, {...res, isPending: false, params: state.params})
        },
        async [ACTIONS_STORAGE.CREATE]({commit, state, dispatch}, params: T) {
            console.log('ACTIONS_STORAGE.CREATE', params)
            commit(MUTATIONS_STORAGE.UPDATE_PENDING, true)
            const res = await api.createStorage({payload: params as any});
            await dispatch(ACTIONS_STORAGE.RELOAD);
            return res;
        },
        async [ACTIONS_STORAGE.UPDATE]({commit, state, dispatch}, params: T) {
            console.log('ACTIONS_STORAGE.UPDATE', params)
            commit(MUTATIONS_STORAGE.UPDATE_PENDING, true)
            const res = await api.updateStorage({payload: params, id: params.id});
            await dispatch(ACTIONS_STORAGE.RELOAD);
            return res;
        },
        async [ACTIONS_STORAGE.DELETE]({commit, state, dispatch}, id: number ) {
            console.log('ACTIONS_STORAGE.DELETE', id)
            commit(MUTATIONS_STORAGE.UPDATE_PENDING, true)
            const res = await api.deleteStorage({id: id});
            await dispatch(ACTIONS_STORAGE.RELOAD);
            return res;
        },
    },
    getters: {
        storageModuleItems: (state): T[] => state.items ?? [],
        storageModuleMeta: (state): MetaPage | undefined => state.meta,
        storageModuleIsPending: (state): boolean | undefined => state.isPending,
    }
}
