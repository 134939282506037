import {AppFormField, AppFormFieldType, AppFormFieldTypeView} from "@/share/forms/forms_definitions";
import {StorageCell} from "@/api/models/storage_cell.ts";
import {FieldBtnSave} from "@/share/forms/form.ts";
import store from "@/store";
import {StorageRow} from "@/api/models/storage_row";
import {Provider} from "@/api/models/provider";
import {fieldRequired} from "@/share/fieldRequired";

export const storageCellFields: AppFormField<StorageCell>[] = [
    {
        label: 'ID',
        key: 'id',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        disabled: true,
        conditionVisible: (r) => r.id != 0,
    },
    {
        label: 'Title',
        key: 'title',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
    },
    {
        label: 'Stack',
        key: 'storageStackId',
        placeholder: 'Select',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.list,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        options: () => (store.getters.storageRowSingleModuleLast as StorageRow)?.storageStack?.map(e => ({label: e.title, value: e.id})) ?? [],
        // required: true,
        // actionGet: async (text: string, cb: (arg: any) => void) => {
        //     const res: StorageRow = store.getters.storageRowSingleModuleLast
        //     if (!text || !text.length) {
        //         cb(res.storageStack.map(e => ({item: e, value: e.title})))
        //         return res.storageStack.map(e => ({item: e, value: e.title}))
        //     }
        //     cb(res.storageStack.filter(e => e.title.toLowerCase().includes(text?.toLowerCase() ?? '')).map(e => ({item: e, value: e.title})));
        // },
    },
    {
        label: 'Board',
        key: 'storageBoardId',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.list,
        placeholder: 'Select',
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        // actionGet: async (text: any, cb: (arg: any) => void, values: any) => {
        //     const res: StorageRow = store.getters.storageRowSingleModuleLast
        //     const items = res.storageBoard.filter(e => e.storageStackId == values.storageStackId).map(e => ({item: e, value: e.title}));
        //
        //     if (!text || !text.length || typeof text == "object" ) {
        //         cb(items)
        //     }
        //     cb(items.filter(e => e.item.title.toLowerCase().includes(text?.toLowerCase() ?? '')));
        // },
        options: (values) => {
            return (store.getters.storageRowSingleModuleLast as StorageRow)
                ?.storageBoard
                ?.filter(e => e.storageStackId == values.storageStackId)
                ?.map(e => ({
                label: e.title,
                value: e.id
            })) ?? [];
        },
    },
    {
        label: 'Sub cells',
        key: 'storageSubCells',
        type: AppFormFieldType.array,
        typeView: AppFormFieldTypeView.form,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        required: true,
        form: [{
            key: 'title',
            type: AppFormFieldType.text,
            typeView: AppFormFieldTypeView.text,
            rules: [{required: true, message: fieldRequired}],
            required: true,
            placeholder: 'product.meta.addSubCell',
            width: '100%',
            label: 'NONE'
        }, {
            key: 'remove',
            type: AppFormFieldType.button,
            typeView: AppFormFieldTypeView.button,
            label: '-',
            class: 'default'
        }],
    },
    {...FieldBtnSave, width: '350px', key: 'action'},
];
