import {Module} from "vuex/types";
import {RootState} from "@/store/index";
import {MetaPage} from "@/Models/MetaPage";
import {GroupProduct} from '@/api/models/group_product.ts'
import {api} from "@/api/api";

type T = GroupProduct;

interface State {
    isPending: boolean,
    items: T[],
    meta?: MetaPage,
    params: { [x: string]: any }
}

export enum MUTATIONS_GROUP_PRODUCT {
    INIT = 'MUTATIONS_GROUP_PRODUCT/INIT',
    SET = 'MUTATIONS_GROUP_PRODUCT/SET',
    UPDATE_PENDING = 'MUTATIONS_GROUP_PRODUCT/UPDATE_PENDING',
}

export enum ACTIONS_GROUP_PRODUCT {
    INIT = 'ACTIONS_GROUP_PRODUCT/INIT',
    LOAD = 'ACTIONS_GROUP_PRODUCT/LOAD',
    RELOAD = 'ACTIONS_GROUP_PRODUCT/RELOAD',
    CREATE = 'ACTIONS_GROUP_PRODUCT/CREATE',
    UPDATE = 'ACTIONS_GROUP_PRODUCT/UPDATE',
    DELETE = 'ACTIONS_GROUP_PRODUCT/DELETE',
}

export const GroupProductModule: Module<State, RootState> = {
    // namespaced: true,
    state: {
        isPending: false,
        items: [],
        meta: undefined,
        params: {}
    },
    mutations: {
        [MUTATIONS_GROUP_PRODUCT.INIT](state) {
            state.items = [];
            state.meta = undefined;
            state.isPending = false;
            state.params = {};
        },
        [MUTATIONS_GROUP_PRODUCT.SET](state, {
            payload,
            meta,
            isPending,
            params,
        }: { payload: T[], meta: MetaPage, isPending: boolean, params?: {[x: string]: any} }) {
            state.items = payload;
            state.meta = meta;
            state.isPending = isPending;
            state.params = params ?? {};
        },
        [MUTATIONS_GROUP_PRODUCT.UPDATE_PENDING](state, flag: boolean) {
            state.isPending = flag;
        },
    },
    actions: {
        async [ACTIONS_GROUP_PRODUCT.INIT]({commit}) {
            commit(MUTATIONS_GROUP_PRODUCT.INIT)
        },
        async [ACTIONS_GROUP_PRODUCT.LOAD]({commit, state}, params?: { page: number }) {
            console.log('ACTIONS_GROUP_PRODUCT.LOAD', params)
            commit(MUTATIONS_GROUP_PRODUCT.UPDATE_PENDING, true)
            const res = await api.getGroupProducts(params as any);
            commit(MUTATIONS_GROUP_PRODUCT.SET, {...res, isPending: false, params: params})
        },
        async [ACTIONS_GROUP_PRODUCT.RELOAD]({commit, state}) {
            console.log('ACTIONS_GROUP_PRODUCT.RELOAD')
            commit(MUTATIONS_GROUP_PRODUCT.UPDATE_PENDING, true)
            const res = await api.getGroupProducts(state.params as any);
            commit(MUTATIONS_GROUP_PRODUCT.SET, {...res, isPending: false, params: state.params})
        },
        async [ACTIONS_GROUP_PRODUCT.CREATE]({commit, state, dispatch}, params: T) {
            console.log('ACTIONS_GROUP_PRODUCT.CREATE', params)
            commit(MUTATIONS_GROUP_PRODUCT.UPDATE_PENDING, true)
            const res = await api.createGroupProduct({payload: params as any});
            await dispatch(ACTIONS_GROUP_PRODUCT.RELOAD);
            return res;
        },
        async [ACTIONS_GROUP_PRODUCT.UPDATE]({commit, state, dispatch}, params: T) {
            console.log('ACTIONS_GROUP_PRODUCT.UPDATE', params)
            commit(MUTATIONS_GROUP_PRODUCT.UPDATE_PENDING, true)
            const res = await api.updateGroupProducts({payload: params, id: params.id});
            await dispatch(ACTIONS_GROUP_PRODUCT.RELOAD);
            return res;
        },
        async [ACTIONS_GROUP_PRODUCT.DELETE]({commit, state, dispatch}, id: number ) {
            console.log('ACTIONS_GROUP_PRODUCT.DELETE', id)
            commit(MUTATIONS_GROUP_PRODUCT.UPDATE_PENDING, true)
            const res = await api.deleteGroupProduct({id: id});
            await dispatch(ACTIONS_GROUP_PRODUCT.RELOAD);
            return res;
        },
    },
    getters: {
        groupProductModuleItems: (state): T[] => state.items ?? [],
        groupProductModuleMeta: (state): MetaPage | undefined => state.meta,
        groupProductModuleIsPending: (state): boolean | undefined => state.isPending,
    }
}
