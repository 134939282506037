import {AppFormField, AppFormFieldType, AppFormFieldTypeView} from "@/share/forms/forms_definitions";
import {Order} from "@/api/models/order";
import {FieldBtnSave} from "@/share/forms/form";
import store from "@/store";
import {Provider} from "@/api/models/provider";
import {Shop} from "@/api/models/shop";
import {fieldRequired} from "@/share/fieldRequired";

export const orderFields: AppFormField<Order>[] = [
    {
        label: 'ID',
        key: 'id',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        conditionVisible: (r) => r.id != 0,
        disabled: true,
    },
    {
        label: 'Number',
        key: 'number',
        id: 'order-number-input',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
    },
    {
        label: 'Date send',
        key: 'dateSend',
        id: 'order-date-input',
        type: AppFormFieldType.date,
        typeView: AppFormFieldTypeView.dateSingle,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
    },
    {
        label: 'Shop',
        key: 'shopId',
        id: 'order-shop-input',
        wrapDropdown: 'order-shop-dropdown',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.list,
        options: () => (store.getters.shopModuleItems as Shop[]).map(e => ({label: e.title, value: e.id})),
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        placeholder: 'Select',
    },
    {
        label: 'Provider',
        key: 'providerId',
        id: 'order-provider-input',
        wrapDropdown: 'order-provider-dropdown',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.list,
        options: () => (store.getters.providerModuleItems as Provider[]).map(e => ({label: e.title, value: e.id})),
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        placeholder: 'Select',
    },
    {...FieldBtnSave, width: '350px', key: 'action', id: 'order-save-btn'},
];
