import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_layout_header = _resolveComponent("layout-header")!
  const _component_app_form = _resolveComponent("app-form")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_layout_header, {
      key: "storageHeader",
      title: _ctx.item?.title,
      loading: _ctx.loading
    }, {
      button: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_el_button, { onClick: _ctx.handleAddRow }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.locale(`storage.meta.add_row`, 'Add row')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, { onClick: _ctx.handleAction }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.locale(`action.save`, 'Save')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["title", "loading"]),
    (_ctx.item)
      ? (_openBlock(), _createBlock(_component_app_form, {
          key: "formStorage",
          "name-entity": _ctx.nameEntity,
          "is-sending": _ctx.loading,
          fields: _ctx.form,
          values: _ctx.values,
          horizontal: true,
          onChangeForm: _ctx.handleChangeStorage
        }, null, 8, ["name-entity", "is-sending", "fields", "values", "onChangeForm"]))
      : _createCommentVNode("", true),
    _createElementVNode("h3", null, _toDisplayString(_ctx.locale(`${_ctx.nameEntity}.meta.row`, 'Row')), 1),
    (_ctx.item)
      ? (_openBlock(), _createBlock(_component_app_table, {
          key: "tableRow",
          columns: _ctx.tableRows.columns,
          items: _ctx.items,
          "name-entity": "storage",
          loading: !_ctx.isLoaded,
          params: _ctx.$route.params,
          deleted: true,
          onDelete: _ctx.handleDeleteRow,
          onClickRow: _ctx.handleOpenRow,
          edited: true,
          onEdit: _ctx.handleOpenEditRow
        }, null, 8, ["columns", "items", "loading", "params", "onDelete", "onClickRow", "onEdit"]))
      : _createCommentVNode("", true),
    (_ctx.selectItem)
      ? (_openBlock(), _createBlock(_component_el_dialog, {
          key: 2,
          modelValue: _ctx.openModal,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openModal) = $event)),
          title: _ctx.locale(`${_ctx.nameEntity}.meta.add_row`, 'Add row'),
          top: "10px",
          "before-close": _ctx.doYouClose
        }, {
          default: _withCtx(() => [
            (_ctx.openModal)
              ? (_openBlock(), _createBlock(_component_app_form, {
                  key: 0,
                  "is-sending": _ctx.loading,
                  fields: _ctx.formRow,
                  values: _ctx.selectItem,
                  "name-entity": _ctx.nameEntity,
                  onAction: _ctx.handleActionRow
                }, null, 8, ["is-sending", "fields", "values", "name-entity", "onAction"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue", "title", "before-close"]))
      : _createCommentVNode("", true)
  ]))
}