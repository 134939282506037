import {AppTable} from "@/share/AppTable.vue";
import {Unit} from "@/api/models/unit";

export const unitTable: AppTable<Unit> = {
    title: 'Еденица измерений',
    columns: [
        {
            title: 'ID',
            type: 'plain',
            code: 'id',
            width: '100px',
        },
        {
            title: 'Заголовок',
            type: 'plain',
            code: 'title',
        }
    ]
};
