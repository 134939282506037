import {Module} from "vuex/types";
import {RootState} from "@/store/index";
import {MetaPage} from "@/Models/MetaPage";
import {Order} from '@/api/models/order.ts'
import {api} from "@/api/api";

type T = Order;

interface State {
    isPending: boolean,
    items: T[],
    meta?: MetaPage,
    params: { [x: string]: any }
}

export enum MUTATIONS_ORDER {
    INIT = 'MUTATIONS_ORDER/INIT',
    SET = 'MUTATIONS_ORDER/SET',
    UPDATE_PENDING = 'MUTATIONS_ORDER/UPDATE_PENDING',
}

export enum ACTIONS_ORDER {
    INIT = 'ACTIONS_ORDER/INIT',
    LOAD = 'ACTIONS_ORDER/LOAD',
    RELOAD = 'ACTIONS_ORDER/RELOAD',
    CREATE = 'ACTIONS_ORDER/CREATE',
    UPDATE = 'ACTIONS_ORDER/UPDATE',
    DELETE = 'ACTIONS_ORDER/DELETE',
}

export const OrderModule: Module<State, RootState> = {
    // namespaced: true,
    state: {
        isPending: false,
        items: [],
        meta: undefined,
        params: {}
    },
    mutations: {
        [MUTATIONS_ORDER.INIT](state) {
            state.items = [];
            state.meta = undefined;
            state.isPending = false;
            state.params = {};
        },
        [MUTATIONS_ORDER.SET](state, {
            payload,
            meta,
            isPending,
            params,
        }: { payload: T[], meta: MetaPage, isPending: boolean, params?: {[x: string]: any} }) {
            state.items = payload;
            state.meta = meta;
            state.isPending = isPending;
            state.params = params ?? {};
        },
        [MUTATIONS_ORDER.UPDATE_PENDING](state, flag: boolean) {
            state.isPending = flag;
        },
    },
    actions: {
        async [ACTIONS_ORDER.INIT]({commit}) {
            commit(MUTATIONS_ORDER.INIT)
        },
        async [ACTIONS_ORDER.LOAD]({commit, state}, params?: { page: number }) {
            console.log('ACTIONS_ORDER.LOAD', params)
            commit(MUTATIONS_ORDER.UPDATE_PENDING, true)
            const res = await api.getOrders(params as any);
            commit(MUTATIONS_ORDER.SET, {...res, isPending: false, params: params})
        },
        async [ACTIONS_ORDER.RELOAD]({commit, state}) {
            console.log('ACTIONS_ORDER.RELOAD')
            commit(MUTATIONS_ORDER.UPDATE_PENDING, true)
            const res = await api.getOrders(state.params as any);
            commit(MUTATIONS_ORDER.SET, {...res, isPending: false, params: state.params})
        },
        async [ACTIONS_ORDER.CREATE]({commit, state, dispatch}, params: T) {
            console.log('ACTIONS_ORDER.CREATE', params)
            commit(MUTATIONS_ORDER.UPDATE_PENDING, true)
            const res = await api.createOrder({payload: params as any});
            await dispatch(ACTIONS_ORDER.RELOAD);
            return res;
        },
        async [ACTIONS_ORDER.UPDATE]({commit, state, dispatch}, params: T) {
            console.log('ACTIONS_ORDER.UPDATE', params)
            commit(MUTATIONS_ORDER.UPDATE_PENDING, true)
            const res = await api.updateOrder({payload: params, id: params.id});
            await dispatch(ACTIONS_ORDER.RELOAD);
            return res;
        },
        async [ACTIONS_ORDER.DELETE]({commit, state, dispatch}, id: number ) {
            console.log('ACTIONS_ORDER.DELETE', id)
            commit(MUTATIONS_ORDER.UPDATE_PENDING, true)
            const res = await api.deleteOrder({id: id});
            await dispatch(ACTIONS_ORDER.RELOAD);
            return res;
        },
    },
    getters: {
        orderModuleItems: (state): T[] => state.items ?? [],
        orderModuleMeta: (state): MetaPage | undefined => state.meta,
        orderModuleIsPending: (state): boolean | undefined => state.isPending,
    }
}
