import {AppTable} from "@/share/AppTable.vue";
import {Order} from "@/api/models/order";
import dateFormatter from "@/share/utils/dateFormat";
import {locale} from "@/share/locale";
import store from "@/store";

export const orderTable: AppTable<Order> = {
    title: 'Orders',
    columns: [
        {
            title: 'Number',
            type: 'plain',
            code: 'number',
            width: '100px',
        },
        {
            title: 'Date send',
            type: 'plain',
            code: 'dateSend',
            render: (text: string, row: Order) => {
                return `${dateFormatter(row.dateSend ?? '')}`
            },
        },
        {
            title: 'Provider',
            type: 'plain',
            code: 'provider',
            render: (text, row) => {
                return row.provider?.title
            }
        },
        {
            title: 'Sum',
            type: 'plain',
            code: 'sum',
            render: (e, values) => {
                const sum = Number(values.sum)
                return `${sum}`.split('.').join(',');
            }
        },
        {
            title: '1',
            type: 'select',
            tags: [
                {label: () => `${locale('order.status.finished', 'Finished')}     `, value: 'Finished'},
                {label: () => `${locale('order.status.draft', 'Draft')}       `, value: 'Draft'},
                {label: () => `${locale('order.status.canceled', 'Canceled')}   `, value: 'Canceled'},
            ],
            code: 'status',
            render: (text: string, row: Order) => {
                return locale(`order.status.${text?.toLowerCase() ?? ''}`, text);
            },
        },
    ]
};
