import store from "@/store";
import {Locale} from "@/api/models/locale";

export function fieldRequired() {
    const localeText : Locale | undefined = store.getters.localeModuleItems.find(e => e.key == 'action.field_require')
    let text = 'Pflichtfeld'
    if (localStorage.getItem('locale') && localeText) {
        switch (localStorage.getItem('locale')) {
            case 'de':
                text = localeText.valueDe;
                break;
            case 'en':
                text = localeText.valueEn;
                break;
            case 'ru':
                text = localeText.valueRu;
                break;
        }
    }

    return text;
}
