import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e61d3eba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "LayoutHeader" }
const _hoisted_2 = { class: "breadcrumb" }
const _hoisted_3 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_breadcrumb = _resolveComponent("breadcrumb")!
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_breadcrumb, { title: _ctx.title }, null, 8, ["title"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h1", null, [
              _renderSlot(_ctx.$slots, "title-icon", {}, undefined, true),
              _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
            ]),
            _renderSlot(_ctx.$slots, "button", {}, undefined, true)
          ]),
          _renderSlot(_ctx.$slots, "subtitle", {}, undefined, true)
        ], 64))
      : (_openBlock(), _createBlock(_component_loading, { key: 1 }))
  ]))
}