export interface Delivery {
  	 id: number
	 dateSend: string
	 responsible: string
  
}

export function getEmptyDelivery () {
   return {
      id: 0,
      dateSend: new Date().toISOString(),
      responsible: "",  
   };
}
