
import {Options, Vue} from "vue-class-component";
import AppForm from "@/share/AppForm.vue";
import {doYouClose} from "@/share/utils/doYouClose";
import {ElMessage, ElMessageBox} from "element-plus";
import {FormAction} from "@/share/forms/forms_definitions";
import store from "@/store";
import {ApiResponse} from "@/api/utils";
import {locale, localeError} from "@/share/locale";
import LayoutTable from "@/share/Layout/LayoutTable.vue";
import {routesKeys} from "@/router";
import {Delivery, getEmptyDelivery} from "@/api/models/delivery";
import {deliveryTable} from "@/table/delivery_columns";
import {deliveryFields} from "@/form/delivery_form";
import {ACTIONS_DELIVERY} from "@/store/DeliveryModule";
import moment from "moment";

type T = Delivery;

@Options({
  components: {
    LayoutTable,
    AppForm,
  },
})
export default class DeliveryList extends Vue {

  async created() {
  }

  columnsTable = deliveryTable

  form = deliveryFields;

  selectItem?: Delivery

  openModal = false;

  doYouClose = doYouClose;
  locale = locale;
  nameEntity = 'delivery'

  openItem(item) {
    this.$router.push({name: routesKeys.DeliveryView.name, params: item})
  }

  get loading(): boolean {
    return store.getters.deliveryModuleIsPending;
  }

  async handleAction(item: FormAction<T>) {
    let res: ApiResponse<any>

    let r = item.values.dateSend
    if (typeof (item.values as any).dateSend == "object") {
      const d = (item.values as any).dateSend;

      r = moment(d).utcOffset(0, true).format()
    }

    const val = {...item.values, dateSend: r};

    if (item.values.id) {
      res = await store.dispatch(ACTIONS_DELIVERY.UPDATE, val);
    } else {
      res = await store.dispatch(ACTIONS_DELIVERY.CREATE, val);
    }
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
      this.openModal = false;
    }
    this.$router.push({name: routesKeys.DeliveryView.name, params: res.payload})
  }

  handleOpenCreate() {
    this.selectItem = getEmptyDelivery();
    this.openModal = true;
  }

  async handleDelete(item: T) {
    await ElMessageBox.confirm(
        locale(`${this.nameEntity}.form.delete`, 'Delete', `${item.id}`),
        locale(`modal.title.warning`, 'Warning'),
        {
          confirmButtonText:  locale(`action.delete`, 'Delete'),
          cancelButtonText: locale(`action.cancel`, 'Cancel'),
          type: 'warning',
        }
    )
    const res: ApiResponse<any> = await store.dispatch(ACTIONS_DELIVERY.DELETE, item.id);
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
      ElMessage({type: 'success', message: locale(`request.success`, 'Success'),})
    }
  }
}
