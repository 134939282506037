export function priceByUnitToString(values: {priceByUnitEuro: string | null | undefined, priceByUnitCent: string | null | undefined}) {
    const sum = Number(values.priceByUnitEuro ?? 0) + (Number(`${values.priceByUnitCent}`.substr(0, 2)) / 100)
    return `${sum.toFixed(2)}`.split('.').join(',');
}

export function priceToString(values: {priceEuro: string | null | undefined, priceCent: string | null | undefined}) {
    const sum = Number(values.priceEuro ?? 0) + (Number(`${values.priceCent}`.substr(0, 2)) / 100)
    return `${sum.toFixed(2)}`.split('.').join(',');
}

export function nettoToString(values: {priceByUnitEuro: string | null | undefined, priceByUnitCent: string | null | undefined, count: number | null | undefined}) {
    const sum = Number(values.priceByUnitEuro) + Number(`${values.priceByUnitCent}`.substr(0, 2)) / 100
    return `${(sum * Number(values.count)).toFixed(2)}`.split('.').join(',');
}
