import {Module} from "vuex/types";
import {RootState} from "@/store/index";
import {MetaPage} from "@/Models/MetaPage";
import {Delivery} from '@/api/models/delivery.ts'
import {api} from "@/api/api";

type T = Delivery;

interface State {
    isPending: boolean,
    items: T[],
    meta?: MetaPage,
    params: { [x: string]: any }
}

export enum MUTATIONS_DELIVERY {
    INIT = 'MUTATIONS_DELIVERY/INIT',
    SET = 'MUTATIONS_DELIVERY/SET',
    UPDATE_PENDING = 'MUTATIONS_DELIVERY/UPDATE_PENDING',
}

export enum ACTIONS_DELIVERY {
    INIT = 'ACTIONS_DELIVERY/INIT',
    LOAD = 'ACTIONS_DELIVERY/LOAD',
    RELOAD = 'ACTIONS_DELIVERY/RELOAD',
    CREATE = 'ACTIONS_DELIVERY/CREATE',
    UPDATE = 'ACTIONS_DELIVERY/UPDATE',
    DELETE = 'ACTIONS_DELIVERY/DELETE',
}

export const DeliveryModule: Module<State, RootState> = {
    // namespaced: true,
    state: {
        isPending: false,
        items: [],
        meta: undefined,
        params: {}
    },
    mutations: {
        [MUTATIONS_DELIVERY.INIT](state) {
            state.items = [];
            state.meta = undefined;
            state.isPending = false;
            state.params = {};
        },
        [MUTATIONS_DELIVERY.SET](state, {
            payload,
            meta,
            isPending,
            params,
        }: { payload: T[], meta: MetaPage, isPending: boolean, params?: {[x: string]: any} }) {
            state.items = payload;
            state.meta = meta;
            state.isPending = isPending;
            state.params = params ?? {};
        },
        [MUTATIONS_DELIVERY.UPDATE_PENDING](state, flag: boolean) {
            state.isPending = flag;
        },
    },
    actions: {
        async [ACTIONS_DELIVERY.INIT]({commit}) {
            commit(MUTATIONS_DELIVERY.INIT)
        },
        async [ACTIONS_DELIVERY.LOAD]({commit, state}, params?: { page: number }) {
            console.log('ACTIONS_DELIVERY.LOAD', params)
            commit(MUTATIONS_DELIVERY.UPDATE_PENDING, true)
            const res = await api.getDelivery(params as any);
            commit(MUTATIONS_DELIVERY.SET, {...res, isPending: false, params: params})
        },
        async [ACTIONS_DELIVERY.RELOAD]({commit, state}) {
            console.log('ACTIONS_DELIVERY.RELOAD')
            commit(MUTATIONS_DELIVERY.UPDATE_PENDING, true)
            const res = await api.getDelivery(state.params as any);
            commit(MUTATIONS_DELIVERY.SET, {...res, isPending: false, params: state.params})
        },
        async [ACTIONS_DELIVERY.CREATE]({commit, state, dispatch}, params: T) {
            console.log('ACTIONS_DELIVERY.CREATE', params)
            commit(MUTATIONS_DELIVERY.UPDATE_PENDING, true)
            const res = await api.createDelivery({payload: params as any});
            await dispatch(ACTIONS_DELIVERY.RELOAD);
            return res;
        },
        async [ACTIONS_DELIVERY.UPDATE]({commit, state, dispatch}, params: T) {
            console.log('ACTIONS_DELIVERY.UPDATE', params)
            commit(MUTATIONS_DELIVERY.UPDATE_PENDING, true)
            const res = await api.updateDelivery({payload: params, id: params.id});
            await dispatch(ACTIONS_DELIVERY.RELOAD);
            return res;
        },
        async [ACTIONS_DELIVERY.DELETE]({commit, state, dispatch}, id: number ) {
            console.log('ACTIONS_DELIVERY.DELETE', id)
            commit(MUTATIONS_DELIVERY.UPDATE_PENDING, true)
            const res = await api.deleteDelivery({id: id});
            await dispatch(ACTIONS_DELIVERY.RELOAD);
            return res;
        },
    },
    getters: {
        deliveryModuleItems: (state): T[] => state.items ?? [],
        deliveryModuleMeta: (state): MetaPage | undefined => state.meta,
        deliveryModuleIsPending: (state): boolean | undefined => state.isPending,
    }
}
