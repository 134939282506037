import {Module} from "vuex/types";
import {RootState} from "@/store/index";
import {MetaPage} from "@/Models/MetaPage";
import {api} from "@/api/api";
import {StorageRow} from "@/api/models/storage_row";

type T = StorageRow;

interface State {
    isPending: boolean,
    items: T[],
    meta?: MetaPage,
    params: { [x: string]: any }
}

export enum MUTATIONS_STORAGE_ROW {
    INIT = 'MUTATIONS_STORAGE_ROW/INIT',
    SET = 'MUTATIONS_STORAGE_ROW/SET',
    UPDATE_PENDING = 'MUTATIONS_STORAGE_ROW/UPDATE_PENDING',
}

export enum ACTIONS_STORAGE_ROW {
    INIT = 'ACTIONS_STORAGE_ROW/INIT',
    LOAD = 'ACTIONS_STORAGE_ROW/LOAD',
    RELOAD = 'ACTIONS_STORAGE_ROW/RELOAD',

}

export const StorageRowModule: Module<State, RootState> = {
    // namespaced: true,
    state: {
        isPending: false,
        items: [],
        meta: undefined,
        params: {}
    },
    mutations: {
        [MUTATIONS_STORAGE_ROW.INIT](state) {
            state.items = [];
            state.meta = undefined;
            state.isPending = false;
            state.params = {};
        },
        [MUTATIONS_STORAGE_ROW.SET](state, {
            payload,
            meta,
            isPending,
            params,
        }: { payload: T[], meta: MetaPage, isPending: boolean, params?: {[x: string]: any} }) {
            state.items = payload;
            state.meta = meta;
            state.isPending = isPending;
            state.params = params ?? {};
        },
        [MUTATIONS_STORAGE_ROW.UPDATE_PENDING](state, flag: boolean) {
            state.isPending = flag;
        },
    },
    actions: {
        async [ACTIONS_STORAGE_ROW.INIT]({commit}) {
            commit(MUTATIONS_STORAGE_ROW.INIT)
        },
        async [ACTIONS_STORAGE_ROW.LOAD]({commit, state}, params?: { page: number }) {
            console.log('ACTIONS_STORAGE_ROW.LOAD', params)
            commit(MUTATIONS_STORAGE_ROW.UPDATE_PENDING, true)
            const res = await api.getStorageRowByStorage(params as any);
            commit(MUTATIONS_STORAGE_ROW.SET, {...res, isPending: false, params: params})
        },
        async [ACTIONS_STORAGE_ROW.RELOAD]({commit, state}) {
            console.log('ACTIONS_STORAGE_ROW.RELOAD')
            commit(MUTATIONS_STORAGE_ROW.UPDATE_PENDING, true)
            const res = await api.getStorageRowByStorage(state.params as any);
            commit(MUTATIONS_STORAGE_ROW.SET, {...res, isPending: false, params: state.params})
        },
    },
    getters: {
        storageRowModuleItems: (state): T[] => state.items ?? [],
        storageRowModuleMeta: (state): MetaPage | undefined => state.meta,
        storageRowModuleIsPending: (state): boolean | undefined => state.isPending,
    }
}
