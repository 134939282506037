import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c8c6b5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"float":"left","margin-top":"3.5rem"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_layout_header = _resolveComponent("layout-header")!
  const _component_app_form = _resolveComponent("app-form")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_layout_header, {
      key: "deliveryHeader",
      title: `Delivery ${_ctx.values?.id}`,
      loading: _ctx.loading
    }, {
      button: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_el_button, {
            onClick: _ctx.handleAction,
            id: "delivery-draft-save-btn"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.locale(`action.save`, 'Сохранить')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["title", "loading"]),
    (_ctx.values)
      ? (_openBlock(), _createBlock(_component_app_form, {
          key: "formDelivery",
          "name-entity": _ctx.nameEntity,
          "is-sending": _ctx.loading,
          fields: _ctx.form,
          values: _ctx.values,
          ref: "AppFormValues"
        }, null, 8, ["name-entity", "is-sending", "fields", "values"]))
      : _createCommentVNode("", true),
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.locale(`deliveryItem.meta.product`, 'Product')), 1),
    _createVNode(_component_el_button, {
      onClick: _ctx.handleOpenAdd,
      style: {"float":"right","margin-bottom":"1rem","margin-top":"3rem"},
      id: "delivery-draft-add-btn"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.locale(`deliveryItem.meta.add`, 'Добавить')), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    (_ctx.values)
      ? (_openBlock(), _createBlock(_component_app_table, {
          key: "tableRow",
          columns: _ctx.tableRows.columns,
          items: _ctx.items,
          loading: !_ctx.isLoaded,
          params: _ctx.$route.params,
          deleted: true,
          "name-entity": "deliveryItem",
          onDelete: _ctx.handleDeleteRow,
          onClickRow: _ctx.handleEditDeliveryItem
        }, null, 8, ["columns", "items", "loading", "params", "onDelete", "onClickRow"]))
      : _createCommentVNode("", true),
    (_ctx.selectItem)
      ? (_openBlock(), _createBlock(_component_el_dialog, {
          key: 2,
          modelValue: _ctx.openModal,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openModal) = $event)),
          title: _ctx.locale(`deliveryItem.meta.${!_ctx.selectItem.id ? 'addDeliveryItem' : 'editDeliveryItem'}`, !_ctx.selectItem.id ? 'Добавить поставку' : 'Изменить поставку'),
          top: "10px",
          "before-close": _ctx.doYouClose
        }, {
          default: _withCtx(() => [
            (_ctx.selectItem && _ctx.openModal)
              ? (_openBlock(), _createBlock(_component_app_form, {
                  key: 0,
                  "is-sending": _ctx.loading,
                  fields: _ctx.formRow,
                  values: _ctx.selectItem,
                  "name-entity": "deliveryItem",
                  onAction: _ctx.applyActionDeliveryItem,
                  references: _ctx.references
                }, null, 8, ["is-sending", "fields", "values", "onAction", "references"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue", "title", "before-close"]))
      : _createCommentVNode("", true)
  ]))
}