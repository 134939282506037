import {Module} from "vuex/types";
import {RootState} from "@/store/index";
import {MetaPage} from "@/Models/MetaPage";
import {DeliveryItem} from '@/api/models/delivery_item.ts'
import {api} from "@/api/api";

type T = DeliveryItem;

interface State {
    isPending: boolean,
    items: T[],
    meta?: MetaPage,
    params: { [x: string]: any }
}

export enum MUTATIONS_DELIVERY_ITEM {
    INIT = 'MUTATIONS_DELIVERY_ITEM/INIT',
    SET = 'MUTATIONS_DELIVERY_ITEM/SET',
    UPDATE_PENDING = 'MUTATIONS_DELIVERY_ITEM/UPDATE_PENDING',
}

export enum ACTIONS_DELIVERY_ITEM {
    INIT = 'ACTIONS_DELIVERY_ITEM/INIT',
    LOAD = 'ACTIONS_DELIVERY_ITEM/LOAD',
    RELOAD = 'ACTIONS_DELIVERY_ITEM/RELOAD',
    CREATE = 'ACTIONS_DELIVERY_ITEM/CREATE',
    UPDATE = 'ACTIONS_DELIVERY_ITEM/UPDATE',
    DELETE = 'ACTIONS_DELIVERY_ITEM/DELETE',
}

export const DeliveryItemModule: Module<State, RootState> = {
    // namespaced: true,
    state: {
        isPending: false,
        items: [],
        meta: undefined,
        params: {}
    },
    mutations: {
        [MUTATIONS_DELIVERY_ITEM.INIT](state) {
            state.items = [];
            state.meta = undefined;
            state.isPending = false;
            state.params = {};
        },
        [MUTATIONS_DELIVERY_ITEM.SET](state, {
            payload,
            meta,
            isPending,
            params,
        }: { payload: T[], meta: MetaPage, isPending: boolean, params?: {[x: string]: any} }) {
            state.items = payload;
            state.meta = meta;
            state.isPending = isPending;
            state.params = params ?? {};
        },
        [MUTATIONS_DELIVERY_ITEM.UPDATE_PENDING](state, flag: boolean) {
            state.isPending = flag;
        },
    },
    actions: {
        async [ACTIONS_DELIVERY_ITEM.INIT]({commit}) {
            commit(MUTATIONS_DELIVERY_ITEM.INIT)
        },
        async [ACTIONS_DELIVERY_ITEM.LOAD]({commit, state}, params?: { page: number }) {
            console.log('ACTIONS_DELIVERY_ITEM.LOAD', params)
            commit(MUTATIONS_DELIVERY_ITEM.UPDATE_PENDING, true)
            const res = await api.getDeliveryItem(params as any);
            commit(MUTATIONS_DELIVERY_ITEM.SET, {...res, isPending: false, params: params})
        },
        async [ACTIONS_DELIVERY_ITEM.RELOAD]({commit, state}) {
            console.log('ACTIONS_DELIVERY_ITEM.RELOAD')
            commit(MUTATIONS_DELIVERY_ITEM.UPDATE_PENDING, true)
            const res = await api.getDeliveryItem(state.params as any);
            commit(MUTATIONS_DELIVERY_ITEM.SET, {...res, isPending: false, params: state.params})
        },
        async [ACTIONS_DELIVERY_ITEM.CREATE]({commit, state, dispatch}, params: T) {
            console.log('ACTIONS_DELIVERY_ITEM.CREATE', params)
            commit(MUTATIONS_DELIVERY_ITEM.UPDATE_PENDING, true)
            const res = await api.createDeliveryItem({payload: params as any});
            await dispatch(ACTIONS_DELIVERY_ITEM.RELOAD);
            return res;
        },
        async [ACTIONS_DELIVERY_ITEM.UPDATE]({commit, state, dispatch}, params: T) {
            console.log('ACTIONS_DELIVERY_ITEM.UPDATE', params)
            commit(MUTATIONS_DELIVERY_ITEM.UPDATE_PENDING, true)
            const res = await api.updateDeliveryItem({payload: params, id: params.id});
            await dispatch(ACTIONS_DELIVERY_ITEM.RELOAD);
            return res;
        },
        async [ACTIONS_DELIVERY_ITEM.DELETE]({commit, state, dispatch}, id: number ) {
            console.log('ACTIONS_DELIVERY_ITEM.DELETE', id)
            commit(MUTATIONS_DELIVERY_ITEM.UPDATE_PENDING, true)
            const res = await api.deleteDeliveryItem({id: id});
            await dispatch(ACTIONS_DELIVERY_ITEM.RELOAD);
            return res;
        },
    },
    getters: {
        deliveryItemModuleItems: (state): T[] => state.items ?? [],
        deliveryItemModuleMeta: (state): MetaPage | undefined => state.meta,
        deliveryItemModuleIsPending: (state): boolean | undefined => state.isPending,
    }
}
