
import {Options, Vue} from "vue-class-component";
import {Component, Watch} from "vue-property-decorator";
import {StorageBoard} from "@/api/models/storage_board";
import {StorageStack} from "@/api/models/storage_stack";
import {locale, localeError} from "@/share/locale";

@Options({
  props: {
    stacks: Array,
    boards: Array,
  },
  emits: [
      'stack'
  ]
})
export default class StorageRowView extends Vue {
  boards!: StorageBoard[]
  stacks!: StorageStack[]

  optionsStacks: any[] = []
  optionsBoards: any[] = []
  valueStack: number | string = '';
  valueBoard: number | string = '';

  locale = locale;

  @Watch('valueStack')
  handleWatch1() {
    this.$emit('stack', this.valueStack)
  }

  @Watch('valueBoard')
  handleWatch2() {
    this.$emit('board', this.valueBoard)
  }

  remoteMethodStack(query: string) {
    if (query) {
      this.optionsStacks = this.stacks.map(e => ({value: e.id, label: e.title})).filter((item) => {
        return item.label.toLowerCase().includes(query.toLowerCase())
      })
    } else {
      this.optionsStacks = []
    }
  }

  remoteMethodBoard(query: string) {
    if (query) {
      let items = [...this.boards];
      if (this.valueStack) {
        items = items.filter(e => e.storageStackId == this.valueStack)
      }
      this.optionsBoards = items.map(e => ({value: e.id, label: e.title})).filter((item) => {
        return item.label.toLowerCase().includes(query.toLowerCase())
      })
    } else {
      this.optionsBoards = []
    }
  }

  handleClearBoard() {
    this.optionsBoards = []
    this.valueBoard = '';
  }
}
