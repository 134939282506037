import {AppFormField, AppFormFieldType, AppFormFieldTypeView} from "@/share/forms/forms_definitions";

export {
    AppFormFieldTypeView, AppFormField, AppOption, AppFormFieldType, IAppForm, FormAction
} from './forms_definitions'

export const FieldBtnSave: AppFormField<any> = {
    key: 'action/submit',
    label: 'action.save',
    type: AppFormFieldType.button,
    typeView: AppFormFieldTypeView.button,
    required: true,
}

export const FieldBtnDelete: AppFormField<any> = {
    key: 'action/delete',
    label: 'action.delete',
    type: AppFormFieldType.button,
    typeView: AppFormFieldTypeView.button,
    required: true,
}

export const FieldBtnPassword: AppFormField<any> = {
    key: 'action/password',
    label: 'user.meta.change_password',
    type: AppFormFieldType.button,
    typeView: AppFormFieldTypeView.button,
    required: true,
}
