import {AppTable} from "@/share/AppTable.vue";
import {Locale} from "@/api/models/locale";

export const localeTable: AppTable<Locale> = {
    title: 'Локализация',
    columns: [
        {
            title: 'Полный ключ до элемента',
            type: 'plain',
            code: 'key',
            width: '250px'
        },
        {
            title: 'Russian',
            type: 'plain',
            code: 'valueRu',
        },
        {
            title: 'English',
            type: 'plain',
            code: 'valueEn',
        },
        {
            title: 'Germany',
            type: 'plain',
            code: 'valueDe',
        }
    ]
};
