
import {Options, Vue} from "vue-class-component";
import AppIcons from "../share/AppIcons.vue";
import LayoutTable from "../share/Layout/LayoutTable.vue";
import AppForm from "@/share/AppForm.vue";
import {doYouClose} from "@/share/utils/doYouClose";
import {ElMessage, ElMessageBox} from "element-plus";
import {FormAction} from "@/share/forms/forms_definitions";
import store from "@/store";
import {ApiResponse} from "@/api/utils";
import {storageTable} from "@/table/storage_columns";
import {storageFields} from "@/form/storage_form";
import {getEmptyStorage, Storage} from "@/api/models/storage";
import {ACTIONS_STORAGE} from "@/store/StorageModule";
import {locale, localeError} from "@/share/locale";
import {routesKeys} from "@/router";
import {storageFieldsCreate} from "@/form/storage_form_create";

type T = Storage;

@Options({
  components: {
    AppForm,
    AppIcons,
    LayoutTable,
  },
})
export default class StoragetList extends Vue {

  async created() {
  }

  columnsTable = storageTable;

  form = storageFieldsCreate;

  selectItem?: T

  openModal = false;

  doYouClose = doYouClose;
  locale = locale;
  nameEntity = 'storage'

  openStorage(item) {
    this.$router.push({name: routesKeys.StorageView.name, params: {id: item.id}})
  }

  get loading(): boolean {
    return store.getters.storageModuleIsPending;
  }

  async handleAction(item: FormAction<T>) {
    let res: ApiResponse<any>
    if (item.values.id) {
      res = await store.dispatch(ACTIONS_STORAGE.UPDATE, item.values);
    } else {
      res = await store.dispatch(ACTIONS_STORAGE.CREATE, item.values);
    }
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
      this.openModal = false;
    }
  }

  handleOpenCreate() {
    this.selectItem = getEmptyStorage();
    this.openModal = true;
  }

  async handleDelete(item: Storage) {
    await ElMessageBox.confirm(
        locale(`${this.nameEntity}.form.delete`, 'Delete', item.title),
        locale(`modal.title.warning`, 'Warning'),
        {
          confirmButtonText:  locale(`action.delete`, 'Delete'),
          cancelButtonText: locale(`action.cancel`, 'Cancel'),
          type: 'warning',
        }
    )
    const res: ApiResponse<any> = await store.dispatch(ACTIONS_STORAGE.DELETE, item.id);
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
      ElMessage({type: 'success', message: locale(`request.success`, 'Success'),})
    }
  }

}
