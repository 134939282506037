import {StorageBoard} from "@/api/models/storage_board";
import {StorageStack} from "@/api/models/storage_stack";
import {StorageSubCell} from "@/api/models/storage_sub_cell";

export interface StorageCell {
    id: number
    title: string
    storageBoard: StorageBoard[]
    storageStack: StorageStack[]
    storageSubCells: StorageSubCell[]
    storageBoardId: number | string
    storageStackId: number | string

}

export function getEmptyStorageCell(): StorageCell {
    return {
        id: 0,
        title: "",
        storageBoard: [],
        storageStack: [],
        storageSubCells: [],
        storageBoardId: '',
        storageStackId: '',
    };
}
