import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import {Calendar, CircleClose, Close, Delete, Edit, Search} from "@element-plus/icons";
import VueTheMask from 'vue-the-mask'

import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {DateFormatter} from "@/share/utils/dateFormat";
import {getLocaleElementUi} from "@/share/locale";



const app = createApp(App);


app.config.globalProperties.$store = store;

app
    .use(ElementPlus, {locale: getLocaleElementUi()})
    .use(store)
    .use(router)
    .use(VueTheMask)
    .component('Edit', Edit)
    .component('Search', Search)
    .component('Close', Close)
    .component('Delete', Delete)
    .component('CircleClose', CircleClose)
    .component('Calendar', Calendar)
    .directive('date', DateFormatter)
    .mount('#app');
