import {Module} from "vuex/types";
import {RootState} from "@/store/index";
import {MetaPage} from "@/Models/MetaPage";
import {User} from '@/api/models/user.ts'
import {api} from "@/api/api";

type T = User;

interface State {
    isPending: boolean,
    items: T[],
    meta?: MetaPage,
    params: { [x: string]: any }
}

export enum MUTATIONS_USER {
    INIT = 'MUTATIONS_USER/INIT',
    SET = 'MUTATIONS_USER/SET',
    UPDATE_PENDING = 'MUTATIONS_USER/UPDATE_PENDING',
}

export enum ACTIONS_USER {
    INIT = 'ACTIONS_USER/INIT',
    LOAD = 'ACTIONS_USER/LOAD',
    RELOAD = 'ACTIONS_USER/RELOAD',
    CREATE = 'ACTIONS_USER/CREATE',
    UPDATE = 'ACTIONS_USER/UPDATE',
    DELETE = 'ACTIONS_USER/DELETE',
}

export const UserModule: Module<State, RootState> = {
    // namespaced: true,
    state: {
        isPending: false,
        items: [],
        meta: undefined,
        params: {}
    },
    mutations: {
        [MUTATIONS_USER.INIT](state) {
            state.items = [];
            state.meta = undefined;
            state.isPending = false;
            state.params = {};
        },
        [MUTATIONS_USER.SET](state, {
            payload,
            meta,
            isPending,
            params,
        }: { payload: T[], meta: MetaPage, isPending: boolean, params?: {[x: string]: any} }) {
            state.items = payload;
            state.meta = meta;
            state.isPending = isPending;
            state.params = params ?? {};
        },
        [MUTATIONS_USER.UPDATE_PENDING](state, flag: boolean) {
            state.isPending = flag;
        },
    },
    actions: {
        async [ACTIONS_USER.INIT]({commit}) {
            commit(MUTATIONS_USER.INIT)
        },
        async [ACTIONS_USER.LOAD]({commit, state}, params?: { page: number }) {
            console.log('ACTIONS_USER.LOAD', params)
            commit(MUTATIONS_USER.UPDATE_PENDING, true)
            const res = await api.getUser();
            commit(MUTATIONS_USER.SET, {...res, isPending: false, params: params})
        },
        async [ACTIONS_USER.RELOAD]({commit, state}) {
            console.log('ACTIONS_USER.RELOAD')
            commit(MUTATIONS_USER.UPDATE_PENDING, true)
            const res = await api.getUser();
            commit(MUTATIONS_USER.SET, {...res, isPending: false, params: state.params})
        },
        async [ACTIONS_USER.CREATE]({commit, state, dispatch}, params: T) {
            console.log('ACTIONS_USER.CREATE', params)
            commit(MUTATIONS_USER.UPDATE_PENDING, true)
            const res = await api.createUser({payload: params as any});
            await dispatch(ACTIONS_USER.RELOAD);
            return res;
        },
        async [ACTIONS_USER.UPDATE]({commit, state, dispatch}, params: T) {
            console.log('ACTIONS_USER.UPDATE', params)
            commit(MUTATIONS_USER.UPDATE_PENDING, true)
            const res = await api.updateUser({payload: params, id: params.id});
            await dispatch(ACTIONS_USER.RELOAD);
            return res;
        },
        async [ACTIONS_USER.DELETE]({commit, state, dispatch}, id: number ) {
            console.log('ACTIONS_USER.DELETE', id)
            commit(MUTATIONS_USER.UPDATE_PENDING, true)
            const res = await api.deleteUser({id: id});
            await dispatch(ACTIONS_USER.RELOAD);
            return res;
        },
    },
    getters: {
        userModuleItems: (state): T[] => state.items ?? [],
        userModuleMeta: (state): MetaPage | undefined => state.meta,
        userModuleIsPending: (state): boolean | undefined => state.isPending,
    }
}
