
import {Options, Vue} from "vue-class-component";
import AppForm from "@/share/AppForm.vue";
import {doYouClose} from "@/share/utils/doYouClose";
import {ElMessage, ElMessageBox} from "element-plus";
import {FormAction} from "@/share/forms/forms_definitions";
import store from "@/store";
import {ApiResponse} from "@/api/utils";
import {locale, localeError} from "@/share/locale";
import LayoutTable from "@/share/Layout/LayoutTable.vue";
import {getEmptyOrder, Order} from "@/api/models/order";
import {ACTIONS_ORDER} from "@/store/OrderModule";
import {orderTable} from "@/table/order_columns";
import {orderFields} from "@/form/order_form";
import {routesKeys} from "@/router";
import moment from "moment";

@Options({
  components: {
    LayoutTable,
    AppForm,
  },
})
export default class OrderList extends Vue {

  async created() {
  }

  columnsTable = orderTable

  form = orderFields;

  selectItem?: Order

  openModal = false;

  doYouClose = doYouClose;
  locale = locale;
  nameEntity = 'order'

  openItem(item) {
    this.$router.push({name: routesKeys.OrderView.name, params: item})
  }

  get loading(): boolean {
    return store.getters.orderModuleIsPending;
  }

  async handleAction(item: FormAction<Order>) {
    let res: ApiResponse<any>

    const d = (item.values as any).dateSend;
    const r = moment(d).utcOffset(0, true).format('YYYY-MM-DD')

    const val = {...item.values, dateSend: r};

    if (item.values.id) {
      res = await store.dispatch(ACTIONS_ORDER.UPDATE, val);
    } else {
      res = await store.dispatch(ACTIONS_ORDER.CREATE, val);
    }
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
      this.openModal = false;
    }
    this.$router.push({name: routesKeys.OrderView.name, params: res.payload})
  }

  handleOpenCreate() {
    this.selectItem = getEmptyOrder();
    this.openModal = true;
  }

  async handleDelete(item: Order) {
    await ElMessageBox.confirm(
        locale(`${this.nameEntity}.form.delete`, 'Delete', `${item.id}`),
        locale(`modal.title.warning`, 'Warning'),
        {
          confirmButtonText:  locale(`action.delete`, 'Delete'),
          cancelButtonText: locale(`action.cancel`, 'Cancel'),
          type: 'warning',
        }
    )
    const res: ApiResponse<any> = await store.dispatch(ACTIONS_ORDER.DELETE, item.id);
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
      ElMessage({type: 'success', message: locale(`request.success`, 'Success'),})
    }
  }
}
