import {StorageStack} from "@/api/models/storage_stack";
import {getEmptyStorage} from "@/api/models/storage";
import {StorageBoard} from "@/api/models/storage_board";
import {StorageCell} from "@/api/models/storage_cell";

export interface StorageRow {
    id: number
    title: string
    storage_id: number
    storageStack: StorageStack[]
    storageBoard: StorageBoard[],
    storageCell: StorageCell[]
}

export function getEmptyStorageRow(storageId = 0): StorageRow {
    return {
        id: 0,
        title: "",
        storage_id: storageId,
        storageStack: [],
        storageCell: [],
        storageBoard: [],
    };
}
