
import {Options, Vue} from 'vue-class-component';

@Options({
  props: {
    search: Boolean,
    edit: Boolean,
    close: Boolean,
    circle_close: Boolean,
    calendar: Boolean,
    more: Boolean,
    delete: Boolean,
    restore: Boolean,
  },
  components: {
  },
})
export default class AppIcons extends Vue{
  search!: boolean
  edit!: boolean
  close!: boolean
  circle_close!: boolean
  calendar!: boolean
  more!: boolean
  delete!: boolean
  restore!: boolean

  urlMoreEdit = "./more_v.svg";
  urlRestore = "./icons/restore.svg";
}
