import {getEmptyProduct, Product} from "@/api/models/product";
import {getEmptyStorageRow, StorageRow} from "@/api/models/storage_row";
import {getEmptyStorageStack, StorageStack} from "@/api/models/storage_stack";
import {getEmptyStorageBoard, StorageBoard} from "@/api/models/storage_board";
import {getEmptyStorageCell, StorageCell} from "@/api/models/storage_cell";
import {getEmptyStorage, Storage} from "@/api/models/storage";
import {getEmptyStorageSubCell, StorageSubCell} from "@/api/models/storage_sub_cell";

export interface DeliveryItem {
    id: number
    product: Product
    productId: number
    count: number | null
    storage: Storage
    storageId: number | null
    row: StorageRow
    rowId: number | null
    stack: StorageStack
    stackId: number | null
    board: StorageBoard
    boardId: number | null
    cell: StorageCell
    cellId: number | null
    subCell: StorageSubCell
    subCellId: number | null
    deliveryId: number
    uid?: number
}

export function getEmptyDeliveryItem(delivery_id = 0): DeliveryItem {
    return {
        id: 0,
        product: getEmptyProduct(),
        productId: 0,
        count: null,
        storage: getEmptyStorage(),
        storageId: null,
        row: getEmptyStorageRow(),
        rowId: null,
        stack: getEmptyStorageStack(),
        stackId: null,
        board: getEmptyStorageBoard(),
        boardId: null,
        cell: getEmptyStorageCell(),
        cellId: null,
        subCell: getEmptyStorageSubCell(),
        subCellId: null,
        deliveryId: delivery_id,
    };
}
