import {AppTable} from "@/share/AppTable.vue";
import {OrderItem} from "@/api/models/order_item";
import store from "@/store";
import {Mvz} from "@/api/models/mvz";
import {Product} from "@/api/models/product";
import {nettoToString, priceByUnitToString} from "@/share/priceByUnitToString";

export const orderItemTable: AppTable<OrderItem> = {
    title: '',
    columns: [
        {
            title: 'ID',
            type: 'plain',
            code: 'id',
            width: '40px',
        },
        {
            title: 'Тип',
            type: 'plain',
            code: 'type',
            width: '100px',
        },
        {
            title: 'Sachkonto',
            type: 'plain',
            code: 'sachkonto',
            render: (e, values) => values.sachkonto?.title ?? values.sachkonto
        },
        {
            title: 'МВЗ',
            type: 'plain',
            code: 'mvz',
            render: (e, values) => {
                return store.getters?.["mvzModuleItems"]?.find((e1: Mvz) => e1.id == values.mvzId)?.title;
            }
        },
        {
            title: 'Внутренний заказ',
            type: 'plain',
            code: 'innerOrder',
            render: (e, values) => values.innerOrder?.title ?? values.innerOrder
        },
        {
            title: 'Артикль',
            type: 'plain',
            code: 'product',
            render: (e, values) => values.product?.title ?? values.product
        },
        {
            title: 'Кол-во',
            type: 'plain',
            code: 'count',
        },
        {
            title: 'Нетто',
            type: 'plain',
            code: 'netto',
            render: (e, values) => {
                return nettoToString(values)
            }
        },
        {
            title: 'Цена за 1',
            type: 'plain',
            code: 'priceByUnit',
            render: (e, values) => {
                return priceByUnitToString(values);
            }
        },
        {
            title: 'ЕД',
            type: 'plain',
            code: 'unit',
            render: (e, values) => {
                if (values.product?.unit?.title) {
                    return values.product?.unit?.title ?? '';
                } else {
                    const products: Product[] = store.getters?.productModuleItems;
                    return products?.find(e => e.id == values.productId ?? values.product?.id)?.unit?.title
                }
            }
        }
    ]
};
