
import {Options, Vue} from "vue-class-component";
import {AppFormField} from "@/share/forms/form";
import {Watch} from "vue-property-decorator";
import {throttle} from "lodash";

@Options({
  props: {
    item: {type: Object, default: () => ({})},
    value: {type: Array, default: () => ([])},
    get: {
      type: Function, default: () => {
      }
    },
  },
  emits: ['select2'],
})
export default class AppFormTags extends Vue {
  item!: AppFormField;
  value!: any[];
  get!: Function;

  valueLocal: any[] = [];

  getDebounce: Function | undefined

  @Watch('value')
  handleWatch1() {
  }

  mounted() {
    console.log(`mounted`)
    setTimeout(() => {
      this.optionsOld.push(...(this.value ?? []))
      this.valueLocal = this.value?.map(e => e.value) ?? []

    }, 100)

    this.getDebounce = throttle(this.get as any, 300);
  }

  get resultOptions() {
    const res = [...(this.value ?? [])]
    for (const item of [...this.optionsOld]) {
      if (!res.find(item1 => item1.value == item.value)) {
        res.push(item);
      }
    }
    for (const item of [...this.options ?? []]) {
      if (!res.find(item1 => item1.value == item.value)) {
        res.push(item);
      }
    }
    return res;
  }

  async remoteMethod(text: any) {
    if (!this.getDebounce) return [];
    try {
      console.log({text})
      this.loading = true;
      const res = await this.getDebounce(text);
      this.loading = false;
      this.options.splice(0, this.options.length, ...res);

      console.log([...res])

      return res;
    } catch (e) {
    }
    return []
  }

  options: any[] = []
  optionsOld: any[] = []
  loading = false;

  async handleChangeValue(e: any) {
    // if (!this.getDebounce) return [];
    // try {
    //   this.loading = true;
    //   const res = await this.getDebounce(e.target.value);
    //   this.loading = false;
    //   this.options.splice(0, this.options.length, ...res);
    //
    //   return res;
    // } catch (e) {
    // }
    // return []
  }

  handleSelect(val: any) {
    console.log(val, [...this.resultOptions], [...this.options], [...this.optionsOld])
    this.valueLocal = val;
    this.$emit('select2', this.resultOptions.filter(item => val.includes(item.value)))
  }
}
