import {AppTable} from "@/share/AppTable.vue";
import {StorageRow} from "@/api/models/storage_row";

export const storageRowTable: AppTable<StorageRow> = {
    title: '',
    columns: [
        {
            title: 'Title',
            type: 'plain',
            code: 'title',
        },

    ]
};
