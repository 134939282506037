
import {Options, Vue} from 'vue-class-component';
import LayoutHeader from "@/share/Layout/LayoutHeader.vue"; // @ is an alias to /src
import {Watch} from "vue-property-decorator";
import LayoutTable from "@/share/Layout/LayoutTable.vue";
import AppIcons from "@/share/AppIcons.vue";
import {MetaPage} from "@/Models/MetaPage";
import _ from "lodash";
import AppTableCell from "@/share/AppTableCell.vue";
import {AppOption} from "@/share/forms/form";
import store from "@/store";
import {obj} from "@/share/types";
import {locale} from "@/share/locale";

export interface AppTableColumnBase<T> {
  type: 'plain' | 'search' | 'sort' | 'tags' | 'select' | 'date',
  title: string,
  code: keyof T,
  render?: (text: string, row: T, column: AppTableColumn<T>) => void
  action?: boolean,
  width?: string,
}

export interface AppTableColumnTags<T> {
  type: 'plain' | 'search' | 'sort' | 'tags' | 'select' | 'date',
  tags?: AppOption[]
}

export type AppTable<T = any> = {
  title: string,
  columns: AppTableColumn<T>[];
}

export type AppTableColumn<T = any> = AppTableColumnBase<T> & AppTableColumnTags<T>

export interface TableAction<T extends obj> {
  key: string,
  payload: T
}

@Options({
  props: {
    items: {type: Array, default: () => []},
    columns: {type: Array, default: () => []},
    store: {type: String, default: ''},
    edited: {type: Boolean, default: false},
    deleted: {type: Boolean, default: false},
    closed: {type: Boolean, default: false},
    keyFilter: {type: String, default: ''},
    loading: {type: Boolean, default: false},
    params: {type: Object, default: () => ({})},
    defaultFilter: {type: Object, default: () => ({})},
    nameEntity: {type: String, default: ''},
  },
  components: {
    AppTableCell,
    AppIcons,
    LayoutTable,
    LayoutHeader,
  },
  emits: ['click-column', 'click-row', 'edit', 'delete'],
})
export default class AppTableCom extends Vue {
  keyFilter!: string
  items!: any[]
  columns!: AppTableColumn[]
  store!: string
  edited!: boolean
  closed!: boolean
  loading!: boolean
  params!: { [x: string]: string }
  defaultFilter!: { [x: string]: string }
  nameEntity!: string

  locale = locale;

  localFilterTags: any = {}

  currentPage = 1;

  private get _items() {
    if (this.store) return store.getters[this.getStoreGetter('Items')]
    return this.items;
  }

  private get _meta(): MetaPage | undefined {
    if (this.store) return store.getters[this.getStoreGetter('Meta')];
    return {
      currentPage: 1,
      perPage: 10,
      total: 0,
      lastPage: 1,
    }
  }

  @Watch('_meta')
  handleWatch() {
    this.currentPage = this._meta?.currentPage ?? this.currentPage;
  }

  @Watch('keyFilter')
  handleWatch2() {
    this.isOpen = undefined;
    for (const column of this.columns) {
      this.setEmptyValueFilter(column)
    }
  }

  private get _isPending(): boolean | undefined {
    if (this.store) return store.getters[this.getStoreGetter('IsPending')]
  }

  private get _hasFilters() {
    const res: { [x: string]: boolean } = {};

    for (const item of this.columns) {
      let code = item.code as string;
      if (item.type == 'search') {
        code = `${item.code as string}Mask`;
      }

      switch (item.type) {
        case "plain":
          break;
        case "search":
          res[code] = this.localFilterTags[`${item.code as string}Mask`] != ''
          break;
        case "sort":
          break;
        case "tags":
          res[code] = Object.values(this.localFilterTags[item.code]).filter(e => e).length > 0
          break;
        case "select":
          res[code] = this.localFilterTags[item.code] != ''
          break;
        case "date":
          res[code] = this.localFilterTags[item.code] != ''
          break;
        default:
          res[code] = Object.values(this.localFilterTags[item.code]).length > 0
      }
    }


    return res;
  }

  isOpen?: AppTableColumn = {
    code: '',
    title: '',
    type: 'plain',
  };

  mounted() {
    if (this.store) {
      store.dispatch(`ACTIONS_${this.getStoreName}/LOAD`, this.params)
    }

    for (const item of this.columns) {
      this.setEmptyValueFilter(item);
    }

    if (Object.keys(this.defaultFilter ?? {}).length) {
      this.localFilterTags = {...this.defaultFilter};
      this.doLoad()
    }
  }

  handleEdit(item: { row: any }) {
    this.$emit('edit', item.row);
  }

  handleDelete(item: { row: any }) {
    this.$emit('delete', item.row);
  }

  handleClose(item: { row: any }) {
    this.$emit('close', item.row);
  }

  renderSlot(slot: any, column: AppTableColumn): string {
    if (column.render) {
      // @ts-ignore
      return column.render(slot?.row?.[this.getColumnCode(column)], slot?.row, column) || '-'
    }

    return slot?.row?.[this.getColumnCode(column)] || '-'
  }

  @Watch('columns')
  handleUpdate1() {
    this.isOpen = {
      code: '',
      title: '',
      type: 'plain',
    };
  }

  setEmptyValueFilter(item: AppTableColumn) {
    switch (item.type) {
      case "plain":
        break;
      case "search":
        this.localFilterTags[`${item.code as string}Mask`] = ''
        break;
      case "sort":
        break;
      case "tags":
        if (this.localFilterTags[item.code]) {
          const values = this.localFilterTags[item.code];
          if (Object.keys(values).length) {
            for (const key of Object.keys(values)) {
              this.localFilterTags[item.code][key] = false;
            }
          } else {
            this.localFilterTags[item.code] = {};
          }
        } else {
          this.localFilterTags[item.code] = {};
        }
        break;
      case "select":
        this.localFilterTags[item.code] = ''
        break;
      case "date":
        this.localFilterTags[item.code] = ''
        break;
      default:
        this.localFilterTags[item.code] = {}
    }
  }

  async handleSearchOpen(column: AppTableColumn, action?: 'close' | 'open') {
    if (!action || action == 'open') {
      this.isOpen = column;

      await this.$nextTick()

      document.querySelector('input')?.focus()
    }

    if (action == 'close') {
      this.isOpen = undefined;
      this.currentPage = 1;
      this.setEmptyValueFilter(column)
      this.doLoad();
    }
  }

  getColumnCode(column: AppTableColumn) {
    return column.code.toString()
  }

  get getStoreName() {
    return _.snakeCase(this.store ?? '').toUpperCase()
  }

  getStoreGetter(name: string) {
    return _.camelCase(this.store + '_MODULE') + name;
  }

  changePage(page: number) {
    this.currentPage = page;
    this.doLoad();
  }

  handleSearch(column: AppTableColumn) {
    this.currentPage = 1;

    this.doLoad();
  }

  handleChangeFilter(column: AppTableColumn, numberOption: string, value: string) {
    console.log(`handleChangeFilter`)
    this.localFilterTags[column.code] = {...(this.localFilterTags[column.code] ?? {}), [numberOption]: value}

    this.currentPage = 1;
    this.doLoad()
  }

  handleChangeFilterSelect(column: AppTableColumn, numberOption: string, value: string) {
    this.currentPage = 1;
    console.log(`handleChangeFilterSelect`, value)

    if (value) {
      this.localFilterTags[column.code] = numberOption
    } else {
      this.setEmptyValueFilter(column)
    }

    this.doLoad();
  }

  handleRemoveFilter(column: AppTableColumn) {
    this.setEmptyValueFilter(column);
    this.currentPage = 1;
    this.doLoad();
  }

  doLoad(params?: { [x: string]: any }) {
    ((this as any).$store as any).dispatch(`ACTIONS_${this.getStoreName}/LOAD`, {
      ...this.params,
      ...this.localFilterTags,
      ...params,
      page: this.currentPage,
    })
  }

  handleClickRow(row: any) {
    this.$emit('click-row', row)
  }

  handleClickColumn(item: TableAction<any>) {
    const column = this.columns.find(e=>e.code == item.key);
    if (column?.action !== false) {
      this.$emit('click-column', item)
    }
  }
}
