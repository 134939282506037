import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ChangePassword = _resolveComponent("ChangePassword")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, { class: "main-container" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_header, null, {
        default: _withCtx(() => [
          _createElementVNode("b", null, [
            (_ctx.locales.length && !_ctx.isAuth)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: "/auth"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.locale('logo', '')), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.locales.length && _ctx.isAuth)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: "/"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.locale('logo', '')), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          (_ctx.isAuth)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                onClick: _ctx.logout
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.locale('formAuth.logout', 'Logout')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          (!_ctx.isLoadingUser && !_ctx.isPageAuth)
            ? (_openBlock(), _createBlock(_component_el_aside, {
                key: 0,
                width: "200px"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routesKeys, (item) => {
                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                      (item.title && _ctx.canSeeLink(item))
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 0,
                            to: item.path,
                            class: _normalizeClass({'router-link-active': _ctx.routePath.includes(item.path) && item.path !== '/'})
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.locale(`${item.name}.menu`, item.title)), 1)
                            ]),
                            _: 2
                          }, 1032, ["to", "class"]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 256))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_el_main, null, {
            default: _withCtx(() => [
              (_ctx.isLoadingUser)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Loading..."))
                : _createCommentVNode("", true),
              (!_ctx.isLoadingUser)
                ? (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
                : _createCommentVNode("", true),
              _createVNode(_component_ChangePassword, {
                onCallbackCreated: _cache[0] || (_cache[0] = func => _ctx.listensOpenChangePass.push(func))
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}