import {AppTable} from "@/share/AppTable.vue";
import {StorageStack} from "@/api/models/storage_stack";
import store from "@/store";
import {StorageRow} from "@/api/models/storage_row";

export const storageStackTable: AppTable<StorageStack> = {
    title: 'Стеллаж',
    columns: [
        {
            title: 'ID',
            type: 'plain',
            code: 'id',
            width: '100px'
        },
        {
            title: 'Title',
            type: 'plain',
            code: 'title',
            width: '300px'
        },
        {
            title: 'Boards',
            type: 'plain',
            code: 'storageRowId',
            render: (text, row, all) => {
                return (store.getters.storageRowSingleModuleLast as StorageRow)
                    ?.storageBoard
                    ?.filter(e => e.storageStackId == row.id)
                    ?.map(e => e.title).join(', ') ?? ``
            }
        },
    ]
};
