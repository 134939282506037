import {Module} from "vuex/types";
import {RootState} from "@/store/index";
import {MetaPage} from "@/Models/MetaPage";
import {Unit} from '@/api/models/unit.ts'
import {api} from "@/api/api";

type T = Unit;

interface State {
    isPending: boolean,
    items: T[],
    meta?: MetaPage,
    params: { [x: string]: any }
}

export enum MUTATIONS_UNIT {
    INIT = 'MUTATIONS_UNIT/INIT',
    SET = 'MUTATIONS_UNIT/SET',
    UPDATE_PENDING = 'MUTATIONS_UNIT/UPDATE_PENDING',
}

export enum ACTIONS_UNIT {
    INIT = 'ACTIONS_UNIT/INIT',
    LOAD = 'ACTIONS_UNIT/LOAD',
    RELOAD = 'ACTIONS_UNIT/RELOAD',
    CREATE = 'ACTIONS_UNIT/CREATE',
    UPDATE = 'ACTIONS_UNIT/UPDATE',
    DELETE = 'ACTIONS_UNIT/DELETE',
}

export const UnitModule: Module<State, RootState> = {
    // namespaced: true,
    state: {
        isPending: false,
        items: [],
        meta: undefined,
        params: {}
    },
    mutations: {
        [MUTATIONS_UNIT.INIT](state) {
            state.items = [];
            state.meta = undefined;
            state.isPending = false;
            state.params = {};
        },
        [MUTATIONS_UNIT.SET](state, {
            payload,
            meta,
            isPending,
            params,
        }: { payload: T[], meta: MetaPage, isPending: boolean, params?: {[x: string]: any} }) {
            state.items = payload;
            state.meta = meta;
            state.isPending = isPending;
            state.params = params ?? {};
        },
        [MUTATIONS_UNIT.UPDATE_PENDING](state, flag: boolean) {
            state.isPending = flag;
        },
    },
    actions: {
        async [ACTIONS_UNIT.INIT]({commit}) {
            commit(MUTATIONS_UNIT.INIT)
        },
        async [ACTIONS_UNIT.LOAD]({commit, state}, params?: { page: number }) {
            console.log('ACTIONS_UNIT.LOAD', params)
            commit(MUTATIONS_UNIT.UPDATE_PENDING, true)
            const res = await api.getUnit(params as any);
            commit(MUTATIONS_UNIT.SET, {...res, isPending: false, params: params})
        },
        async [ACTIONS_UNIT.RELOAD]({commit, state}) {
            console.log('ACTIONS_UNIT.RELOAD')
            commit(MUTATIONS_UNIT.UPDATE_PENDING, true)
            const res = await api.getUnit(state.params as any);
            commit(MUTATIONS_UNIT.SET, {...res, isPending: false, params: state.params})
        },
        async [ACTIONS_UNIT.CREATE]({commit, state, dispatch}, params: T) {
            console.log('ACTIONS_UNIT.CREATE', params)
            commit(MUTATIONS_UNIT.UPDATE_PENDING, true)
            const res = await api.createUnit({payload: params as any});
            await dispatch(ACTIONS_UNIT.RELOAD);
            return res;
        },
        async [ACTIONS_UNIT.UPDATE]({commit, state, dispatch}, params: T) {
            console.log('ACTIONS_UNIT.UPDATE', params)
            commit(MUTATIONS_UNIT.UPDATE_PENDING, true)
            const res = await api.updateUnit({payload: params, id: params.id});
            await dispatch(ACTIONS_UNIT.RELOAD);
            return res;
        },
        async [ACTIONS_UNIT.DELETE]({commit, state, dispatch}, id: number ) {
            console.log('ACTIONS_UNIT.DELETE', id)
            commit(MUTATIONS_UNIT.UPDATE_PENDING, true)
            const res = await api.deleteUnit({id: id});
            await dispatch(ACTIONS_UNIT.RELOAD);
            return res;
        },
    },
    getters: {
        unitModuleItems: (state): T[] => state.items ?? [],
        unitModuleMeta: (state): MetaPage | undefined => state.meta,
        unitModuleIsPending: (state): boolean | undefined => state.isPending,
    }
}
