import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8998e3a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "LayoutTable" }
const _hoisted_2 = { class: "subtitle" }
const _hoisted_3 = { class: "action" }
const _hoisted_4 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTable = _resolveComponent("AppTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "subtitle", {}, undefined, true)
    ]),
    _createElementVNode("span", _hoisted_3, [
      _renderSlot(_ctx.$slots, "button", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "contentHeader", {}, undefined, true),
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createVNode(_component_AppTable, {
          items: _ctx.items,
          columns: _ctx.columns,
          store: _ctx.store,
          edited: _ctx.edited,
          deleted: _ctx.deleted,
          closed: _ctx.closed,
          onEdit: _cache[0] || (_cache[0] = e => _ctx.$emit('edit', e)),
          onDelete: _cache[1] || (_cache[1] = e => _ctx.$emit('delete', e)),
          onClose: _cache[2] || (_cache[2] = e => _ctx.$emit('close', e)),
          onClickColumn: _cache[3] || (_cache[3] = e => _ctx.$emit('click-column', e)),
          onClickRow: _cache[4] || (_cache[4] = e => _ctx.$emit('click-row', e)),
          loading: _ctx.loading,
          "default-filter": _ctx.defaultFilter,
          "key-filter": _ctx.keyFilter,
          params: _ctx.params,
          "name-entity": _ctx.store
        }, {
          action: _withCtx((slotProps) => [
            _renderSlot(_ctx.$slots, "action", {
              item: slotProps.item.row
            }, undefined, true)
          ]),
          _: 3
        }, 8, ["items", "columns", "store", "edited", "deleted", "closed", "loading", "default-filter", "key-filter", "params", "name-entity"])
      ], true),
      _renderSlot(_ctx.$slots, "contentFooter", {}, undefined, true)
    ])
  ]))
}