
import {Options, Vue} from "vue-class-component";
import AppIcons from "../../share/AppIcons.vue";
import LayoutTable from "../../share/Layout/LayoutTable.vue";
import AppForm from "@/share/AppForm.vue";
import {doYouClose} from "@/share/utils/doYouClose";
import {ElMessage, ElMessageBox} from "element-plus";
import {FormAction} from "@/share/forms/forms_definitions";
import store from "@/store";
import {ApiResponse} from "@/api/utils";
import {ACTIONS_STORAGE} from "@/store/StorageModule";
import {locale, localeError} from "@/share/locale";
import LayoutHeader from "@/share/Layout/LayoutHeader.vue";
import AppTable from "@/share/AppTable.vue";
import {ACTIONS_STORAGE_ROW_SINGLE} from "@/store/StorageRowSingleModule";
import SelectRow from "@/views/StorageView/SelectRow.vue";
import {StorageRow} from "@/api/models/storage_row";
import {getEmptyStorageCell, StorageCell} from "@/api/models/storage_cell";
import {api} from "@/api/api";
import {storageStackTable} from "@/table/storage_stack_columns";
import {getEmptyStorageStack, StorageStack} from "@/api/models/storage_stack";
import {storageStackFields} from "@/form/storage_stack_form";

type T = StorageRow;

@Options({
  components: {
    SelectRow,
    AppTable,
    LayoutHeader,
    AppForm,
    AppIcons,
    LayoutTable,
  },
})
export default class StorageStackView extends Vue {

  async created() {
    await store.dispatch(ACTIONS_STORAGE_ROW_SINGLE.LOAD, this.$route.params)
  }

  form = storageStackFields;

  tableRows = storageStackTable;

  stackId: number = 0;
  boardId: number = 0;

  openModal = false;
  selectItem: StorageStack = getEmptyStorageStack();

  references = {
    'storageStackId': (values: StorageCell) => {
      return {
        'storageBoardId': ''
      }
    }
  }

  get cells() {
    let items = this.item?.storageCell ?? [];
    if (this.stackId) {
      items = items.filter(e => e.storageStackId == this.stackId)
    }
    if (this.boardId) {
      items = items.filter(e => e.storageBoardId == this.boardId)
    }

    return items;
  }

  get item(): T {
    return store.getters.storageRowSingleModuleByID(this.$route.params.id_row);
  }

  doYouClose = doYouClose;
  locale = locale;
  nameEntity = 'storage_cell'

  get loading(): boolean {
    return store.getters.storageRowSingleModuleIsPending;
  }

  async handleAction(item: FormAction<StorageStack>) {
    let res: ApiResponse<any>
    if (item.values.id) {
      res = await api.updateStack({payload: item.values, id: item.values.id})
    } else {
      res = await api.createStack({payload: item.values})
    }
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
    }
    await store.dispatch(ACTIONS_STORAGE_ROW_SINGLE.LOAD, this.$route.params)
    this.openModal = false;
  }

  handleOpenCreate() {
    this.selectItem = getEmptyStorageStack(Number(this.$route.params.id_row));
    this.openModal = true;
  }

  async handleDeleteCell(item: StorageStack) {
    await ElMessageBox.confirm(
        locale(`${this.nameEntity}.form.delete`, 'Delete', item.title),
        locale(`modal.title.warning`, 'Warning'),
        {
          confirmButtonText: locale(`action.delete`, 'Delete'),
          cancelButtonText: locale(`action.cancel`, 'Cancel'),
          type: 'warning',
        }
    )
    const res = await api.deleteStack({id: item.id})
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    }
    await store.dispatch(ACTIONS_STORAGE_ROW_SINGLE.LOAD, this.$route.params)
  }

  handleOpenCell(item: StorageStack) {
    this.selectItem = {...item, storageBoards: this.item?.storageBoard?.filter(e => e.storageStackId == item.id)};
    this.openModal = true
  }

  handleOpenStack() {
  }

}
