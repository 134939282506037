import {Module} from "vuex/types";
import {RootState} from "@/store/index";
import {MetaPage} from "@/Models/MetaPage";
import {api} from "@/api/api";
import {Log} from "@/api/models/log";

type T = Log;

interface State {
    isPending: boolean,
    items: T[],
    meta?: MetaPage,
    params: { [x: string]: any }
}

export enum MUTATIONS_LOG {
    INIT = 'MUTATIONS_LOG/INIT',
    SET = 'MUTATIONS_LOG/SET',
    UPDATE_PENDING = 'MUTATIONS_LOG/UPDATE_PENDING',
}

export enum ACTIONS_LOG {
    INIT = 'ACTIONS_LOG/INIT',
    LOAD = 'ACTIONS_LOG/LOAD',
    RELOAD = 'ACTIONS_LOG/RELOAD',
}

export const LogModule: Module<State, RootState> = {
    // namespaced: true,
    state: {
        isPending: false,
        items: [],
        meta: undefined,
        params: {}
    },
    mutations: {
        [MUTATIONS_LOG.INIT](state) {
            state.items = [];
            state.meta = undefined;
            state.isPending = false;
            state.params = {};
        },
        [MUTATIONS_LOG.SET](state, {
            payload,
            meta,
            isPending,
            params,
        }: { payload: T[], meta: MetaPage, isPending: boolean, params?: {[x: string]: any} }) {
            state.items = payload;
            state.meta = meta;
            state.isPending = isPending;
            state.params = params ?? {};
        },
        [MUTATIONS_LOG.UPDATE_PENDING](state, flag: boolean) {
            state.isPending = flag;
        },
    },
    actions: {
        async [ACTIONS_LOG.INIT]({commit}) {
            commit(MUTATIONS_LOG.INIT)
        },
        async [ACTIONS_LOG.LOAD]({commit, state}, params?: { page: number }) {
            console.log('ACTIONS_LOG.LOAD', params)
            commit(MUTATIONS_LOG.UPDATE_PENDING, true)
            const res = await api.getLogs(params ?? {page: 1});
            commit(MUTATIONS_LOG.SET, {...res, isPending: false, params: params})
        },
        async [ACTIONS_LOG.RELOAD]({commit, state}) {
            console.log('ACTIONS_LOG.RELOAD')
            commit(MUTATIONS_LOG.UPDATE_PENDING, true)
            const res = await api.getLogs(state.params as any);
            commit(MUTATIONS_LOG.SET, {...res, isPending: false, params: state.params})
        },
    },
    getters: {
        logModuleItems: (state): T[] => state.items ?? [],
        logModuleMeta: (state): MetaPage | undefined => state.meta,
        logModuleIsPending: (state): boolean | undefined => state.isPending,
    }
}
