import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    modelValue: _ctx.valueLocal,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valueLocal) = $event)),
    onInput: _ctx.handleChangeValue,
    multiple: "",
    filterable: "",
    remote: "",
    "remote-method": _ctx.remoteMethod,
    clearable: "",
    options: _ctx.resultOptions,
    selected: _ctx.resultOptions,
    loading: _ctx.loading,
    placeholder: _ctx.item.placeholder,
    onChange: _ctx.handleSelect
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resultOptions, (item) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: item.value,
          label: item.label,
          value: item.value
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.label), 1)
          ]),
          _: 2
        }, 1032, ["label", "value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "onInput", "remote-method", "options", "selected", "loading", "placeholder", "onChange"]))
}