
import {Options, Vue} from "vue-class-component";
import {Watch} from "vue-property-decorator";
import {User} from "@/api/models/user";
import {locale, localeError} from "@/share/locale";
import {api} from "@/api/api";
import {ElMessage} from "element-plus/es";

@Options({
  props: {
    user: Object,
    isOpen: Boolean
  },
  emits: ['done']
})
export default class ChangePassword extends Vue {
  user!: User;
  isOpen!: boolean

  pass1 = ''
  pass2 = ''

  locale=locale

  id = 0;

  created() {
  }

  @Watch('isOpen')
  handleWatch2() {
    this.pass1 = ''
    this.pass2 = ''
  }

  get validate() {
    return this.pass1 == this.pass2 && this.pass1.length > 7;
  }

  loading = false;

  async handleChangePassword() {
    try {
      this.loading = true;

      const res = await api.updatePassword({id: this.user.id, password: this.pass1});

      if (res.error) {
        ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
      } else {
        ElMessage({type: 'success', message: locale(`request.success`, 'Success'),})
      }

    } catch (e) {
      localeError(e)
    }

    this.loading = false;
    this.handleClose();
  }

  @Watch('isOpen')
  handleWatch1() {
    if (!this.isOpen) {
      (window as any).document.activeElement.blur();
    }
  }

  handleClose() {
    this.$emit('done');
  }
}
