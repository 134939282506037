
import {Options, Vue} from "vue-class-component";
import Breadcrumb from "@/share/breadcrumb.vue";
// @ts-ignore
import Loading from "@/share/loading.vue";

@Options({
  components: {Loading, Breadcrumb},
  props: {
    title: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
  }
})
export default class LayoutHeader extends Vue {
  title!: string;
  isLoading!: boolean;
}
