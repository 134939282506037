import {AppTable} from "@/share/AppTable.vue";
import {Provider} from "@/api/models/provider";

export const providerTable: AppTable<Provider> = {
    title: 'Список поставщиков',
    columns: [
        {
            title: '#',
            type: 'plain',
            code: 'number',
            width: '100px'
        },
        {
            title: 'Заголовок',
            type: 'plain',
            code: 'title',
        },
        {
            title: 'Email',
            type: 'plain',
            code: 'email',
        },
        {
            title: 'Телефон',
            type: 'plain',
            code: 'phone',
        },
        {
            title: 'Комментарий',
            type: 'plain',
            code: 'comment',
        }
    ]
};
