export interface InnerOrder {
    id: number
    number: string
    title: string

}

export function getEmptyInnerOrder(): InnerOrder {
    return {
        id: 0,
        number: "",
        title: "",
    };
}
