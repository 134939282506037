
import {Options, Vue} from "vue-class-component";
import AppIcons from "../share/AppIcons.vue";
import LayoutTable from "../share/Layout/LayoutTable.vue";
import AppForm from "@/share/AppForm.vue";
import {doYouClose} from "@/share/utils/doYouClose";
import {ElMessage, ElMessageBox} from "element-plus";
import {FormAction} from "@/share/forms/forms_definitions";
import store from "@/store";
import {ApiResponse} from "@/api/utils";
import {locale, localeError} from "@/share/locale";
import {unitTable} from "@/table/unit_columns";
import {unitFields} from "@/form/unit_form";
import {ACTIONS_UNIT} from "@/store/UnitModule";
import {getEmptyUnit, Unit} from "@/api/models/unit";


@Options({
  components: {
    AppForm,
    AppIcons,
    LayoutTable,
  },
})
export default class UnitList extends Vue {

  async created() {
  }

  columnsTable = unitTable;

  form = unitFields;

  selectItem?: Unit

  openModal = false;

  doYouClose = doYouClose;
  locale = locale;
  nameEntity = 'unit'

  openItem(item) {
    this.selectItem = item;
    this.openModal = true;
  }

  get loading(): boolean {
    return store.getters.productModuleIsPending;
  }

  async handleAction(item: FormAction<Unit>) {
    let res: ApiResponse<any>
    const payload: Unit = {...item.values}
    if (item.values.id) {
      res = await store.dispatch(ACTIONS_UNIT.UPDATE, payload);
    } else {
      res = await store.dispatch(ACTIONS_UNIT.CREATE, payload);
    }
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
      this.openModal = false;
    }
  }

  handleOpenCreate() {
    this.selectItem = getEmptyUnit();
    this.openModal = true;
  }

  async handleDelete(item: Unit) {
    await ElMessageBox.confirm(
        locale(`${this.nameEntity}.form.delete`, 'Delete', item.title),
        locale(`modal.title.warning`, 'Warning'),
        {
          confirmButtonText: locale(`action.delete`, 'Delete'),
          cancelButtonText: locale(`action.cancel`, 'Cancel'),
          type: 'warning',
        }
    )
    const res: ApiResponse<any> = await store.dispatch(ACTIONS_UNIT.DELETE, item.id);
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
      ElMessage({type: 'success', message: locale(`request.success`, 'Success'),})
    }
  }
}
