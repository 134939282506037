
import {Options, Vue} from "vue-class-component";
import AppIcons from "../share/AppIcons.vue";
import LayoutTable from "../share/Layout/LayoutTable.vue";
import AppForm from "@/share/AppForm.vue";
import {getEmptyProduct, Product} from "@/api/models/product";
import {doYouClose} from "@/share/utils/doYouClose";
import {productFields} from "@/form/product_form";
import {ElMessage, ElMessageBox} from "element-plus";
import {FormAction} from "@/share/forms/forms_definitions";
import store from "@/store";
import {ACTIONS_PRODUCT} from "@/store/ProductModule";
import {ApiResponse} from "@/api/utils";
import {locale, localeError} from "@/share/locale";
import {productTable} from "@/table/product_columns";
import {values} from "lodash";
import {priceByUnitToString, priceToString} from "@/share/priceByUnitToString";

@Options({
  components: {
    AppForm,
    AppIcons,
    LayoutTable,
  },
})
export default class ProductList extends Vue {

  async created() {
  }

  columnsTable = productTable

  form = productFields;

  selectItem: Product = getEmptyProduct()

  openModal = false;

  doYouClose = doYouClose;
  locale = locale;
  nameEntity = 'product'

  openProduct(item) {
    this.selectItem = ({...item, price: priceToString(item)});
    this.openModal = true;
  }

  references = {
    'storageId': (values: Product) => {
      return {
        'storageRowId': '',
        'storageStackId': '',
        'storageBoardId': '',
        'storageCellId': '',
      }
    },
    'storageRowId': (values: Product) => {
      return {
        'storageStackId': '',
        'storageBoardId': '',
        'storageCellId': '',
      }
    },
    'storageStackId': (values: Product) => {
      return {
        'storageBoardId': '',
        'storageCellId': '',
      }
    },
    'storageBoardId': (values: Product) => {
      return {
        'storageCellId': '',
      }
    },
  }

  get loading(): boolean {
    return store.getters.productModuleIsPending;
  }

  async handleAction(item: FormAction<Product>) {
    const values = item.values;
    const prices = values.price?.toString()?.replaceAll(',', '.')?.split('.') ?? []
    const cent = prices[1] ?? '0';
    values.priceEuro = Number(prices[0] ?? '0');
    values.priceCent = Number(cent.length < 2 ? cent + '0' : cent);

    let res: ApiResponse<any>
    const payload: Product = {
      id: values.id,
      number: values.number,
      title: values.title,
      desc: values.desc,
      priceEuro: values.priceEuro,
      priceCent: values.priceCent,
      baseCount: values.baseCount,
      limitWarning: values.limitWarning,
      storageRowId: values.storageRowId,
      storageStackId: values.storageStackId,
      storageBoardId: values.storageBoardId,
      storageCellId: values.storageCellId,
      providerId: values.providerId,
      groupId: values.groupId,
      storageId: values.storageId,
      unitId: values.unitId,
      sachkontoId: values.sachkontoId,
      mvzId: values.mvzId,
      innerOrderId: values.innerOrderId,
      count: values.count ?? 0,
      price: ''
    }
    console.log({payload})
    if (values.id) {
      res = await store.dispatch(ACTIONS_PRODUCT.UPDATE, payload);
    } else {
      res = await store.dispatch(ACTIONS_PRODUCT.CREATE, payload);
    }
    if (res.error) {
      console.log({res})
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
      this.openModal = false;
    }
  }

  handleOpenCreate() {
    this.selectItem = getEmptyProduct();
    this.openModal = true;
  }

  async handleDelete(item: Product) {
    await ElMessageBox.confirm(
        locale(`${this.nameEntity}.form.delete`, 'Delete', item.title),
        locale(`modal.title.warning`, 'Warning'),
        {
          confirmButtonText: locale(`action.delete`, 'Delete'),
          cancelButtonText: locale(`action.cancel`, 'Cancel'),
          type: 'warning',
        }
    )
    const res: ApiResponse<any> = await store.dispatch(ACTIONS_PRODUCT.DELETE, item.id);
    if (res.error) {
      ElMessage({type: 'error', message: localeError(res.error?.message, 'Error'),})
    } else {
      ElMessage({type: 'success', message: locale(`request.success`, 'Success'),})
    }
  }
}
