import {AppFormField, AppFormFieldType, AppFormFieldTypeView} from "@/share/forms/forms_definitions";
import {FieldBtnSave} from "@/share/forms/form";
import {DeliveryItem} from "@/api/models/delivery_item";
import {api} from "@/api/api";
import {Sachkonto} from "@/api/models/sachkonto";
import store from "@/store";
import {Storage} from "@/api/models/storage";
import {StorageCell} from "@/api/models/storage_cell";
import {StorageBoard} from "@/api/models/storage_board";
import {StorageStack} from "@/api/models/storage_stack";
import {StorageRow} from "@/api/models/storage_row";
import {MUTATIONS_PRODUCT} from "@/store/ProductModule";
import {fieldRequired} from "@/share/fieldRequired";
import {getOptionMapTitle} from "@/form/utils";
import {StorageSubCell} from "@/api/models/storage_sub_cell";

export const deliveryItemFields: AppFormField<DeliveryItem>[] = [
    {
        label: 'ID',
        key: 'id',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        conditionVisible: (r) => r.id != 0,
        disabled: true,
    },
    {
        label: 'Product',
        key: 'product',
        id: 'delivery-elem-product-input',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.autocomplete,
        placeholder: 'Select',
        rules: [{required: true, message: fieldRequired}],
        actionGet: async (text: string, cb: (arg: any) => void) => {
            const products = await api.getProducts({page: 1, search: text.split('(')[0].trim()});
            const res = products.payload.map((e: Sachkonto) => getOptionMapTitle(e))
            cb(res);

            if (products.payload?.length) {
                products.payload.forEach(item => store.commit(MUTATIONS_PRODUCT.ADD, item))
            }
        },
        renderValue: (text: any) => {
            console.log(`renderValue `, text)
            return text ? (text.title ?? text) : text;
        },
        validate: (val, oldVal) => {
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {
        label: 'Count',
        key: 'count',
        id: 'delivery-elem-count-input',
        type: AppFormFieldType.text,
        typeView: AppFormFieldTypeView.text,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
    },
    {
        label: 'Storage',
        key: 'storageId',
        id: 'delivery-elem-storage-input',
        placeholder: 'Select',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.list,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        options: () => (store.getters.storageModuleItems as Storage[])?.map(e => ({label: e.title, value: e.id})) ?? [],
        validate: (val, oldVal) => {
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {
        label: 'Row',
        key: 'rowId',
        id: 'delivery-elem-row-input',
        placeholder: 'Select',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.list,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        options: async (values) => {
            const items = (await api.getStorageRowByStorage({id: values.storageId})).payload ?? [];

            (window as any).rows = [...JSON.parse(JSON.stringify(items)), ...((window as any).rows ?? [])];

            return items.map((e: StorageRow) => ({
                value: e.id,
                label: e.title
            }));
        },
        validate: (val, oldVal) => {
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {
        label: 'Stack',
        key: 'stackId',
        id: 'delivery-elem-stack-input',
        placeholder: 'Select',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.list,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        options: async (values) => {
            const items = (await api.getStorageStackByRow({id: values.rowId})).payload ?? [];

            (window as any).stacks = [...JSON.parse(JSON.stringify(items)), ...((window as any).stacks ?? [])];

            return items.map((e: StorageRow) => ({
                value: e.id,
                label: e.title
            }));
        },
        validate: (val, oldVal) => {
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {
        label: 'Board',
        key: 'boardId',
        id: 'delivery-elem-board-input',
        placeholder: 'Select',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.list,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        options: async (values) => {
            const items = (await api.getBoardByStack({id: values.stackId})).payload ?? [];

            (window as any).boards = [...JSON.parse(JSON.stringify(items)), ...((window as any).boards ?? [])];

            return items.map((e: StorageRow) => ({value: e.id, label: e.title}));
        },
        validate: (val, oldVal) => {
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {
        label: 'Cell',
        key: 'cellId',
        id: 'delivery-elem-cell-input',
        placeholder: 'Select',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.list,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        options: async (values) => {
            const items = (await api.getCellByBoard({id: values.boardId})).payload ?? [];

            (window as any).cells = [...JSON.parse(JSON.stringify(items)), ...((window as any).cells ?? [])];

            return items.map((e: StorageCell) => ({value: e.id, label: e.title}));
        },
        validate: (val, oldVal) => {
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {
        label: 'Sub cell',
        key: 'subCellId',
        id: 'delivery-elem-cell-input',
        placeholder: 'Select',
        type: AppFormFieldType.select,
        typeView: AppFormFieldTypeView.list,
        rules: [{required: true, message: fieldRequired, trigger: 'blur'}],
        options: async (values) => {
            const items = (await api.getSubCellByCell({id: values.cellId})).payload ?? [];

            (window as any).subCells = [...JSON.parse(JSON.stringify(items)), ...((window as any).subCells ?? [])];

            return items.map((e: StorageSubCell) => ({value: e.id, label: e.title}));
        },
        validate: (val, oldVal) => {
            if (typeof val == 'string') return !!val
            if (typeof val == 'number') return !!val

            return val?.id
        },
    },
    {...FieldBtnSave, width: '350px', key: 'action', id: 'delivery-elem-save-btn'},
];
