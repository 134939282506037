import {DeliveryItem} from "@/api/models/delivery_item";

export const referencesStorage = {
    'storageId': (values: DeliveryItem) => {
        return {
            'rowId': '',
            'stackId': '',
            'boardId': '',
            'cellId': '',
            'subCellId': '',
        }
    },
    'rowId': (values: DeliveryItem) => {
        return {
            'stackId': '',
            'boardId': '',
            'cellId': '',
            'subCellId': '',
        }
    },
    'stackId': (values: DeliveryItem) => {
        return {
            'boardId': '',
            'cellId': '',
            'subCellId': '',
        }
    },
    'boardId': (values: DeliveryItem) => {
        return {
            'cellId': '',
            'subCellId': '',
        }
    },
    'cellId': (values: DeliveryItem) => {
        return {
            'subCellId': '',
        }
    },
}
