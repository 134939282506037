import {Module} from "vuex/types";
import {RootState} from "@/store/index";
import {MetaPage} from "@/Models/MetaPage";
import {Storage} from '@/api/models/storage.ts'
import {api} from "@/api/api";

type T = Storage;

interface State {
    isPending: boolean,
    item?: T,
    params: { [x: string]: any }
}

export enum MUTATIONS_STORAGE_SINGLE {
    INIT = 'MUTATIONS_STORAGE_SINGLE/INIT',
    SET = 'MUTATIONS_STORAGE_SINGLE/SET',
    UPDATE_PENDING = 'MUTATIONS_STORAGE_SINGLE/UPDATE_PENDING',
}

export enum ACTIONS_STORAGE_SINGLE {
    INIT = 'ACTIONS_STORAGE_SINGLE/INIT',
    LOAD = 'ACTIONS_STORAGE_SINGLE/LOAD',
    CREATE = 'ACTIONS_STORAGE_SINGLE/CREATE',
    UPDATE = 'ACTIONS_STORAGE_SINGLE/UPDATE',
    DELETE = 'ACTIONS_STORAGE_SINGLE/DELETE',
}

export const StorageSingleModule: Module<State, RootState> = {
    // namespaced: true,
    state: {
        isPending: false,
        params: {},
        item: undefined,
    },
    mutations: {
        [MUTATIONS_STORAGE_SINGLE.INIT](state) {
            state.isPending = false;
            state.params = {};
        },
        [MUTATIONS_STORAGE_SINGLE.SET](state, {
            isPending,
            params,
            payload
        }: { payload: T, isPending: boolean, params?: {[x: string]: any} }) {
            state.isPending = isPending;
            state.params = params ?? {};
            state.item = payload
        },
        [MUTATIONS_STORAGE_SINGLE.UPDATE_PENDING](state, flag: boolean) {
            state.isPending = flag;
        },
    },
    actions: {
        async [ACTIONS_STORAGE_SINGLE.INIT]({commit}) {
            commit(MUTATIONS_STORAGE_SINGLE.INIT)
        },
        async [ACTIONS_STORAGE_SINGLE.LOAD]({commit, state}, params: { id: number }) {
            console.log('ACTIONS_STORAGE_SINGLE.LOAD', params)
            commit(MUTATIONS_STORAGE_SINGLE.UPDATE_PENDING, true)
            const res = await api.getStorageById(params);
            commit(MUTATIONS_STORAGE_SINGLE.SET, {...res, isPending: false, params: params})
        },
        async [ACTIONS_STORAGE_SINGLE.CREATE]({commit, state, dispatch}, params: T) {
            console.log('ACTIONS_STORAGE_SINGLE.CREATE', params)
            commit(MUTATIONS_STORAGE_SINGLE.UPDATE_PENDING, true)
            return await api.createStorage({payload: params as any});
        },
        async [ACTIONS_STORAGE_SINGLE.UPDATE]({commit, state, dispatch}, params: T) {
            console.log('ACTIONS_STORAGE_SINGLE.UPDATE', params)
            commit(MUTATIONS_STORAGE_SINGLE.UPDATE_PENDING, true)
            return await api.updateStorage({payload: params, id: params.id});
        },
        async [ACTIONS_STORAGE_SINGLE.DELETE]({commit, state, dispatch}, id: number ) {
            console.log('ACTIONS_STORAGE_SINGLE.DELETE', id)
            commit(MUTATIONS_STORAGE_SINGLE.UPDATE_PENDING, true)
            return await api.deleteStorage({id: id});
        },
    },
    getters: {
        storageSingleModuleItem: (state): T | undefined => state.item,
        storageSingleModuleIsPending: (state): boolean | undefined => state.isPending,
    }
}
